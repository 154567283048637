import { call, put, takeLatest } from 'redux-saga/effects';
import {
  actionTypes,
  getNodeSharedUser,
  getNodeSharedUserSuccess,
  searchUserSuccess,
  shareSuccess,
  deleteShareSuccess,
  editShareSuccess,
  initAppsSuccess,
  getWallPapersSuccess,
  getHelpSuccess,
  getUnsplashSuccess,
  searchUnsplashSuccess,
  getRecentCollabSuccess,
  searchUserLocalSuccess,
  getFriendListSuccess,
  addFriendSuccess,
  getCategoriesSuccess,
  addAppSuccess,
  deleteAppSuccess,
  getAppListSuccess,
  getAddedAppsSuccess,
  fixAppSuccess,
  dragAppSuccess,
  getPrivateChatIdSuccess,
  getIconsSuccess,
  getCardUsersSuccess,
} from '../actions/commonActions';
import { Failed } from '../actions/commonActions';
import api from '../../util/api';
import ResponseGenerator from '../../interfaces/ResponseGenerator';

function* getSharedUser(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.share.getShares, action.nodeKey);
    if (res.status === 200) {
      yield put(getNodeSharedUserSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* share(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.share.share, action.nodeKey, action.toUser, action.role);
    if (res.status === 200) {
      yield put(shareSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* searchUser(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.searchUser, action.keyWord);
    if (res.status === 200) {
      yield put(searchUserSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* searchUserLocal(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.searchUserLocal, action.keyWord);
    if (res.status === 200) {
      yield put(searchUserLocalSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getRecentCollab() {
  try {
    const res: ResponseGenerator = yield call(api.auth.getRecentCollab);
    if (res.status === 200) {
      yield put(getRecentCollabSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* deleteShare(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.share.cancelShare, action.nodeKey, action.toUser);
    if (res.status === 200) {
      yield put(deleteShareSuccess(action.toUser));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* transferNode(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.share.transferNode, action.nodeKey, action.toUser);
    if (res.status === 200) {
      yield put(getNodeSharedUser(action.nodeKey));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* editShare(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.share.editShare, action.nodeKey, action.toUser, action.role);
    if (res.status === 200) {
      yield put(editShareSuccess(action.toUser, action.role));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* initApps(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getNodes, action.nodeKey);
    if (res.status === 200) {
      yield put(initAppsSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getWapppapers(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.options.getWallPapers, action.currentPage, action.perPage);
    if (res.status === 200) {
      yield put(getWallPapersSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getIcons(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.getIcons, action.currentPage, action.perPage, action.tagKey);
    if (res.status === 200) {
      yield put(getIconsSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getUnsplash() {
  try {
    const res: ResponseGenerator = yield call(api.auth.getUnsplashImages);
    if (res) {
      yield put(getUnsplashSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* searchUnsplash(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.searchUnsplashImages, action.keyword, 1, 500);
    if (res) {
      yield put(searchUnsplashSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getHelp(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getNodes, action.nodeKey);
    if (res.status === 200) {
      yield put(getHelpSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getFriendList() {
  try {
    const res: ResponseGenerator = yield call(api.auth.getFriendList);
    if (res.status === 200) {
      yield put(getFriendListSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}
function* getPrivateChatId(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.getPrivateChatId, action.friendKey);
    if (res.status === 200) {
      yield put(getPrivateChatIdSuccess(res.data));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* addFriend(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.addFriend, action.userKey);
    if (res.status === 200) {
      yield put(addFriendSuccess(action.userKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getCategories() {
  try {
    const res: ResponseGenerator = yield call(api.options.getCategories);
    if (res.status === 200) {
      yield put(getCategoriesSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getApps(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.options.getApps, action.keyword, action.tagKey, action.added);
    if (res.status === 200) {
      yield put(getAppListSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* addApp(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.options.addApp, action.appKey);
    if (res.status === 200) {
      yield put(addAppSuccess(action.appKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* deleteApp(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.options.deleteApp, action.appKey);
    if (res.status === 200) {
      yield put(deleteAppSuccess(action.appKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getAddedApps(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getNodes, action.nodeKey);
    if (res.status === 200) {
      yield put(getAddedAppsSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* fixApp(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.options.fixApp, action.nodeKey);
    if (res.status === 200) {
      yield put(fixAppSuccess(action.nodeKey, action.app));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* dragApp(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.drag,
      action.nodeKey,
      action.targetNodeKey,
      action.placement,
      action.oldFather,
    );
    if (res.status === 200) {
      yield put(dragAppSuccess(action.nodeKey, action.targetNodeKey, action.placement, res, action.oldFather));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getCardUsers(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.card.getCardUsers, action.userKeyArr);
    if (res.status === 200) {
      yield put(getCardUsersSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* deleteQiniu(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.deleteQiniu, action.urls);
    if (res.status === 200) {
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

const authSagas = [
  takeLatest(actionTypes.GET_SHARED_PEOPLE, getSharedUser),
  takeLatest(actionTypes.SHARE, share),
  takeLatest(actionTypes.SEARCH_USER, searchUser),
  takeLatest(actionTypes.DELETE_SHARE, deleteShare),
  takeLatest(actionTypes.TRANSFER_NODE, transferNode),
  takeLatest(actionTypes.EDIT_SHARE, editShare),
  takeLatest(actionTypes.INIT_APPS, initApps),
  takeLatest(actionTypes.GET_WALLPAPERS, getWapppapers),
  takeLatest(actionTypes.GET_ICONS, getIcons),
  takeLatest(actionTypes.GET_HELP, getHelp),
  takeLatest(actionTypes.GET_UNSPLASH, getUnsplash),
  takeLatest(actionTypes.SEARCH_UNSPLASH, searchUnsplash),
  takeLatest(actionTypes.GET_RECENT_COLLAB, getRecentCollab),
  takeLatest(actionTypes.SEARCH_USER_LOCAL, searchUserLocal),
  takeLatest(actionTypes.ADD_FRIEND, addFriend),
  takeLatest(actionTypes.GET_FRIEND_LIST, getFriendList),
  takeLatest(actionTypes.GET_PRIVATE_CHAT_ID, getPrivateChatId),
  takeLatest(actionTypes.GET_CATEGORY, getCategories),
  takeLatest(actionTypes.GET_STORE_APP_LIST, getApps),
  takeLatest(actionTypes.ADD_APP, addApp),
  takeLatest(actionTypes.DELETE_APP, deleteApp),
  takeLatest(actionTypes.GET_ADDED_APP_LIST, getAddedApps),
  takeLatest(actionTypes.FIX_APP, fixApp),
  takeLatest(actionTypes.DRAG_APP, dragApp),
  takeLatest(actionTypes.GET_CARD_USERS, getCardUsers),
  takeLatest(actionTypes.DELETE_QINIU, deleteQiniu),
];
export default authSagas;
