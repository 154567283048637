import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { Common } from './common';
import { AuthType } from './auth';
import { ArticleReducer } from './article';
import { TreeType } from './tree';
import { TaskType } from './task';
import { WorkspaceType } from './workspace';

interface RootState {
  auth: AuthType;
  common: Common;
  article: ArticleReducer;
  tree: TreeType;
  task: TaskType;
  workspace: WorkspaceType;
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
