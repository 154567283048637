import { call, put, takeLatest } from 'redux-saga/effects';
import {
  actionTypes,
  loginByTokenSuccess,
  loginFailed,
  getQiniuTokenSuccess,
  initAppSuccess,
  getAppsSuccess,
  initSuccess,
  updateConfigSuccess,
  loginByQridSuccess,
  remainStorageSuccess,
} from '../actions/authActions';
import { Failed } from '../actions/commonActions';
import api from '../../util/api';
import ResponseGenerator from '../../interfaces/ResponseGenerator';
import ResponseGenerator2 from '../../interfaces/ResponseGenerator2';

function* loginByToken(action: any) {
  try {
    const res: ResponseGenerator2 = yield call(api.auth.loginByToken, action.token);
    if (res.statusCode === '200') {
      api.setToken(action.token);
      const user = res.result;
      const profile = user.profile || {};
      const syncRes: ResponseGenerator = yield call(
        api.auth.syncUser,
        user._key,
        profile.trueName || profile.nickName,
        user.mobile,
        user.app,
        user.appHigh,
        profile.avatar,
        profile.email,
        action.qr_id,
      );
      if (syncRes.status === 200) {
        yield put(loginByTokenSuccess(res));
      } else {
        yield put(loginFailed());
      }
    } else {
      yield put(loginFailed());
    }
  } catch (e) {
    yield put(loginFailed());
  }
}

function* loginByQrid(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.loginByQrid, action.qrid);
    if (res.status === 200) {
      api.setToken(res.data.token);
      yield put(loginByQridSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getQiniuToken() {
  try {
    const res: ResponseGenerator2 = yield call(api.auth.getUptoken);
    if (res.statusCode === '200') {
      yield put(getQiniuTokenSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* initApp(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.initApp, action.suffix);
    if (res.status === 200) {
      yield put(initAppSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getApps(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.initApp, undefined, action.noToken);
    if (res.status === 200) {
      yield put(getAppsSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* init() {
  try {
    const res: ResponseGenerator = yield call(api.options.init);
    if (res.status === 200) {
      yield put(initSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* updateConfig(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.options.updateUserConfig, action.config);
    if (res.status === 200) {
      yield put(updateConfigSuccess(action.config));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* remainStorage(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.auth.remainingStorage);
    if (res.status === 200) {
      yield put(remainStorageSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

const authSagas = [
  takeLatest(actionTypes.LOGIN_BY_TOKEN, loginByToken),
  takeLatest(actionTypes.GET_QINIU_TOKEN, getQiniuToken),
  takeLatest(actionTypes.INIT_APP, initApp),
  takeLatest(actionTypes.GET_APPS, getApps),
  takeLatest(actionTypes.INIT, init),
  takeLatest(actionTypes.UPDATE_CONFIG, updateConfig),
  takeLatest(actionTypes.LOGIN_BY_QRID, loginByQrid),
  takeLatest(actionTypes.REMAIN_STORAGE, remainStorage),
];
export default authSagas;
