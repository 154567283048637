export const actionTypes = {
  SWITCH_USER_PROFILE: 'SWITCH_USER_PROFILE',
  SWITCH_FLOAT_MENU: 'SWITCH_FLOAT_MENU',
};

export function switchUserProfile(visible?: boolean) {
  return {
    type: actionTypes.SWITCH_USER_PROFILE,
    visible,
  };
}

export function switchFloatMenu(visible?: boolean) {
  return {
    type: actionTypes.SWITCH_FLOAT_MENU,
    visible,
  };
}
