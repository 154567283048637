import { ArticleDetail } from '../../interfaces/Article';

export const actionTypes = {
  ADD_ARTICLE: 'ADD_ARTICLE',
  ADD_ARTICLE_SUCCEEDED: 'ADD_ARTICLE_SUCCEEDED',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  DELETE_ARTICLE_SUCCEEDED: 'DELETE_ARTICLE_SUCCEEDED',
  DELETE_BATCH_ARTICLE: 'DELETE_BATCH_ARTICLE',
  DELETE_BATCH_ARTICLE_SUCCEEDED: 'DELETE_BATCH_ARTICLE_SUCCEEDED',
  EDIT_ARTICLE: 'EDIT_ARTICLE',
  EDIT_ARTICLE_SUCCEEDED: 'EDIT_ARTICLE_SUCCEEDED',
  EDIT_ARTICLE_SAVESTATUS: 'EDIT_ARTICLE_SAVESTATUS',
  GET_ARTICLE_BY_ID: 'GET_ARTICLE_BY_ID',
  GET_ARTICLE_BY_ID_SUCCEEDED: 'GET_ARTICLE_BY_ID_SUCCEEDED',
  CLEAR_ARTICLE: 'CLEAR_ARTICLE',
  GET_DOC_LIST: 'GET_DOC_LIST',
  GET_DOC_LIST_SUCCEEDED: 'GET_DOC_LIST_SUCCEEDED',
  PREVIEW_ARTICLE: 'PREVIEW_ARTICLE',
  PREVIEW_ARTICLE_SUCCEEDED: 'PREVIEW_ARTICLE_SUCCEEDED',
  SEARCH: 'SEARCH',
  SEARCH_SUCCEEDED: 'SEARCH_SUCCEEDED',
  ADD_DRAW: 'ADD_DRAW',
  EDIT_DRAW_SUCCEEDED: 'EDIT_DRAW_SUCCEEDED',
  EDIT_DRAW: 'EDIT_DRAW',
  GET_SHARE_LIST: 'GET_SHARE_LIST',
  GET_SHARE_LIST_SUCCEEDED: 'GET_SHARE_LIST_SUCCEEDED',
  GET_SHARE_OUT_LIST: 'GET_SHARE_OUT_LIST',
  GET_SHARE_OUT_LIST_SUCCEEDED: 'GET_SHARE_OUT_LIST_SUCCEEDED',
  GET_FAV_LIST: 'GET_FAV_LIST',
  GET_FAV_LIST_SUCCEEDED: 'GET_FAV_LIST_SUCCEEDED',
  GET_NAV_FAV_LIST: 'GET_NAV_FAV_LIST',
  GET_NAV_FAV_LIST_SUCCEEDED: 'GET_NAV_FAV_LIST_SUCCEEDED',
  GET_CLIPS: 'GET_CLIPS',
  GET_CLIPS_SUCCEEDED: 'GET_CLIPS_SUCCEEDED',
  // 檢索節點
  SEARCH_NODE: 'SEARCH_NODE',
  SEARCH_NODE_SUCCEEDED: 'SEARCH_NODE_SUCCEEDED',
  OPEN_SEARCH_PANEL: 'OPEN_SEARCH_PANEL',
  CLEAR_ARTICLES: 'CLEAR_ARTICLES',
  GET_NTFY_LIST: 'GET_NTFY_LIST',
  GET_NTFY_LIST_SUCCEEDED: 'GET_NTFY_LIST_SUCCEEDED',
  READ_NTFY: 'READ_NTFY',
  READ_NTFY_SUCCEEDED: 'READ_NTFY_SUCCEEDED',
  CLEAR_NTFY: 'CLEAR_NTFY',
  CLEAR_NTFY_SUCCEEDED: 'CLEAR_NTFY_SUCCEEDED',
  TOGGLE_PUBLIC_SHARE_MODAL: 'TOGGLE_PUBLIC_SHARE_MODAL',
  PUBLIC_SHARE: 'PUBLIC_SHARE',
  PUBLIC_SHARE_SUCCEEDED: 'PUBLIC_SHARE_SUCCEEDED',
  CHECK_PUBLIC_SHARE: 'CHECK_PUBLIC_SHARE',
  CHECK_PUBLIC_SHARE_SUCCEEDED: 'CHECK_PUBLIC_SHARE_SUCCEEDED',
  CLEAR_PUBLIC_SHARE: 'CLEAR_PUBLIC_SHARE',
  // draw
  CHANGE_STYLE_TYPE: 'CHANGE_STYLE_TYPE',
  // 回收箱
  GET_RECYCLE: 'GET_RECYCLE',
  GET_RECYCLE__SUCCEEDED: 'GET_RECYCLE_SUCCEEDED',
  RESTORE: 'RESTORE',
  RESTORE_SUCCEEDED: 'RESTORE_SUCCEEDED',
  DELETE_RECYCLE: 'DELETE_RECYCLE',
  DELETE_RECYCLE_SUCCEEDED: 'DELETE_RECYCLE_SUCCEEDED',
  CLICK_PATH: 'CLICK_PATH',
  FAV_ARTICLE: 'FAV_ARTICLE',
  FAV_ARTICLE_SUCCEEDED: 'FAV_ARTICLE_SUCCEEDED',
  ARTICLE_CHANGED: 'ARTICLE_CHANGED',
  GET_CHILD_NODES: 'GET_CHILD_NODES',
  GET_CHILD_NODES_SUCCESS: 'GET_CHILD_NODES_SUCCESS',
  GET_RECENT_UPLOAD: 'GET_RECENT_UPLOAD',
  GET_RECENT_UPLOAD_SUCCEEDED: 'GET_RECENT_UPLOAD_SUCCEEDED',
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_SUCCEEDED: 'UPLOAD_FILE_SUCCEEDED',
  SET_VIEW_UPLOAD_LOG: 'SET_VIEW_UPLOAD_LOG',
  GET_QUICK_ACCESS: 'GET_QUICK_ACCESS',
  GET_QUICK_ACCESS_SUCCEEDED: 'GET_QUICK_ACCESS_SUCCEEDED',
  ADD_QUICK_ACCESS: 'ADD_QUICK_ACCESS',
  ADD_QUICK_ACCESS_SUCCEEDED: 'ADD_QUICK_ACCESS_SUCCEEDED',
  REMOVE_QUICK_ACCESS: 'REMOVE_QUICK_ACCESS',
  REMOVE_QUICK_ACCESS_SUCCEEDED: 'REMOVE_QUICK_ACCESS_SUCCEEDED',
  PUBLIC_SHARE_TREE: 'PUBLIC_SHARE_TREE',
  PUBLIC_SHARE_TREE_SUCCEEDED: 'PUBLIC_SHARE_TREE_SUCCEEDED',
  GET_BANGUMI_LIST: 'GET_BANGUMI_LIST',
  GET_BANGUMI_LIST_SUCCEEDE: 'GET_BANGUMI_LIST_SUCCEEDE',
};

// --- articles ---
export function getArticleById(_key: string, bookId?: string) {
  console.log('---action: GET_ARTICLE_BY_ID---');

  return {
    type: actionTypes.GET_ARTICLE_BY_ID,
    _key,
    bookId,
  };
}
export function getArticleByIdSuccess(data: any) {
  return {
    type: actionTypes.GET_ARTICLE_BY_ID_SUCCEEDED,
    data,
  };
}
export function previewArticle(_key: string) {
  return {
    type: actionTypes.PREVIEW_ARTICLE,
    _key,
  };
}
export function previewArticleSuccess(data: any) {
  return {
    type: actionTypes.PREVIEW_ARTICLE_SUCCEEDED,
    data,
  };
}
export function deleteArticle(
  nodeKey: string,
  isTree?: boolean,
  exitShare?: boolean,
  nextTreeKey?: string,
  noMessage?: boolean,
  isProjectTree?: boolean,
) {
  return {
    type: actionTypes.DELETE_ARTICLE,
    nodeKey,
    isTree,
    exitShare,
    nextTreeKey,
    noMessage,
    isProjectTree,
  };
}
export function deleteArticleSuccess(
  nodeKey: string,
  isTree?: boolean,
  exitShare?: boolean,
  noMessage?: boolean,
  isProjectTree?: boolean,
) {
  return {
    type: actionTypes.DELETE_ARTICLE_SUCCEEDED,
    nodeKey,
    isTree,
    exitShare,
    noMessage,
    isProjectTree,
  };
}
export function deleteBatchArticle(nodeKeyArr: string[], nextTreeKey?: string) {
  return {
    type: actionTypes.DELETE_BATCH_ARTICLE,
    nodeKeyArr,
    nextTreeKey,
  };
}
export function deleteBatchArticleSuccess(nodeKeyArr: string[]) {
  return {
    type: actionTypes.DELETE_BATCH_ARTICLE_SUCCEEDED,
    nodeKeyArr,
  };
}
export function editArticle(
  nodeKey: string,
  name?: string,
  nodeType?: string,
  detail?: ArticleDetail,
  status?: number,
  thumbnail?: string,
  url?: string,
  openNewTab?: boolean,
  notShowMessage?: boolean,
  modifyState?: boolean,
  star?: number,
  customTime?: number,
  noRefresh?: boolean,
  fileSize?: number,
  fileType?: string,
) {
  return {
    type: actionTypes.EDIT_ARTICLE,
    nodeKey,
    status,
    name,
    nodeType,
    detail,
    thumbnail,
    url,
    openNewTab,
    notShowMessage,
    modifyState,
    star,
    customTime,
    noRefresh,
    fileSize,
    fileType,
  };
}
export function editArticleSuccess(
  nodeKey: string,
  name: string,
  data: any,
  detail?: ArticleDetail,
  url?: string,
  notShowMessage?: boolean,
  modifyState?: boolean,
  star?: number,
  customTime?: number,
  noRefresh?: boolean,
  fileType?: boolean,
) {
  return {
    type: actionTypes.EDIT_ARTICLE_SUCCEEDED,
    nodeKey,
    name,
    data,
    detail,
    url,
    saveStatus: 1,
    notShowMessage,
    modifyState,
    star,
    customTime,
    noRefresh,
    fileType,
  };
}
export function editArticleSaveStatus(saveStatus: number) {
  return {
    type: actionTypes.EDIT_ARTICLE_SAVESTATUS,
    saveStatus,
  };
}

export function addArticle(
  name?: string,
  nodeType?: string,
  detail?: ArticleDetail,
  appKey?: string,
  fatherId?: string,
  thumbnail?: string,
  notShowMessage?: boolean,
  inArticles?: boolean,
  url?: string,
  partialLoading?: boolean,
  customTime?: number,
) {
  return {
    type: actionTypes.ADD_ARTICLE,
    appKey,
    name,
    fatherId,
    nodeType,
    detail,
    thumbnail,
    notShowMessage,
    inArticles,
    url,
    partialLoading,
    customTime,
  };
}

export function addArticleSuccess(
  data: any,
  detail: any,
  notShowMessage?: boolean,
  inArticles?: boolean,
  partialLoading?: boolean,
  customTime?: number,
) {
  return {
    type: actionTypes.ADD_ARTICLE_SUCCEEDED,
    data,
    detail,
    saveStatus: 1,
    notShowMessage,
    inArticles,
    partialLoading,
    customTime,
  };
}
export function clearArticle() {
  return {
    type: actionTypes.CLEAR_ARTICLE,
  };
}

export function getDocList(
  currentPage: number,
  perPage: number,
  appKey?: string,
  pagination?: boolean,
  projectKey?: string,
  nodeType?: string,
  star?: number,
  cardKey?: string,
  partialLoading?: boolean,
) {
  return {
    type: actionTypes.GET_DOC_LIST,
    currentPage,
    perPage,
    appKey,
    pagination,
    projectKey,
    nodeType,
    star,
    cardKey,
    partialLoading,
  };
}
export function getDocListSuccess(data: any, page: number, pagination?: boolean, partialLoading?: boolean) {
  return {
    type: actionTypes.GET_DOC_LIST_SUCCEEDED,
    data,
    page,
    pagination,
    partialLoading,
  };
}

export function getShareList(
  page: number,
  limit: number,
  appKey?: string,
  pagination?: boolean,
  projectKey?: string,
  nodeType?: string,
  cardKey?: string,
) {
  return {
    type: actionTypes.GET_SHARE_LIST,
    page,
    limit,
    appKey,
    pagination,
    projectKey,
    nodeType,
    cardKey,
  };
}
export function getShareListSuccess(data: any, page: number, pagination?: boolean) {
  return {
    type: actionTypes.GET_SHARE_LIST_SUCCEEDED,
    data,
    page,
    pagination,
  };
}

export function getShareOutList(
  page: number,
  limit: number,
  appKey?: string,
  pagination?: boolean,
  projectKey?: string,
  nodeType?: string,
  cardKey?: string,
) {
  return {
    type: actionTypes.GET_SHARE_OUT_LIST,
    page,
    limit,
    appKey,
    pagination,
    projectKey,
    nodeType,
    cardKey,
  };
}
export function getShareOutListSuccess(data: any, page: number, pagination?: boolean) {
  return {
    type: actionTypes.GET_SHARE_OUT_LIST_SUCCEEDED,
    page,
    data,
    pagination,
  };
}

export function getFavList(
  page: number,
  limit: number,
  appKey?: string,
  pagination?: boolean,
  showOriginal?: boolean,
  projectKey?: string,
  nodeType?: string,
  star?: number,
  cardKey?: string,
) {
  return {
    type: actionTypes.GET_FAV_LIST,
    page,
    limit,
    appKey,
    pagination,
    showOriginal,
    projectKey,
    nodeType,
    star,
    cardKey,
  };
}
export function getFavListSuccess(data: any, page: number, pagination?: boolean) {
  return {
    type: actionTypes.GET_FAV_LIST_SUCCEEDED,
    data,
    page,
    pagination,
  };
}

export function getNavFavList(
  page: number,
  limit: number,
  appKey?: string,
  pagination?: boolean,
  showOriginal?: boolean,
  projectKey?: string,
  nodeType?: string,
) {
  return {
    type: actionTypes.GET_NAV_FAV_LIST,
    page,
    limit,
    appKey,
    pagination,
    showOriginal,
    projectKey,
    nodeType,
  };
}
export function getNavFavListSuccess(data: any, page: number, pagination?: boolean) {
  return {
    type: actionTypes.GET_NAV_FAV_LIST_SUCCEEDED,
    data,
    page,
    pagination,
  };
}

export function getClips(page: number, limit: number, nodeType?: string) {
  return {
    type: actionTypes.GET_CLIPS,
    page,
    limit,
    nodeType,
  };
}
export function getClipsSuccess(data: any, page: number) {
  return {
    type: actionTypes.GET_CLIPS_SUCCEEDED,
    data,
    page,
  };
}

export function search(keyWord: string, appKey?: string) {
  return {
    type: actionTypes.SEARCH,
    keyWord: keyWord,
    appKey,
  };
}

export function searchSuccess(data: any) {
  return {
    type: actionTypes.SEARCH_SUCCEEDED,
    data,
  };
}

// draw
export function addDraw(
  name?: string,
  nodeType?: string,
  detail?: ArticleDetail,
  appKey?: string,
  fatherId?: string,
  thumbnail?: string,
  styleType?: any,
) {
  return {
    type: actionTypes.ADD_DRAW,
    appKey,
    fatherId,
    name,
    nodeType,
    detail,
    thumbnail,
    styleType,
  };
}

export function editDraw(
  nodeKey: string,
  name?: string,
  nodeType?: string,
  detail?: ArticleDetail,
  status?: number,
  thumbnail?: string,
  url?: string,
  styleType?: any,
) {
  return {
    type: actionTypes.EDIT_DRAW,
    nodeKey,
    status,
    name,
    nodeType,
    detail,
    thumbnail,
    url,
    styleType,
  };
}
export function editDrawSuccess(nodeKey?: string, detail?: any, styleType?: any, data?: any, name?: string) {
  return {
    type: actionTypes.EDIT_DRAW_SUCCEEDED,
    nodeKey,
    saveStatus: 1,
    styleType,
    detail,
    data,
    name,
  };
}

export function toggleSearchPanel(visible: boolean) {
  return { type: actionTypes.OPEN_SEARCH_PANEL, visible };
}

export function togglePublicShareModal(visible: boolean) {
  return { type: actionTypes.TOGGLE_PUBLIC_SHARE_MODAL, visible };
}

export function searchNode(
  keyword: string,
  appKey?: string,
  nodeKey?: string,
  nodeType?: string,
  cardKey?: string,
  partialLoading?: boolean,
) {
  return {
    type: actionTypes.SEARCH_NODE,
    keyword,
    appKey,
    nodeKey,
    nodeType,
    cardKey,
    partialLoading,
  };
}
export function searchNodeSuccess(data: any, partialLoading?: boolean) {
  return {
    type: actionTypes.SEARCH_NODE_SUCCEEDED,
    data,
    partialLoading,
  };
}

export function clearArticles() {
  return {
    type: actionTypes.CLEAR_ARTICLES,
  };
}

export function getNtfyList(page: number, limit: number, unRead?: boolean, status?: number) {
  return {
    type: actionTypes.GET_NTFY_LIST,
    page,
    limit,
    unRead,
    status,
  };
}
export function getNtfyListSuccess(pagination: boolean, data: any, unRead?: boolean) {
  return {
    type: actionTypes.GET_NTFY_LIST_SUCCEEDED,
    pagination,
    data,
    unRead,
  };
}
export function readNtfy(messageKey?: string) {
  return {
    type: actionTypes.READ_NTFY,
    messageKey,
  };
}
export function readNtfySuccess(messageKey: string) {
  return {
    type: actionTypes.READ_NTFY_SUCCEEDED,
    messageKey,
  };
}
export function clearNtfy() {
  return {
    type: actionTypes.CLEAR_NTFY,
  };
}
export function clearNtfySuccess() {
  return {
    type: actionTypes.CLEAR_NTFY_SUCCEEDED,
  };
}
export function publicShare(nodeKey: string, isBook: boolean) {
  return {
    type: actionTypes.PUBLIC_SHARE,
    nodeKey,
    isBook,
  };
}
export function publicShareSuccess(nodeKey: string, isBook: boolean) {
  return {
    type: actionTypes.PUBLIC_SHARE_SUCCEEDED,
    nodeKey,
    isBook,
  };
}
export function checkPublicShare(nodeKey: string) {
  return {
    type: actionTypes.CHECK_PUBLIC_SHARE,
    nodeKey,
  };
}
export function checkPublicShareSuccess(data: any) {
  return {
    type: actionTypes.CHECK_PUBLIC_SHARE_SUCCEEDED,
    data,
  };
}
export function clearPublicShare(nodeKey: string) {
  return {
    type: actionTypes.CLEAR_PUBLIC_SHARE,
    nodeKey,
  };
}
export function publicShareTree(nodeKey: string, isPublic: boolean, allowEdit?: boolean) {
  return {
    type: actionTypes.PUBLIC_SHARE_TREE,
    nodeKey,
    isPublic,
    allowEdit,
  };
}
export function publicShareTreeSuccess(nodeKey: string) {
  return {
    type: actionTypes.PUBLIC_SHARE_TREE_SUCCEEDED,
    nodeKey,
  };
}

export function changeStyleType(data: any) {
  return {
    type: actionTypes.CHANGE_STYLE_TYPE,
    data,
  };
}

export function getRecycle(currentPage: number, perPage: number) {
  return {
    type: actionTypes.GET_RECYCLE,
    currentPage,
    perPage,
    partialLoading: true,
  };
}

export function getRecycleSuccess(data: any) {
  return {
    type: actionTypes.GET_RECYCLE__SUCCEEDED,
    data,
    partialLoading: true,
  };
}

export function restore(nodeKey: string) {
  return {
    type: actionTypes.RESTORE,
    nodeKey,
  };
}

export function restoreSuccess(nodeKey: string) {
  return {
    type: actionTypes.RESTORE_SUCCEEDED,
    nodeKey,
  };
}

export function deleteRecycle(nodeKeyArr?: string[]) {
  return {
    type: actionTypes.DELETE_RECYCLE,
    nodeKeyArr,
  };
}

export function deleteRecycleSuccess(nodeKeyArr: string[]) {
  return {
    type: actionTypes.DELETE_RECYCLE_SUCCEEDED,
    nodeKeyArr,
  };
}

export function clickPath(nodeKey: string, callback: Function) {
  return {
    type: actionTypes.CLICK_PATH,
    nodeKey,
    callback,
  };
}

export function fav(nodeKey: string) {
  return {
    type: actionTypes.FAV_ARTICLE,
    nodeKey,
  };
}

export function favSuccess(nodeKey: string, data: any) {
  return {
    type: actionTypes.FAV_ARTICLE_SUCCEEDED,
    nodeKey,
    data,
  };
}

export function articleChanged() {
  return {
    type: actionTypes.ARTICLE_CHANGED,
  };
}

export function getChildNodes(
  nodeKey: string,
  page: number,
  limit: number,
  returnFolder?: boolean,
  partialLoading?: boolean,
) {
  return {
    type: actionTypes.GET_CHILD_NODES,
    nodeKey,
    page,
    limit,
    returnFolder,
    partialLoading,
  };
}
export function getChildNodesSuccess(page: number, data: any, partialLoading?: boolean) {
  return {
    type: actionTypes.GET_CHILD_NODES_SUCCESS,
    page,
    data,
    partialLoading,
  };
}

export function getRecentUpload(currentPage: number, perPage: number, projectKey?: string) {
  return {
    type: actionTypes.GET_RECENT_UPLOAD,
    currentPage,
    perPage,
    projectKey,
  };
}
export function getRecentUploadSuccess(data: any, page: number) {
  return {
    type: actionTypes.GET_RECENT_UPLOAD_SUCCEEDED,
    data,
    page,
  };
}

export function setViewUploadLog(nodeKey: string) {
  return {
    type: actionTypes.SET_VIEW_UPLOAD_LOG,
    nodeKey,
  };
}

export function uploadFile(
  name: string,
  fatherId: string,
  url: string,
  fileSize: number,
  fileType: string,
  thumbnail?: string,
) {
  return {
    type: actionTypes.UPLOAD_FILE,
    name,
    nodeType: 'file',
    fatherId,
    url,
    fileSize,
    fileType,
    thumbnail,
  };
}

export function uploadFileSuccess(data: any) {
  return {
    type: actionTypes.UPLOAD_FILE_SUCCEEDED,
    data,
  };
}

export function getQuickAccess(currentPage: number, perPage: number) {
  return {
    type: actionTypes.GET_QUICK_ACCESS,
    currentPage,
    perPage,
  };
}

export function getQuickAccessSuccess(data: any) {
  return {
    type: actionTypes.GET_QUICK_ACCESS_SUCCEEDED,
    data,
  };
}

export function addQuickAccess(nodeKey: string) {
  return {
    type: actionTypes.ADD_QUICK_ACCESS,
    nodeKey,
  };
}

export function addQuickAccessSuccess(data: any) {
  return {
    type: actionTypes.ADD_QUICK_ACCESS_SUCCEEDED,
    data,
  };
}

export function removeQuickAccess(nodeKey: string) {
  return {
    type: actionTypes.REMOVE_QUICK_ACCESS,
    nodeKey,
  };
}

export function removeQuickAccessSuccess(nodeKey: string) {
  return {
    type: actionTypes.REMOVE_QUICK_ACCESS_SUCCEEDED,
    nodeKey,
  };
}

export function getBangumiList(userKey: string) {
  return {
    type: actionTypes.GET_BANGUMI_LIST,
    userKey,
  };
}

export function getBangumiListSuccess(data: any) {
  return {
    type: actionTypes.GET_BANGUMI_LIST_SUCCEEDE,
    data,
  };
}
