import { combineReducers } from 'redux';
import { common } from './common';
import { auth } from './auth';
import { article } from './article';
import { tree } from './tree';
import { task } from './task';
import { workspace } from './workspace';

export const rootReducer = combineReducers({
  auth,
  common,
  article,
  tree,
  task,
  workspace,
});

export type RootState = ReturnType<typeof rootReducer>;
