import { ArticleType } from '../interfaces/Article';

export class IndexDBOperate {
  // 数据库实例
  db: IDBDatabase | null = null;
  // 数据库名称
  databaseName: string | null = null;
  // 数据库版本
  version: number | null = null;
  // 表或对象仓库名称
  objStoName: any = null;
  constructor(databaseName: string, version: number, objStoName: string, successFunc?: Function, indexObj?: any) {
    this.databaseName = databaseName;
    this.version = version;
    this.objStoName = objStoName;

    // 若无此数据库则会创建
    const request = window.indexedDB.open(databaseName, version);
    request.onerror = (e) => {
      console.log('数据库打开报错');
    };
    request.onsuccess = (e) => {
      this.db = request.result;
      const db = request.result;
      console.log('数据库打开成功');
      // 建表或对象仓库，id作为主键
      if (successFunc) {
        successFunc(db);
      }
    };

    // 如果指定的版本号，大于数据库的实际版本号，就会发生数据库升级事件
    // 第一次打开数据库时，会先触发upgradeneeded事件，然后触发success事件
    request.onupgradeneeded = (e: any) => {
      // console.log('数据库打开/创建报错')
      // this.db = e.target.result
      const db = e.target ? e.target.result : null;

      // 先判断一下，这张表格是否存在，如果不存在再新建表
      if (!db.objectStoreNames.contains(objStoName)) {
        // 建表
        const objectStore = db.createObjectStore(objStoName, { keyPath: 'id' });

        // 新建索引（索引名称、索引所在的属性、配置对象（说明该属性是否包含重复的值））
        if (indexObj) {
          objectStore.createIndex(indexObj.indexName, indexObj.IndexAattrOf, indexObj.config);
        }
      }
    };

    request.onblocked = (e) => {
      console.log('上一次的数据库连接还未关闭');
    };
  }

  // 创建
  createData = (data: any) => {
    if (!this.db) {
      return;
    }
    const request = this.db
      .transaction([`${this.objStoName}`], 'readwrite')
      .objectStore(`${this.objStoName}`)
      .add(data);

    request.onsuccess = (e) => {
      console.log('数据写入成功');
    };

    request.onerror = (e: any) => {
      console.log('数据写入失败', e.target.error);
    };
  };

  // 更新
  updateData = (data: any) => {
    if (!this.db) {
      return;
    }
    const request = this.db
      .transaction([`${this.objStoName}`], 'readwrite')
      .objectStore(`${this.objStoName}`)
      .put(data);

    request.onsuccess = (e) => {
      console.log('数据更新成功');
    };

    request.onerror = (e) => {
      console.log('数据更新失败');
    };
  };

  // 读
  retrieveData = (id: string, successFunc: Function) => {
    if (!this.db) {
      console.log('---数据库还未打开---');
      return;
    }
    const transaction = this.db.transaction([`${this.objStoName}`]);
    const objectStore = transaction.objectStore(`${this.objStoName}`);
    const request = objectStore.get(id);

    request.onerror = (e) => {
      // console.log('事务失败')
    };

    request.onsuccess = (e) => {
      if (request.result) {
        successFunc(request.result);
        //  console.log('Name: ' + request.result.name)
        //  console.log('Age: ' + request.result.age)
        //  console.log('Email: ' + request.result.email)
      } else {
        successFunc(request.result);
        //  console.log('未获得数据记录')
      }
    };
  };

  // 遍历
  readAllData = (successFunc: Function) => {
    if (!this.db) {
      return;
    }
    const objectStore = this.db.transaction([`${this.objStoName}`], 'readwrite').objectStore(`${this.objStoName}`);

    objectStore.openCursor().onsuccess = (e: any) => {
      const cursor = e.target.result;

      if (cursor) {
        successFunc(cursor);
        cursor.continue();
        // console.log('Id: ' + cursor.key)
        // console.log('Name: ' + cursor.value.name)
        // console.log('Age: ' + cursor.value.age)
        // console.log('Email: ' + cursor.value.email)
      } else {
        // console.log('没有更多数据了！')
      }
    };
  };

  // 遍历（加入模糊搜索）
  fuzzySearchData = (field: any, keyWord: string, successFunc: Function) => {
    if (!this.db) {
      return;
    }
    const objectStore = this.db.transaction([`${this.objStoName}`], 'readwrite').objectStore(`${this.objStoName}`);
    const data: any[] = [];

    objectStore.openCursor().onsuccess = (e: any) => {
      const cursor = e.target.result;

      if (cursor) {
        if (cursor.value[`${field}`].indexOf(keyWord) >= 0) {
          data.push(cursor.value);
        }
        cursor.continue();

        // console.log('Id: ' + cursor.key)
        // console.log('Name: ' + cursor.value.name)
        // console.log('Age: ' + cursor.value.age)
        // console.log('Email: ' + cursor.value.email)
      } else {
        // console.log('没有更多数据了！')
        successFunc(data);
      }
    };
  };

  deleteData = (id: string) => {
    if (!this.db) {
      return;
    }
    const request = this.db
      .transaction([`${this.objStoName}`], 'readwrite')
      .objectStore(`${this.objStoName}`)
      .delete(id);

    request.onsuccess = (e) => {
      // console.log('数据删除成功')
    };
  };
  // 通过索引获取数据
  retrieveByIndex = (indexName: string, searchTerm: any, successFunc: Function) => {
    if (!this.db) {
      return;
    }
    const transaction = this.db.transaction([`${this.objStoName}`], 'readonly');
    const store = transaction.objectStore(`${this.objStoName}`);
    const index = store.index(`${indexName}`);
    const request = index.get(`${searchTerm}`);

    request.onsuccess = (e: any) => {
      const { result } = e.target;
      console.log(e.target);
      if (result) {
        // ...
        successFunc(result);
      } else {
        // ...
      }
    };
  };
  // 新增或者更新数据
  addOrUpdateData = (data: any) => {
    this.retrieveData(data.id, (result: any) => {
      if (result) {
        this.updateData(data);
      } else {
        this.createData(data);
      }
    });
  };
  // 比较服务器数据和本地数据，获取最新的一个
  getLocalDataOrServerData = (article: ArticleType) => {
    const that = this;
    return new Promise(async function (resolve, reject) {
      try {
        that.retrieveData(article._key, (result: any) => {
          if (result) {
            if (result.updateTime > article.updateTime) {
              console.log('使用本地数据');
              resolve(result.content);
            } else {
              console.log('使用云端数据');
              resolve(article.detail);
            }
          } else {
            console.log('使用云端数据');
            resolve(article.detail);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };
}
