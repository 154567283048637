import { actionTypes } from '../actions/workspaceActions';
import { actionTypes as treeActions } from '../actions/treeActions';
import { actionTypes as articleActions } from '../actions/articleActions';
import Node from '../../interfaces/Node';

export interface WorkspaceType {
  addedPage: Node | null;
  userProfileVisible: boolean;
  floatMenuOpened: boolean;
}

const defaultState: WorkspaceType = {
  addedPage: null,
  userProfileVisible: false,
  floatMenuOpened: false,
};

export const workspace = (state = defaultState, action: any) => {
  switch (action.type) {
    case actionTypes.SWITCH_USER_PROFILE: {
      return {
        ...state,
        userProfileVisible: action.visible !== undefined ? action.visible : !state.userProfileVisible,
      };
    }
    case actionTypes.SWITCH_FLOAT_MENU: {
      return {
        ...state,
        floatMenuOpened: action.visible !== undefined ? action.visible : !state.floatMenuOpened,
      };
    }
    case treeActions.CREATE_NODE_SUCCEEDED: {
      if (action.inTiptap) {
        const addedNode = { ...action.data.data, ...action.nodeInfoObj };
        return {
          ...state,
          addedPage: addedNode,
        };
      } else {
        return state;
      }
    }
    case articleActions.EDIT_ARTICLE_SUCCEEDED: {
      return {
        ...state,
        addedPage: null,
      };
    }
    default:
      return state;
  }
};
