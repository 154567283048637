import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Pages from './Pages';
import socketIOClient from 'socket.io-client';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../src/theme';
import Message from './components/common/Message';
import PublicShareModal from './components/common/PublicShareModal';
import { SocketProvider } from 'socket.io-react';
import SoundEffect from './components/common/SoundEffect';

let socket = null;
if (window.parent === window.self) {
  socket = socketIOClient('https://nodeserver.qingtime.cn');
  socket.on('connect', () => {
    console.log('---socket连接成功！---');
  });
}

ReactDOM.render(
  <SocketProvider socket={socket}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Pages />
        <Message />
        <SoundEffect />
        <PublicShareModal />
      </Provider>
    </ThemeProvider>
  </SocketProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
