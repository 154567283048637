import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#7C7B7B',
      zIndex: 999,
    },
    icon: {
      width: '120px',
      height: '120px',
      backgroundImage: 'url(/icon/loading.gif)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
  }),
);

interface Props {
  isRelative?: boolean;
}

export default function Loading({ isRelative }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.loading} style={{ position: isRelative ? 'relative' : 'absolute' }}>
      <CircularProgress size={28} color="inherit" />
      {/* <i className={classes.icon}></i> */}
    </div>
  );
}
