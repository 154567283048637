import Node from '../interfaces/Node';
import NodeMap from '../interfaces/NodeMap';
import AppType from '../interfaces/AppType';
import { ArticleType } from '../interfaces/Article';
import api from './api';

export const guid = (len?: number, radix?: number) => {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
};

// 获取url中search的值
export const getSearchParamValue = (search: string, paramName: string) => {
  const QUERY_PARAMS = new URLSearchParams(search);
  return QUERY_PARAMS.get(paramName);
};

export const is_mobile = () => {
  let regex_match =
    /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220|Mobile)/i;
  let u = navigator.userAgent;
  if (null == u) {
    return true;
  }
  let result = regex_match.exec(u);

  if (null == result) {
    return false;
  } else {
    return true;
  }
};

/**
 * 获取子孙节点
 * @param node
 * @param nodeMap
 * @param includeSelf
 * @returns
 */
export const getAncestor = (node: Node, nodeMap: NodeMap, includeSelf?: boolean) => {
  const getFather = (node: Node) => {
    const father = nodeMap[node.father];
    if (father) {
      ancestorList.unshift(father);
      getFather(father);
    }
  };

  let ancestorList: Node[] = includeSelf ? [node] : [];
  getFather(node);
  return ancestorList;
};

export const getChildren = (node: Node, nodeMap: NodeMap) => {
  const getChild = (node: Node) => {
    map[node._key] = node;
    const sortList = node.sortList;
    for (let index = 0; index < sortList.length; index++) {
      const key = sortList[index];
      const node = nodeMap[key];
      if (node) {
        getChild(node);
      }
    }
  };

  let map: NodeMap = {};
  if (node) {
    getChild(node);
  }
  return map;
};

const isArrayRepeat = (array1: any[], array2: any[]) => {
  let set = new Set([...array1, ...array2]);
  return array1.length + array2.length !== set.size;
};

/**
 * 获取有效的多选节点（框选拖拽节点时，如果同时选择父节点与其子节点，只有父节点为有效节点）
 * @param selectedNodes
 * @param nodeMap
 */
export const getValidSelectedNodes = (selectedNodes: Node[], nodeMap: NodeMap) => {
  let validSelectedKeys: Node[] = [];
  let selectedIds = [];
  for (let index = 0; index < selectedNodes.length; index++) {
    const node = selectedNodes[index];
    selectedIds.push(node._key);
  }
  for (let index = 0; index < selectedNodes.length; index++) {
    const node = selectedNodes[index];
    const ancestors = getAncestor(node, nodeMap);
    if (!isArrayRepeat(ancestors, selectedIds)) {
      validSelectedKeys.push(node);
    }
  }
  return validSelectedKeys;
};

export const isJson = (str: string) => {
  if (typeof str == 'string') {
    try {
      JSON.parse(str);
      if (str.indexOf('{') > -1) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  return false;
};

/**
 * @name plainTextToNaotuFormat
 * @description
 * mirror of kityminder-core
 * source: https://github.com/fex-team/kityminder-core/blob/dev/src/core/data.js#L80-L167
 * @param {string} text
 */
export const plainTextToNaotuFormat = (text: string) => {
  let children = [];
  let jsonMap: any = {};
  let level = 0;
  let LINE_SPLITTER = /\r|\n|\r\n/;
  let TAB_REGEXP = /^(\t|\x20{2})/;
  let lines = text.split(LINE_SPLITTER);
  let line = '';
  let jsonNode;
  let i = 0;

  function isEmpty(line: string) {
    return line === '' && !/\S/.test(line);
  }

  function getNode(line: string) {
    return {
      data: {
        id: guid(8, 16),
        text: line.replace(/^(\t|\x20{2})+/, '').replace(/(\t|\x20{2})+$/, ''),
      },
      children: [],
    };
  }

  function getLevel(text: string) {
    var level = 0;
    while (TAB_REGEXP.test(text)) {
      text = text.replace(TAB_REGEXP, '');
      level++;
    }
    return level;
  }

  function addChild(parent: any, node: any) {
    parent.children.push(node);
  }

  while ((line = lines[i++]) !== undefined) {
    line = line.replace(/&nbsp;{2}/g, ''); //空格数 /^(\t|\x20{2})/ {2}表示两个空格 &nbsp;&nbsp;同理
    if (isEmpty(line)) continue;

    level = getLevel(line);
    jsonNode = getNode(line);
    if (level === 0) {
      jsonMap = {};
      children.push(jsonNode);
      jsonMap[0] = children[children.length - 1];
    } else {
      if (!jsonMap[level - 1]) {
        throw new Error('格式错误'); // Invalid local format
      }
      addChild(jsonMap[level - 1], jsonNode);
      jsonMap[level] = jsonNode;
    }
  }
  return children;
};

export const getNodeUrl = (node: Node | ArticleType, edit?: boolean) => {
  let url;
  if (node.type === 'folder' || node.type?.includes('Folder')) {
    url = `${window.location.protocol}//${window.location.host}/home/view/tasktree?id=${node._key}`;
  } else {
    switch (node.type) {
      case 'doc': {
        if (node.editorPath) {
          if (node.docType === 'e-book') {
            url = `${window.location.protocol}//${window.location.host}${node.editorPath}?bookid=${node._key}`;
          } else if (node.docType === 'task-tree') {
            url = `${window.location.protocol}//${window.location.host}${node.editorPath}?id=${node._key}`;
          } else if (node.docType === 'knowledgebase') {
            url = `${window.location.protocol}//${window.location.host}${node.editorPath}?baseid=${node._key}`;
          } else {
            url = `${window.location.protocol}//${window.location.host}${node.editorPath}?key=${node._key}${
              edit ? '&type=edit' : ''
            }`;
          }
        } else {
          url = node.uri;
        }
        break;
      }
      case 'link': {
        if (!node.url) {
          return;
        }
        if (node.url.includes('http://') || node.url.includes('https://')) {
          url = node.url;
        } else {
          url = `https://${node.url}`;
        }
        break;
      }
      case 'file': {
        url = `${window.location.protocol}//${window.location.host}/home/fileViewer?key=${node._key}`;
        break;
      }
      case 'flexNode': {
        url = `${window.location.protocol}//${window.location.host}/home/card?key=${node._key}`;
        break;
      }
      case 'task': {
        url = `${window.location.protocol}//${window.location.host}/home/detail/task?key=${node._key}`;
        break;
      }
      default:
        break;
    }
  }

  return url;
};

export const getAppUrl = async (node: AppType) => {
  const appHigh = node.appHigh;
  const autoLoginPath = node.autoLoginPath;
  if (appHigh && autoLoginPath) {
    const res: any = await api.auth.switchToken(node.appHigh || 0);
    if (res.statusCode === '200') {
      const token = res.result.token;
      const uri = `${node.uri}${autoLoginPath}${token}`;
      return uri;
    } else {
      return null;
    }
  }
};

/**
 * 获取第三方应用地址
 * @param nodeKey
 * @returns
 */
export const getThirdAppUrl = (nodeKey: string, appUrl: string, editMode?: boolean, isDraw?: boolean) => {
  const getDataApi = JSON.stringify({
    url: 'https://timeosdata.qingtime.cn/node/detail',
    params: { nodeKey },
  });
  const patchDataApi = JSON.stringify({
    url: 'https://timeosdata.qingtime.cn/node',
    params: { nodeKey, type: 'doc' },
    docDataName: isDraw ? ['detail', 'name'] : 'detail',
  });
  const getUptokenApi = JSON.stringify({
    url: 'https://baokudata.qingtime.cn/sgbh/upTokenQiniu/getQiNiuUpToken',
    params: { token: '', type: 2 },
  });
  const token = localStorage.getItem('auth_token');
  // isEdit 2:编辑模式 1:只读 0:预览
  const query = `token=${token}&getDataApi=${encodeURIComponent(getDataApi)}&patchDataApi=${encodeURIComponent(
    patchDataApi,
  )}&getUptokenApi=${encodeURIComponent(getUptokenApi)}&isEdit=${editMode ? 2 : 0}&hideHead=1`;
  return `${appUrl}?${query}`;
};

export const openArticleInPage = (article: ArticleType, app?: AppType, newTab?: boolean) => {
  let url;
  if (article.type === 'folder' || article.type.includes('Folder') || article.type === 'project') {
    window.open(`${window.location.origin}/home/project/repository?id=${article._key}`, '_blank');
    // localStorage.setItem('last-repo-root-id', article._key);
  } else if (article.type === 'flexNode') {
    url = `${window.location.protocol}//${window.location.host}/home/card?key=${article._key}`;
  } else if (article.type === 'doc' && app) {
    switch (app.suffix) {
      case 'qdoc':
        url = `${window.location.protocol}//${window.location.host}/home/qdoc/docEditor?key=${article._key}`;
        break;
      case 'blocks':
        url = `${window.location.protocol}//${window.location.host}/home/qdoc/blocks?key=${article._key}`;
        break;
      case 'md':
        url = `${window.location.protocol}//${window.location.host}/home/md/markdown?key=${article._key}`;
        break;
      case 'sheet':
        url = `${window.location.protocol}//${window.location.host}/home/viewer/sheet?key=${article._key}`;
        break;
      case 'mind':
        url = `${window.location.protocol}//${window.location.host}/home/mind/mind?key=${article._key}`;
        break;
      case 'qppt': {
        url = `${window.location.protocol}//${window.location.host}/home/viewer/ppt?key=${article._key}`;
        break;
      }
      case 'draw': {
        url = `${window.location.protocol}//${window.location.host}/home/viewer/draw?key=${article._key}`;
        break;
      }
      case 'task':
        // url = `${window.location.protocol}//${window.location.host}/home/task/tasktree?id=${article._key}`;
        url = `${window.location.protocol}//${window.location.host}/home/project/repository?id=${article._key}`;
        break;
      case 'book':
        url = `${window.location.protocol}//${window.location.host}/home/qdoc/book?bookid=${article._key}`;
        break;
      case 'base':
        url = `${window.location.protocol}//${window.location.host}/home/base/knowledgebase?baseid=${article._key}`;
        break;
      case 'bangumi':
        url = `${window.location.protocol}//${window.location.host}/home/qdoc/bangumi?key=${article._key}`;
        break;
      default:
        break;
    }
  } else if (article.type === 'link') {
    if (!article.url) {
      return;
    }
    if (article.url.includes('http://') || article.url.includes('https://')) {
      url = article.url;
    } else {
      url = `https://${article.url}`;
    }
  } else if (article.type === 'file') {
    url = `${window.location.protocol}//${window.location.host}/home/fileViewer?key=${article._key}`;
  } else if (article.type === 'folder' || article.type.includes('Folder') || article.type === 'timePocket') {
    url = `${window.location.protocol}//${window.location.host}/home/project/repository?id=${article._key}`;
  }
  if (url) {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }
};

export const openDoc = (node: Node | ArticleType | null, edit?: boolean, openType?: 'newTab' | 'newWindow') => {
  if (!node) {
    return;
  }
  const url = getNodeUrl(node, edit);
  if (url) {
    if (openType) {
      switch (openType) {
        case 'newTab':
          window.open(url, '_blank');
          break;
        case 'newWindow': {
          const screenWidth = window.screen.width;
          const screenHeight = window.screen.height;
          window.open(
            url,
            'new',
            `width=${screenWidth * 0.6}, height=${screenHeight * 0.8},top=${
              (screenHeight - screenHeight * 0.8) / 2
            }, left=${(screenWidth - screenWidth * 0.6) / 2},toolbar=no, menubar=no, location=no, status=no`,
          );
          break;
        }
        default:
          break;
      }
    } else {
      window.location.href = url;
    }
  }
};

interface TreeData {
  rootKey: string;
  viewType: string;
  data: NodeMap;
}
export const exportNodes2Txt = (title: string, treeData: TreeData) => {
  let textList: string[] = [];
  const root = treeData.data[treeData.rootKey];
  location(treeData.data, root, 0);
  downloadText(`${title}.txt`, textList.join('\r\n'));

  function location(nodes: NodeMap, node: Node, x: number) {
    let text = node.name;
    for (let index = 0; index < x; index++) {
      text = ' '.concat(text);
    }
    textList.push(text);
    const childrenIds = node.sortList || [];
    let childX = x + 1;
    // 遍历子节点
    for (let index = 0; index < childrenIds.length; index++) {
      const element = nodes[childrenIds[index]];
      location(nodes, element, childX);
    }
  }
};

function downloadText(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function download(url: string, fileName: string) {
  fetch(url).then((res) =>
    res.blob().then((blob) => {
      var a = document.createElement('a');
      var url = window.URL.createObjectURL(blob);
      var filename = fileName;
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }),
  );
}

export function clickPath(path: Node | ArticleType) {
  const nodeKey = path.father || path._key;
  const isWorkspace = window.location.pathname.includes('/home/workspace') ? true : false;
  if (isWorkspace) {
    window.open(`${window.location.pathname}?node-id=${nodeKey}`, '_blank');
  } else {
    window.open(`${window.location.origin}/home/view/tasktree?id=${nodeKey}`, '_blank');
  }
  // localStorage.setItem('last-repo-root-id', nodeKey);
}

export function getFileType(node: ArticleType, appMap: any): string | undefined {
  if (node.type === 'shortcuts' && node.linkNode) {
    return getFileType(node.linkNode, appMap);
  } else if ((node.type && node.type.includes('Folder')) || node.type === 'folder') {
    return '文件夹';
  } else if (node.type === 'flexNode') {
    return node.cardName || '卡片';
  } else if (node.type === 'link') {
    return '链接';
  }
  switch (node.type) {
    case 'doc':
      if (node.docType === 'task-tree') {
        return '项目';
      } else if (node.docType === 'e-book') {
        return '电子书';
      } else if (node.docType === 'knowledgebase') {
        return '知识库';
      } else {
        const app = appMap ? appMap[node.appKey] : null;
        if (app) {
          return app.name;
        } else {
          return undefined;
        }
      }
    case 'project':
      return '机构';
    case 'file':
      return '文件';
    default:
      return undefined;
  }
}

export function getSelectionCoords() {
  let win = window;
  const doc: any = win.document;
  let sel = doc.selection;
  let range;
  let rects;
  let rect;

  var x = 0,
    y = 0;
  if (sel) {
    if (sel.type !== 'Control') {
      range = sel.createRange();
      range.collapse(true);
      x = range.boundingLeft;
      y = range.boundingTop;
    }
  } else if (win.getSelection) {
    sel = win.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0).cloneRange();
      if (range.getClientRects) {
        range.collapse(true);
        rects = range.getClientRects();
        if (rects.length > 0) {
          rect = rects[0];
        }
        // 光标在行首时，rect为undefined
        if (rect) {
          x = rect.left;
          y = rect.top;
        }
      }
      // Fall back to inserting a temporary element
      if ((x === 0 && y === 0) || rect === undefined) {
        var span = doc.createElement('span');
        if (span.getClientRects) {
          // Ensure span has dimensions and position by
          // adding a zero-width space character
          span.appendChild(doc.createTextNode('\u200b'));
          range.insertNode(span);
          rect = span.getClientRects()[0];
          x = rect.left;
          y = rect.top;
          var spanParent = span.parentNode;
          spanParent.removeChild(span);

          // Glue any broken text nodes back together
          spanParent.normalize();
        }
      }
    }
  }
  return { x: x, y: y };
}

export function getPopupPosition(x: number, y: number, width: number, height: number, lineHeight: number) {
  let positionX = 0;
  let positionY = 0;
  if (x + width > document.body.clientWidth) {
    positionX = x - (x + width - document.body.clientWidth) - 30;
  } else {
    positionX = x - 30;
  }
  if (y + lineHeight + height > document.body.clientHeight) {
    positionY = y - height;
  } else {
    positionY = y + lineHeight;
  }
  return {
    positionX,
    positionY,
  };
}

export function getFileTypeByName(name: string) {
  const fileExtension = name.split('.').pop();
  if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg') {
    return 'image/*';
  } else if (fileExtension === 'mp3' || fileExtension === 'aac' || fileExtension === 'wav') {
    return 'audio/*';
  } else if (fileExtension === 'mp4') {
    return 'video/*';
  } else if (fileExtension === 'pdf') {
    return 'application/pdf';
  } else if (
    fileExtension === 'docx' ||
    fileExtension === 'doc' ||
    fileExtension === 'xls' ||
    fileExtension === 'xlsx' ||
    fileExtension === 'ppt' ||
    fileExtension === 'pptx'
  ) {
    return 'application/msword';
  } else {
    return 'unknow';
  }
}

// 获取元素在屏幕中的位置
export function getElPosition(el: HTMLElement) {
  let element: HTMLElement | null = el;
  let x = 0;
  let y = 0;
  while (element !== document.body && element) {
    x += element.offsetLeft;
    y += element.offsetTop;
    element = element.offsetParent as HTMLElement;
  }
  //计算想对位置
  x += window.screenLeft + document.body.clientLeft;
  y += window.screenTop + document.body.clientTop;
  return { x, y };
}

/**
 * 函数防抖 => 连续的事件响应我们只触发一次回调
 * 这是一个闭包
 * @param func
 * @param wait
 */
export function debounce(func: Function, wait: number, immediateFunc?: Function) {
  let timeout: NodeJS.Timeout | null = null;
  return (args: any) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (immediateFunc) {
      immediateFunc(args);
    }
    timeout = setTimeout(() => {
      func(args);
      timeout = null;
    }, wait);
  };
}

/**
 * 节流
 * @param func
 * @param gapTime
 * @returns
 */
export function throttle(func: Function, gapTime: number) {
  let lastTime = 0;
  return (args: any) => {
    let time = new Date().getTime();
    if (time - lastTime > gapTime || !lastTime) {
      func(args);
      lastTime = time;
    }
  };
}

/**
 * 判断是否是絵文字
 * @param str
 * @returns
 */
export function isEmoji(str?: string) {
  return str ? /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]/g.test(str) : false;
}
