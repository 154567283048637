import UserConfig from '../../interfaces/UserConfig';

export const actionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCEEDED: 'LOGIN_SUCCEEDED',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
  REGISTER: 'REGISTER',
  REGISTER_SUCCEEDED: 'REGISTER_SUCCEEDED',
  LOGIN_BY_TOKEN: 'LOGIN_BY_TOKEN',
  LOGIN_BY_TOKEN_SUCCEEDED: 'LOGIN_BY_TOKEN_SUCCEEDED',
  LOGIN_BY_QRID: 'LOGIN_BY_QRID',
  LOGIN_BY_QRID_SUCCEEDED: 'LOGIN_BY_QRID_SUCCEEDED',
  GET_QINIU_TOKEN: 'GET_QINIU_TOKEN',
  GET_QINIU_TOKEN_SUCCEEDED: 'GET_QINIU_TOKEN_SUCCEEDED',
  INIT_APP: 'INIT_APP',
  INIT_APP_SUCCEEDED: 'INIT_APP_SUCCEEDED',
  GET_APPS: 'GET_APPS',
  GET_APPS_SUCCEEDED: 'GET_APPS_SUCCEEDED',
  INIT: 'INIT',
  INIT_SUCCEEDED: 'INIT_SUCCEEDED',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  UPDATE_CONFIG_SUCCEEDED: 'UPDATE_CONFIG_SUCCEEDED',
  SWITCH_SPACE: 'SWITCH_SPACE',
  REMAIN_STORAGE: 'REMAIN_STORAGE',
  REMAIN_STORAGE_SUCCEEDED: 'REMAIN_STORAGE_SUCCEEDED',
};

export function login(username: string, password: string) {
  return {
    type: actionTypes.LOGIN,
    username: username,
    password: password,
  };
}

export function loginFailed() {
  return {
    type: actionTypes.LOGIN_FAILED,
  };
}

export function loginSuccess(data: any) {
  return {
    type: actionTypes.LOGIN_SUCCEEDED,
    data,
  };
}

export function register(username: string, password: string) {
  return {
    type: actionTypes.REGISTER,
    username: username,
    password: password,
  };
}

export function registerSuccess(data: any) {
  return {
    type: actionTypes.REGISTER_SUCCEEDED,
    data,
  };
}

export function loginByToken(token: string, qr_id?: string) {
  return {
    type: actionTypes.LOGIN_BY_TOKEN,
    token: token,
    qr_id,
  };
}

export function loginByTokenSuccess(data: any) {
  return {
    type: actionTypes.LOGIN_BY_TOKEN_SUCCEEDED,
    data,
  };
}

export function loginByQrid(qrid: string) {
  return {
    type: actionTypes.LOGIN_BY_QRID,
    qrid,
  };
}

export function loginByQridSuccess(data: any) {
  return {
    type: actionTypes.LOGIN_BY_QRID_SUCCEEDED,
    data,
  };
}

export function logout() {
  return {
    type: actionTypes.LOGOUT,
  };
}

export function getQiniuToken() {
  return {
    type: actionTypes.GET_QINIU_TOKEN,
  };
}

export function getQiniuTokenSuccess(data: any) {
  return {
    type: actionTypes.GET_QINIU_TOKEN_SUCCEEDED,
    data,
  };
}

export function initApp(suffix?: string) {
  return {
    type: actionTypes.INIT_APP,
    suffix,
  };
}

export function initAppSuccess(data: any) {
  return {
    type: actionTypes.INIT_APP_SUCCEEDED,
    data,
  };
}

export function getApps(noToken?: boolean) {
  return {
    type: actionTypes.GET_APPS,
    noToken,
  };
}

export function getAppsSuccess(data: any) {
  return {
    type: actionTypes.GET_APPS_SUCCEEDED,
    data,
  };
}

export function init() {
  return {
    type: actionTypes.INIT,
  };
}

export function initSuccess(data: any) {
  return {
    type: actionTypes.INIT_SUCCEEDED,
    data,
  };
}

export function updateConfig(config: UserConfig) {
  return {
    type: actionTypes.UPDATE_CONFIG,
    config,
  };
}

export function updateConfigSuccess(config: UserConfig) {
  return {
    type: actionTypes.UPDATE_CONFIG_SUCCEEDED,
    config,
  };
}

export function switchSpace(spaceKey: string, spaceName: string) {
  return {
    type: actionTypes.SWITCH_SPACE,
    spaceKey,
    spaceName,
  };
}

export function remainStorage() {
  return {
    type: actionTypes.REMAIN_STORAGE,
  };
}

export function remainStorageSuccess(data: any) {
  return {
    type: actionTypes.REMAIN_STORAGE_SUCCEEDED,
    data,
  };
}
