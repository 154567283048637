import { call, put, takeLatest } from 'redux-saga/effects';
import {
  actionTypes,
  getProjectTasksSuccess,
  updateTaskSuccess,
  getProjectTaskTreeListSuccess,
  addTaskSuccess,
  getRecentTasksSuccess,
  getFilterConfigSuccess,
  getDateListSuccess,
  getUserTasksSuccess,
  fileCompeletedTasksSuccess,
  getTaskListSuccess,
} from '../actions/taskActions';
import { Failed } from '../actions/commonActions';
import api from '../../util/api';
import ResponseGenerator from '../../interfaces/ResponseGenerator';

function* getProjectTask(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getProjectTask,
      action.filterConfig,
      action.tabType,
      action.projectKey,
      action.friendKey,
    );
    if (res.status === 200) {
      yield put(getProjectTasksSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getTaskList(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getTaskList,
      action.filterConfig,
      action.tabType,
      action.friendKey,
      action.currentPage,
      action.perPage,
    );
    if (res.status === 200) {
      yield put(getTaskListSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getProjectTaskTree(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getProjectTaskTree, action.projectKey);
    if (res.status === 200) {
      yield put(getProjectTaskTreeListSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* updateTask(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateProjectTask,
      action.nodeKey,
      action.executor,
      action.hour,
      action.limitDay,
      action.check,
      action.name,
      action.filed,
    );
    if (res.status === 200) {
      yield put(updateTaskSuccess(action.treeKey, action.nodeKey, res, action.check));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* addTask(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.addTask, action.name, action.fathersObj);
    if (res.status === 200) {
      yield put(addTaskSuccess(action.treeKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getRecentTasks(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getRecentTasks,
      action.currentPage,
      action.perPage,
      action.filterConfig,
    );
    if (res.status === 200) {
      yield put(getRecentTasksSuccess(action.currentPage, res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getFilterConfig(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getTaskFilterConfig);
    if (res.status === 200) {
      yield put(getFilterConfigSuccess(res, action.userKey));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getDateList(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getTaskDateList, action.userKey);
    if (res.status === 200) {
      yield put(getDateListSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getUserTasksByDay(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getUserTasksByDay, action.userKey, action.date);
    if (res.status === 200) {
      yield put(getUserTasksSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* fileCompeletedTasks(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.fileCompeletedTasks, action.treeKey);
    if (res.status === 200) {
      yield put(fileCompeletedTasksSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

const taskSagas = [
  takeLatest(actionTypes.GET_PROJECT_TASKS, getProjectTask),
  takeLatest(actionTypes.UPDATE_TASK, updateTask),
  takeLatest(actionTypes.GET_PROJECT_TASK_TREE, getProjectTaskTree),
  takeLatest(actionTypes.CREATE_TASK, addTask),
  takeLatest(actionTypes.GET_RECENT_TASKS, getRecentTasks),
  takeLatest(actionTypes.GET_FILTER_CONFIG, getFilterConfig),
  takeLatest(actionTypes.GET_DATE_LIST, getDateList),
  takeLatest(actionTypes.GET_USER_TASKS, getUserTasksByDay),
  takeLatest(actionTypes.FILE_COMPELETED_TASKS, fileCompeletedTasks),
  takeLatest(actionTypes.GET_TASK_LIST, getTaskList),
];
export default taskSagas;
