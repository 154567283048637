import { actionTypes } from '../actions/treeActions';
import { actionTypes as articleActionTypes } from '../actions/articleActions';
import { actionTypes as commonActionTypes } from '../actions/commonActions';
import { actionTypes as taskActionTypes } from '../actions/taskActions';
import NodeMap from '../../interfaces/NodeMap';
import Node from '../../interfaces/Node';
import Comment from '../../interfaces/Comment';
import { Card } from '../../interfaces/Card';
import { CardFieldType } from '../../interfaces/CardFieldType';
import { ArticleType } from '../../interfaces/Article';
import { Task } from '../../interfaces/ProjectTask';

export interface TreeType {
  // 視圖：單列、多列
  viewType: string;
  // 節點路徑
  nodePath: Node[];
  // 節點集合
  nodeMap: NodeMap | null;
  // 根節點key
  rootKey: string | null;
  // 空间站节点集合
  projectNodeMap: NodeMap | null;
  // 空间站根节点
  projectRootKey: string | null;
  // 目录根节点
  menuRootKey: string | null;
  // 目錄節點集合
  menuNodeMap: NodeMap | null;
  // 快速访问目录根节点
  quickAccessRootKey: string | null;
  // 快速访问目录节点集合
  quickAccessNodeMap: NodeMap | null;

  // 選中的節點
  selectedNode: Node | null;
  selectedNodes: Node[];
  // 將要選中的節點key
  toSelectKey: string | null;
  toSelectNode: Node | null;
  // 空间站左侧目录将要选中的key
  projectToSelectKey: string | null;
  // 將要編輯的節點
  toEditKey: string | null;
  defaultNoteFocusedNodeId: string;
  // 選中的左側菜單節點
  selectedMenuNode: Node | null;
  // 左側菜單是否收起
  menuCollapsed: boolean;
  // 是否顯示添加協作面板
  sharePanelVisible: boolean;
  // 是否顯示文檔面板
  docPanelVisible: boolean;
  // 是否顯示消息面板
  notificationVisible: boolean;
  // 是否顯示歷史面板
  historyVisible: boolean;
  previewVisible: boolean;
  focusedTree: 'menu' | 'repo' | null;
  logList: any[];
  totalLog: number;
  docPanelType: number;
  projectList: Node[];
  loading: boolean;
  transparentLoading: boolean;
  editable: boolean;
  isPublic: boolean;
  commentList: Comment[];
  commentCount: number;
  fontType: 'normal' | 'bold';
  rootKeyHistory: ArticleType[];
  cardList: Card[];
  cardDetail: CardFieldType[];
  pasteNode: Node | null;
}

const MENU_COLLAPSED = localStorage.getItem('MENU_COLLAPSED');

const defaultState: TreeType = {
  viewType: localStorage.getItem('view-type') ? localStorage.getItem('view-type') || 'mutil' : 'mutil',
  nodePath: [],
  nodeMap: null,
  rootKey: null,
  projectNodeMap: null,
  projectRootKey: null,
  menuRootKey: null,
  menuNodeMap: null,
  quickAccessRootKey: null,
  quickAccessNodeMap: null,
  selectedNode: null,
  selectedNodes: [],
  toSelectKey: null,
  toSelectNode: null,
  projectToSelectKey: null,
  toEditKey: null,
  defaultNoteFocusedNodeId: '',
  selectedMenuNode: null,
  menuCollapsed: MENU_COLLAPSED === null ? false : JSON.parse(MENU_COLLAPSED),
  sharePanelVisible: false,
  docPanelVisible: false,
  notificationVisible: false,
  historyVisible: false,
  previewVisible: false,
  focusedTree: null,
  logList: [],
  totalLog: 0,
  docPanelType: 0,
  projectList: [],
  loading: false,
  transparentLoading: false,
  editable: false,
  isPublic: false,
  commentList: [],
  commentCount: 0,
  fontType: (localStorage.getItem('font-type') as 'normal' | 'bold') || 'normal',
  rootKeyHistory: [],
  cardList: [],
  cardDetail: [],
  pasteNode: null,
};

function deleteNodeById(node: Node, nodes: NodeMap) {
  let sortList = node.sortList;
  for (let index = 0; sortList && index < sortList.length; index++) {
    let childKey = sortList[index];
    let child = nodes[childKey];
    if (child) {
      deleteNodeById(child, nodes);
    }
  }
  delete nodes[node._key];
}

function addSibling(selectedId: string, targetNodeKey: string, type: 'up' | 'down', nodeMap: NodeMap) {
  let selectedNode = nodeMap[selectedId];
  let targetNode = nodeMap[targetNodeKey];
  if (!selectedNode || !targetNode) {
    return;
  }
  if (selectedNode.father === targetNode.father) {
    // 從選中節點的父節點的children中刪除當前id
    let father = nodeMap[targetNode.father];
    if (!father) {
      return;
    }
    let sortList = father.sortList;
    sortList.splice(sortList.indexOf(selectedNode._key), 1);
    // 將當前id添加到目標節點的父節點children中
    sortList.splice(
      type === 'down' ? sortList.indexOf(targetNodeKey) + 1 : sortList.indexOf(targetNodeKey),
      0,
      selectedId,
    );
  } else {
    // 從選中節點的父節點的children中刪除當前id
    let selectedNodeFather = nodeMap[selectedNode.father];
    if (!selectedNodeFather) {
      return;
    }
    let sortList = selectedNodeFather.sortList;
    sortList.splice(sortList.indexOf(selectedNode._key), 1);
    selectedNode.father = targetNode.father;
    // 將當前id添加到目標節點的父節點children中
    let targetFather = nodeMap[targetNode.father];
    if (!targetFather) {
      return;
    }
    let children2 = targetFather.sortList;
    children2.splice(
      type === 'down' ? children2.indexOf(targetNodeKey) + 1 : children2.indexOf(targetNodeKey),
      0,
      selectedId,
    );
  }
}

function addNode(nodemap: NodeMap, action: any) {
  const addedNode = action.data.data;
  // 添加节点
  if (action.addType === 'child') {
    const selectedNode = nodemap[action.nodeKey];
    if (selectedNode) {
      selectedNode.sortList.push(addedNode._key);
      nodemap[addedNode._key] = addedNode;
    }
  } else if (action.addType === 'peer' || action.addType === 'elder-peer') {
    // 添加兄弟节点
    let selectedNode = nodemap[action.nodeKey];
    if (selectedNode) {
      let fatherNode = nodemap[selectedNode.father];
      if (fatherNode) {
        let brotherKeys = fatherNode.sortList;
        const index = brotherKeys.indexOf(action.nodeKey);
        brotherKeys.splice(action.addType === 'peer' ? index + 1 : index, 0, addedNode._key);
        fatherNode.sortList = brotherKeys;
        nodemap[addedNode._key] = addedNode;
      }
    }
  }
}

export const tree = (state = defaultState, action: any) => {
  switch (action.type) {
    case actionTypes.CLEAR_TREE:
      if (!action.treeType) {
        return {
          ...state,
          // nodePath: [],
          nodeMap: null,
          rootKey: null,
          menuNodeMap: null,
          menuRootKey: null,
          projectNodeMap: null,
          projectRootKey: null,
          quickAccessRootKey: null,
          quickAccessNodeMap: null,
          type: null,
          selectedNode: null,
          toSelectKey: null,
          projectToSelectKey: null,
          toEditKey: null,
          selectedMenuNode: null,
        };
      } else if (action.treeType === 'tree') {
        return {
          ...state,
          // nodePath: [],
          nodeMap: null,
          rootKey: null,
          type: null,
          selectedNode: null,
          toSelectKey: null,
          toEditKey: null,
          selectedMenuNode: null,
        };
      } else if (action.treeType === 'menu-tree') {
        return {
          ...state,
          menuNodeMap: null,
          menuRootKey: null,
        };
      } else if (action.treeType === 'quick-access') {
        return {
          ...state,
          quickAccessRootKey: null,
          quickAccessNodeMap: null,
        };
      } else if (action.treeType === 'project-tree') {
        return {
          ...state,
          projectNodeMap: null,
          projectRootKey: null,
          projectToSelectKey: null,
        };
      } else {
        return state;
      }
    case actionTypes.CHANGE_TREE_VIEW:
      localStorage.setItem('view-type', action.viewType);
      return {
        ...state,
        viewType: action.viewType,
      };
    case actionTypes.TOGGLE_MENU:
      localStorage.setItem('MENU_COLLAPSED', JSON.stringify(!state.menuCollapsed));
      return {
        ...state,
        menuCollapsed: action.collapsed !== undefined ? action.collapsed : !state.menuCollapsed,
      };
    case actionTypes.GET_NODE_PATH_SUCCEEDED: {
      return {
        ...state,
        nodePath: action.data.data,
      };
    }
    case actionTypes.CLICK_MENU_NODE:
      return {
        ...state,
        selectedMenuNode: action.node,
        toEditKey: action.node
          ? action.toEdit
            ? action.node._key
            : state.toEditKey === action.node._key
            ? state.toEditKey
            : null
          : null,
        focusedTree: 'menu',
      };

    case actionTypes.GET_REPOSITORY_TREE: {
      return {
        ...state,
        selectedNode: null,
        toSelectKey: null,
        projectToSelectKey: null,
        toEditKey: null,
        sharePanelVisible: false,
        loading: true,
      };
    }
    case actionTypes.GET_REPOSITORY_TREE_SUCCESS: {
      if (!action.isMenuTree) {
        return {
          ...state,
          nodeMap: action.data.data,
          rootKey: action.data.root,
          loading: false,
          isPublic: action.data.isPublic,
          transparentLoading: false,
          toSelectNode: null,
        };
      } else {
        return {
          ...state,
          projectNodeMap: action.data.data,
          projectRootKey: action.data.root,
          loading: false,
          isPublic: action.data.isPublic,
          transparentLoading: false,
          toSelectNode: null,
        };
      }
    }
    case actionTypes.GET_FOLDER_TREE_SUCCESS: {
      const treeType = action.treeType || 'menu';
      if (treeType === 'menu') {
        return {
          ...state,
          menuNodeMap: action.data.data,
          menuRootKey: action.data.root,
        };
      } else if (treeType === 'quick-access') {
        return {
          ...state,
          quickAccessNodeMap: action.data.data,
          quickAccessRootKey: action.data.root,
        };
      } else {
        return state;
      }
    }
    case actionTypes.GET_OTHER_TREE_SUCCESS: {
      let nodes = state.nodeMap ? { ...state.nodeMap } : null;
      let projectMenuNodes = state.projectNodeMap ? { ...state.projectNodeMap } : null;
      // 將已有的數據與返回的新數據進行合併
      if (nodes) {
        nodes = { ...nodes, ...action.data.data };
      }
      if (projectMenuNodes) {
        projectMenuNodes = { ...projectMenuNodes, ...action.data.data };
      }
      return {
        ...state,
        nodeMap: nodes || state.nodeMap,
        projectNodeMap: projectMenuNodes || state.projectNodeMap,
      };
    }
    case actionTypes.IMPORT_FILES: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.EXPAND_BOOK_SUCCEEDED:
    case actionTypes.IMPORT_SUCCEEDED:
    case actionTypes.IMPORT_FILES_SUCCEEDED: {
      let nodes: NodeMap = { ...state.nodeMap };
      let node = nodes[action.nodeKey];
      // 將已有的數據與返回的新數據進行合併
      nodes = { ...nodes, ...action.data.data };
      node.contract = false;
      return {
        ...state,
        nodeMap: nodes,
        loading: false,
      };
    }

    case actionTypes.COLLAPSE_BOOK: {
      let nodes: NodeMap = { ...state.nodeMap };
      let node = nodes[action.nodeKey];
      // 收起，並刪除已折疊的節點
      for (let index = 0; index < node.sortList.length; index++) {
        const childKey = node.sortList[index];
        if (nodes[childKey]) {
          deleteNodeById(nodes[childKey], nodes);
        }
      }
      node.contract = true;
      return {
        ...state,
        nodeMap: nodes,
      };
    }

    case actionTypes.CLICK_NODE: {
      return {
        ...state,
        selectedNode: action.node,
        toEditKey: null,
        focusedTree: 'repo',
      };
    }
    case actionTypes.CLICK_NODE_BY_ID: {
      return {
        ...state,
        selectedNode:
          state.nodeMap && state.nodeMap[action.nodeKey] ? state.nodeMap[action.nodeKey] : state.selectedNode,
        toEditKey: null,
        focusedTree: 'repo',
      };
    }
    case actionTypes.RENAME_NODE_SUCCEEDED:
    case articleActionTypes.EDIT_DRAW_SUCCEEDED: {
      // 发送消息
      if (!action.noMessage) {
        if (window.parent && window.parent !== window.self) {
          window.parent.postMessage(
            { eventName: 'refresh-finder', data: { nodeKey: action.nodeKey, name: action.name } },
            '*',
          );
        }
        if (window.opener) {
          window.opener.postMessage(
            { eventName: 'refresh-finder', data: { nodeKey: action.nodeKey, name: action.name } },
            '*',
          );
        }
        const webview: any = document.getElementById('web-view');
        if (webview && webview.contentWindow) {
          webview.contentWindow.postMessage(
            { eventName: 'refresh-finder', data: { nodeKey: action.nodeKey, name: action.name } },
            '*',
          );
        }
      }

      let nodes: NodeMap | null = state.nodeMap ? JSON.parse(JSON.stringify(state.nodeMap)) : null;
      let menuNodes: NodeMap | null = state.menuNodeMap ? JSON.parse(JSON.stringify(state.menuNodeMap)) : null;
      let projectNodes: NodeMap | null = state.projectNodeMap ? JSON.parse(JSON.stringify(state.projectNodeMap)) : null;
      if (nodes) {
        let node = nodes[action.nodeKey];
        if (node && action.name) {
          node.name = action.name;
        }
      }
      if (menuNodes) {
        let menuNode = menuNodes[action.nodeKey];
        if (menuNode && action.name) {
          menuNode.name = action.name;
        }
      }
      if (projectNodes) {
        let node = projectNodes[action.nodeKey];
        if (node && action.name) {
          node.name = action.name;
        }
      }

      const projectList = [...state.projectList];
      const index = projectList.findIndex((project) => project._key === action.nodeKey);
      if (index !== -1) {
        projectList[index].name = action.name;
      }
      return {
        ...state,
        nodeMap: nodes,
        // toSelectKey: null,
        menuNodeMap: menuNodes,
        projectNodeMap: projectNodes,
        // toEditKey: null,
        projectList,
      };
    }
    case articleActionTypes.EDIT_ARTICLE_SUCCEEDED: {
      const newUrl = action.data.url || action.url;
      const newNode = action.data && action.data.data ? action.data.data : undefined;

      const getState = (
        nodeMap: NodeMap,
        nodeKey: string,
        newName: string,
        newUrl: string,
        newStar: number,
        newCustomTime: number,
        newFileType: string,
        newNode: Node,
      ) => {
        let nodes: NodeMap = { ...nodeMap };
        let node = nodes[nodeKey];
        if (node) {
          node = {
            ...node,
            ...{
              name: newName,
              url: newUrl,
              star: newStar,
              customTime: newCustomTime,
              fileType: newFileType,
            },
          };
          if (newNode) {
            node = { ...node, ...newNode };
          }
          nodes[nodeKey] = node;
          return {
            nodeMap: nodes,
            selectedNode: node,
          };
        } else {
          return null;
        }
      };

      if (!action.noRefresh) {
        if (state.nodeMap === null || window.location.pathname.includes('/bangumi')) {
          if (window.parent) {
            window.parent.postMessage(
              { eventName: 'refresh-finder', data: { nodeKey: action.nodeKey, name: action.name } },
              '*',
            );
          }
          if (window.opener) {
            window.opener.postMessage(
              { eventName: 'refresh-finder', data: { nodeKey: action.nodeKey, name: action.name } },
              '*',
            );
          }
        }
      }
      const oldNode = state.nodeMap ? state.nodeMap[action.nodeKey] : null;
      const oldProjectNode = state.projectNodeMap ? state.projectNodeMap[action.nodeKey] : null;
      // 如果name或者url或者star或者customTime或者fileType发生改变，更新当前节点
      if (
        (state.nodeMap || state.projectNodeMap) &&
        ((oldNode && action.name !== oldNode.name) ||
          (oldProjectNode && action.name !== oldProjectNode.name) ||
          newUrl ||
          action.star ||
          action.customTime ||
          action.fileType)
      ) {
        const newState = state.nodeMap
          ? getState(
              state.nodeMap,
              action.nodeKey,
              action.name,
              newUrl,
              action.star,
              action.customTime,
              action.fileType,
              newNode,
            )
          : null;
        const newProjectState = state.projectNodeMap
          ? getState(
              state.projectNodeMap,
              action.nodeKey,
              action.name,
              newUrl,
              action.star,
              action.customTime,
              action.fileType,
              newNode,
            )
          : null;
        return {
          ...state,
          nodeMap: newState ? newState.nodeMap : state.nodeMap,
          projectNodeMap: newProjectState ? newProjectState.nodeMap : state.projectNodeMap,
          selectedNode: newProjectState?.selectedNode || newState?.selectedNode || state.selectedNode,
        };
      } else {
        return state;
      }
    }
    case articleActionTypes.FAV_ARTICLE_SUCCEEDED: {
      const getState = (nodes: NodeMap) => {
        const nodeMap = { ...nodes };
        let node = nodeMap[action.nodeKey];
        if (node) {
          node.hasCollect = !node.hasCollect;
        }
        return nodeMap;
      };
      const nodeMap = state.nodeMap ? getState(state.nodeMap) : null;
      const projectNodeMap = state.projectNodeMap ? getState(state.projectNodeMap) : null;
      return {
        ...state,
        nodeMap,
        projectNodeMap,
      };
    }
    case actionTypes.CHANGE_SORT: {
      return {
        ...state,
        transparentLoading: true,
      };
    }
    case actionTypes.CHANGE_SORT_SUCCEEDED: {
      let nodes = state.nodeMap ? { ...state.nodeMap } : null;
      if (nodes) {
        let node = nodes[action.nodeKey];
        if (node) {
          node.sortList = action.sortList;
        }
      }

      let projectMenuNodes = state.projectNodeMap ? { ...state.projectNodeMap } : null;
      if (projectMenuNodes) {
        let projectNode = projectMenuNodes[action.nodeKey];
        if (projectNode) {
          projectNode.sortList = action.sortList;
        }
      }

      return {
        ...state,
        nodeMap: nodes,
        projectNodeMap: projectMenuNodes,
        transparentLoading: false,
        toSelectKey: action.toSelectKey || state.toSelectKey,
      };
    }
    case actionTypes.CREATE_NODE: {
      return {
        ...state,
        transparentLoading: true,
      };
    }
    case actionTypes.CREATE_NODE_SUCCEEDED: {
      const addedNode = { ...action.data.data, ...action.nodeInfoObj };
      // 创建完成后选中toSelectKey
      const toSelectKey = action.addType === 'elder-peer' ? action.nodeKey : addedNode._key;
      // toEditKey 决定右侧预览是否自动变为编辑模式
      const toEditKey = addedNode.type === 'doc' || addedNode.type === 'flexNode' ? addedNode._key : state.toEditKey;

      let nodes: NodeMap | null = state.nodeMap ? JSON.parse(JSON.stringify(state.nodeMap)) : null;
      let menuNodes: NodeMap | null = state.menuNodeMap ? JSON.parse(JSON.stringify(state.menuNodeMap)) : null;
      let projectMenuNodes = state.projectNodeMap ? { ...state.projectNodeMap } : null;

      if (nodes && state.rootKey && nodes[state.rootKey]) {
        addNode(nodes, action);
      }
      if (menuNodes && state.menuRootKey && menuNodes[state.menuRootKey] && addedNode.type === 'folder') {
        addNode(menuNodes, action);
      }
      if (projectMenuNodes && state.projectRootKey && projectMenuNodes[state.projectRootKey]) {
        addNode(projectMenuNodes, action);
      }
      return {
        ...state,
        nodeMap: nodes,
        toSelectKey: !action.inTiptap ? toSelectKey : null,
        toEditKey: !action.inTiptap ? toEditKey : null,
        menuNodeMap: menuNodes,
        projectNodeMap: projectMenuNodes,
        transparentLoading: false,
        projectToSelectKey: action.isProjectTree ? toSelectKey : state.projectToSelectKey,
      };
    }
    case articleActionTypes.DELETE_ARTICLE: {
      return {
        ...state,
        transparentLoading: true,
      };
    }
    case articleActionTypes.DELETE_ARTICLE_SUCCEEDED: {
      let nextSelectNodeKey = null;
      if (action.isTree) {
        const selectedId: string = action.nodeKey;
        let nodes: NodeMap | null = state.nodeMap ? JSON.parse(JSON.stringify(state.nodeMap)) : null;
        let menuNodes: NodeMap | null = state.menuNodeMap ? JSON.parse(JSON.stringify(state.menuNodeMap)) : null;
        let projectMenuNodes = state.projectNodeMap ? { ...state.projectNodeMap } : null;

        if (nodes) {
          let selectedNode = nodes[selectedId];
          if (selectedNode) {
            let fatherNode = nodes[selectedNode.father];
            if (fatherNode) {
              let brotherKeys = fatherNode.sortList;
              const index = brotherKeys.indexOf(selectedId);
              brotherKeys.splice(index, 1);
              fatherNode.sortList = brotherKeys;
              if (brotherKeys.length && index > 0) {
                nextSelectNodeKey = brotherKeys[index - 1];
              } else {
                nextSelectNodeKey = fatherNode._key;
              }
              deleteNodeById(selectedNode, nodes);
            }
          }
        }

        if (projectMenuNodes) {
          let selectedNode = projectMenuNodes[selectedId];
          if (selectedNode) {
            let fatherNode = projectMenuNodes[selectedNode.father];
            if (fatherNode) {
              let brotherKeys = fatherNode.sortList;
              const index = brotherKeys.indexOf(selectedId);
              brotherKeys.splice(index, 1);
              fatherNode.sortList = brotherKeys;
              if (brotherKeys.length && index > 0) {
                nextSelectNodeKey = brotherKeys[index - 1];
              } else {
                nextSelectNodeKey = fatherNode._key;
              }
              deleteNodeById(selectedNode, projectMenuNodes);
            }
          }
        }

        if (menuNodes) {
          let selectedNode = menuNodes[selectedId];
          if (selectedNode) {
            let fatherNode = menuNodes[selectedNode.father];
            if (fatherNode) {
              let brotherKeys = fatherNode.sortList;
              brotherKeys.splice(brotherKeys.indexOf(selectedId), 1);
              fatherNode.sortList = brotherKeys;
              deleteNodeById(selectedNode, menuNodes);
            }
          }
        }

        return {
          ...state,
          nodeMap: nodes,
          selectedNode: null,
          toSelectKey: nextSelectNodeKey,
          menuNodeMap: menuNodes,
          projectNodeMap: projectMenuNodes,
          transparentLoading: false,
          projectToSelectKey: action.isProjectTree ? nextSelectNodeKey : state.projectToSelectKey,
        };
      } else {
        return {
          ...state,
          transparentLoading: false,
        };
      }
    }
    case articleActionTypes.DELETE_BATCH_ARTICLE: {
      return {
        ...state,
        transparentLoading: true,
      };
    }
    case articleActionTypes.DELETE_BATCH_ARTICLE_SUCCEEDED: {
      const nodeKeyArr: string[] = action.nodeKeyArr;
      let nodes: NodeMap | null = state.nodeMap ? JSON.parse(JSON.stringify(state.nodeMap)) : null;
      let menuNodes: NodeMap | null = state.menuNodeMap ? JSON.parse(JSON.stringify(state.menuNodeMap)) : null;
      let projectMenuNodes = state.projectNodeMap ? { ...state.projectNodeMap } : null;

      for (let index = 0; index < nodeKeyArr.length; index++) {
        const selectedId = nodeKeyArr[index];
        if (nodes) {
          let selectedNode = nodes[selectedId];
          if (selectedNode) {
            let fatherNode = nodes[selectedNode.father];
            if (fatherNode) {
              let brotherKeys = fatherNode.sortList;
              brotherKeys.splice(brotherKeys.indexOf(selectedId), 1);
              fatherNode.sortList = brotherKeys;
              deleteNodeById(selectedNode, nodes);
            }
          }
        }

        if (projectMenuNodes) {
          let selectedNode = projectMenuNodes[selectedId];
          if (selectedNode) {
            let fatherNode = projectMenuNodes[selectedNode.father];
            if (fatherNode) {
              let brotherKeys = fatherNode.sortList;
              brotherKeys.splice(brotherKeys.indexOf(selectedId), 1);
              fatherNode.sortList = brotherKeys;
              deleteNodeById(selectedNode, projectMenuNodes);
            }
          }
        }

        if (menuNodes) {
          let selectedNode = menuNodes[selectedId];
          if (selectedNode) {
            let fatherNode = menuNodes[selectedNode.father];
            if (fatherNode) {
              let brotherKeys = fatherNode.sortList;
              brotherKeys.splice(brotherKeys.indexOf(selectedId), 1);
              fatherNode.sortList = brotherKeys;
              deleteNodeById(selectedNode, menuNodes);
            }
          }
        }
      }

      return {
        ...state,
        nodeMap: nodes,
        selectedNode: null,
        toSelectKey: null,
        menuNodeMap: menuNodes,
        projectNodeMap: projectMenuNodes,
        transparentLoading: false,
      };
    }
    case actionTypes.EXPAND_NODE_SUCCEEDED: {
      let nodes: NodeMap | null = state.nodeMap ? JSON.parse(JSON.stringify(state.nodeMap)) : null;
      let menuNodes: NodeMap | null = state.menuNodeMap ? JSON.parse(JSON.stringify(state.menuNodeMap)) : null;
      let projectMenuNodes = state.projectNodeMap ? { ...state.projectNodeMap } : null;

      if (nodes) {
        let node = nodes[action.nodeKey];
        if (node) {
          // 展開
          if (node.contract) {
            // 將已有的數據與展開後返回的新數據進行合併
            nodes = { ...nodes, ...action.data.data };
            node.contract = false;
          } else {
            // 收起，並刪除已折疊的節點
            for (let index = 0; index < node.sortList.length; index++) {
              const childKey = node.sortList[index];
              if (nodes[childKey]) {
                deleteNodeById(nodes[childKey], nodes);
              }
            }
            node.contract = true;
            node.childNum = action.data.data;
          }
        }
      }

      if (projectMenuNodes) {
        let node = projectMenuNodes[action.nodeKey];
        if (node) {
          // 展開
          if (node.contract) {
            // 將已有的數據與展開後返回的新數據進行合併
            projectMenuNodes = { ...projectMenuNodes, ...action.data.data };
            node.contract = false;
          } else {
            // 收起，並刪除已折疊的節點
            for (let index = 0; index < node.sortList.length; index++) {
              const childKey = node.sortList[index];
              if (projectMenuNodes[childKey]) {
                deleteNodeById(projectMenuNodes[childKey], projectMenuNodes);
              }
            }
            node.contract = true;
            node.childNum = action.data.data;
          }
        }
      }

      if (menuNodes) {
        let node = menuNodes[action.nodeKey];
        if (node) {
          // 展開
          if (node.contract) {
            node.contract = false;
          } else {
            node.contract = true;
          }
        }
      }
      return {
        ...state,
        nodeMap: nodes,
        menuNodeMap: menuNodes,
        projectNodeMap: projectMenuNodes,
      };
    }
    case actionTypes.ARCHIVE_SUCCEEDED: {
      const newNode = action.data.data;
      let nodes: NodeMap | null = state.nodeMap ? JSON.parse(JSON.stringify(state.nodeMap)) : null;
      let selectedNode = state.selectedNode ? { ...state.selectedNode } : null;
      let projectMenuNodes = state.projectNodeMap ? { ...state.projectNodeMap } : null;

      if (nodes) {
        let node = nodes[action.nodeKey];
        if (node.isPack) {
        } else {
          // 歸檔，並刪除已折疊的節點
          for (let index = 0; index < node.sortList.length; index++) {
            const childKey = node.sortList[index];
            if (nodes[childKey]) {
              deleteNodeById(nodes[childKey], nodes);
            }
          }
        }
        node = { ...node, ...newNode };
        selectedNode = { ...selectedNode, ...newNode };
        nodes[node._key] = node;
      }

      if (projectMenuNodes) {
        let node = projectMenuNodes[action.nodeKey];
        if (node.isPack) {
        } else {
          // 歸檔，並刪除已折疊的節點
          for (let index = 0; index < node.sortList.length; index++) {
            const childKey = node.sortList[index];
            if (projectMenuNodes[childKey]) {
              deleteNodeById(projectMenuNodes[childKey], projectMenuNodes);
            }
          }
        }
        node = { ...node, ...newNode };
        projectMenuNodes[node._key] = node;
      }

      return {
        ...state,
        nodeMap: nodes,
        selectedNode,
        projectNodeMap: projectMenuNodes,
      };
    }
    case actionTypes.FAV_SUCCEEDED: {
      if (state.nodeMap) {
        let nodes = state.nodeMap ? { ...state.nodeMap } : null;
        let menuNodes = state.menuNodeMap ? { ...state.menuNodeMap } : null;
        let projectMenuNodes = state.projectNodeMap ? { ...state.projectNodeMap } : null;

        if (nodes) {
          let node = nodes[action.nodeKey];
          if (node) {
            node.hasCollect = !node.hasCollect;
          }
        }

        if (projectMenuNodes) {
          let node = projectMenuNodes[action.nodeKey];
          if (node) {
            node.hasCollect = !node.hasCollect;
          }
        }

        if (menuNodes) {
          const newNode = action.data.data;
          if (menuNodes[action.nodeKey] && newNode) {
            menuNodes[action.nodeKey] = newNode;
          }
        }

        return {
          ...state,
          nodeMap: nodes,
          selectedNode: nodes ? nodes[action.nodeKey] : null,
          menuNodeMap: menuNodes,
          projectNodeMap: projectMenuNodes,
        };
      } else {
        return state;
      }
    }
    case actionTypes.PASTE: {
      return {
        ...state,
        transparentLoading: true,
      };
    }
    case actionTypes.PASTE_SUCCEEDED: {
      let crossTree = false;
      const getState = (nodes: NodeMap) => {
        const nodeMap = { ...nodes };
        let pasteNode = nodeMap[action.pasteNodeKey];
        let targetNode = nodeMap[action.selectedId];
        if (!pasteNode || !targetNode) {
          crossTree = true;
          return nodeMap;
        }
        if (action.pasteType === 'cut') {
          if (action.pasteNodeKey === action.selectedId) {
            return null;
          }
          // 從選中節點的父節點的children中刪除當前id
          let selectedNodeFather = nodeMap[pasteNode.father];
          if (!selectedNodeFather) {
            return null;
          }
          let sortList = selectedNodeFather.sortList;
          sortList.splice(sortList.indexOf(pasteNode._key), 1);
          pasteNode.father = action.selectedId;
          // 將當前id添加到目標節點children中
          targetNode.sortList.push(pasteNode._key);
          return nodeMap;
        } else {
          // 將已有的數據與新數據進行合併
          const nodeMap = { ...nodes, ...action.data.data };
          const newNodeKey = action.data.root;
          const newNode = nodeMap[newNodeKey];
          if (newNode) {
            // 將newNode的id添加到目標節點children中
            targetNode.sortList.push(newNode._key);
          }
          return nodeMap;
        }
      };
      const nodeMap = state.nodeMap ? getState(state.nodeMap) : null;
      const projectNodeMap = state.projectNodeMap ? getState(state.projectNodeMap) : null;
      return {
        ...state,
        nodeMap,
        projectNodeMap,
        transparentLoading: false,
        pasteNode: crossTree ? action.data.data : state.pasteNode,
      };
    }
    case actionTypes.DRAG: {
      return {
        ...state,
        transparentLoading: true,
      };
    }
    case actionTypes.DRAG_SUCCEEDED:
    case actionTypes.CONVERT_TO_CHILD_SUCCEEDED: {
      const getState = (nodes: NodeMap, undateChildNum?: boolean) => {
        const nodeMap = { ...nodes };
        let selectedNode = nodeMap[action.nodeKey];
        let targetNode = nodeMap[action.targetNodeKey];
        if (selectedNode) {
          switch (action.placement) {
            case 'in':
              // 從選中節點的父節點的children中刪除當前id
              let selectedNodeFather = nodeMap[selectedNode.father];
              if (!selectedNodeFather) {
                break;
              }
              let sortList = selectedNodeFather.sortList;
              sortList.splice(sortList.indexOf(selectedNode._key), 1);
              selectedNode.father = action.targetNodeKey;
              // 將當前id添加到目標節點children中
              if (targetNode) {
                targetNode.sortList.push(selectedNode._key);
              }
              break;
            case 'up':
            case 'down':
              addSibling(action.nodeKey, action.targetNodeKey, action.placement, nodeMap);
              break;
            default:
              break;
          }
          return nodeMap;
        } else if (targetNode && undateChildNum) {
          // 更新拖拽剪藏后目录上显示的节点数
          let selectedNodeFather = state.selectedNode ? nodeMap[state.selectedNode._key] : null;
          let targetNode = nodeMap[action.targetNodeKey];
          if (action.placement === 'in') {
            if (selectedNodeFather) {
              selectedNodeFather.childNum = (selectedNodeFather.childNum || 1) - 1;
            }
            if (targetNode) {
              targetNode.childNum = (targetNode.childNum || 0) + 1;
            }
          }
          return nodeMap;
        } else {
          return nodeMap;
        }
      };

      const nodeMap = state.nodeMap ? getState(state.nodeMap) : null;
      const menuNodeMap = state.menuNodeMap ? getState(state.menuNodeMap, true) : null;
      const projectNodeMap = state.projectNodeMap ? getState(state.projectNodeMap) : null;

      return {
        ...state,
        nodeMap,
        menuNodeMap,
        projectNodeMap,
        selectedNode: state.nodeMap ? state.nodeMap[action.nodeKey] : null,
        transparentLoading: false,
      };
    }
    case actionTypes.DRAG_BATCH: {
      return {
        ...state,
        transparentLoading: true,
      };
    }
    case actionTypes.DRAG_BATCH_SUCCEEDED: {
      let nodeMap: NodeMap = { ...state.nodeMap };
      let targetNode = nodeMap[action.targetNodeKey];

      for (let index = 0; index < action.dragNodeArr.length; index++) {
        const nodeKey = action.dragNodeArr[index].nodeKey;
        let selectedNode = nodeMap[nodeKey];
        if (!selectedNode || !targetNode) {
          break;
        }
        switch (action.placement) {
          case 'in':
            // 從選中節點的父節點的children中刪除當前id
            let selectedNodeFather = nodeMap[selectedNode.father];
            if (!selectedNodeFather) {
              break;
            }
            let sortList = selectedNodeFather.sortList;
            sortList.splice(sortList.indexOf(selectedNode._key), 1);
            selectedNode.father = action.targetNodeKey;
            // 將當前id添加到目標節點children中
            targetNode.sortList.push(selectedNode._key);
            break;
          case 'up':
          case 'down':
            addSibling(action.nodeKey, action.targetNodeKey, action.placement, nodeMap);
            break;
          default:
            break;
        }
      }

      return {
        ...state,
        nodeMap,
        transparentLoading: false,
      };
    }
    case actionTypes.TOGGLE_SHARE_PANEL: {
      return {
        ...state,
        sharePanelVisible: action.visible === undefined ? !state.sharePanelVisible : action.visible,
      };
    }
    case actionTypes.TOGGLE_PREVIEW_PANEL: {
      return {
        ...state,
        previewVisible: action.visible === undefined ? !state.previewVisible : action.visible,
      };
    }
    case actionTypes.TOGGLE_DOC_PANEL: {
      return {
        ...state,
        docPanelVisible: action.visible,
        docPanelType: action.docPanelType === undefined ? state.docPanelType : action.docPanelType,
      };
    }
    case actionTypes.TOGGLE_NTFY_PANEL:
      return {
        ...state,
        notificationVisible: action.visible === undefined ? !state.notificationVisible : action.visible,
      };
    case actionTypes.TOGGLE_HISTORY_PANEL:
      let logList = state.logList;
      if (action.visible === undefined && state.historyVisible) {
        logList = [];
      } else if (!action.visible) {
        logList = [];
      }
      return {
        ...state,
        historyVisible: action.visible === undefined ? !state.historyVisible : action.visible,
        logList,
      };
    case actionTypes.CONVERT_NODE: {
      return { ...state, toSelectKey: null, projectToSelectKey: null };
    }
    case actionTypes.CONVERT_NODE_SUCCEEDED: {
      let nodeMap = state.nodeMap ? { ...state.nodeMap } : null;
      let projectNodeMap = state.projectNodeMap ? { ...state.projectNodeMap } : null;

      const newNode = { ...action.data.data, ...action.nodeInfoObj };
      if (action.name) {
        newNode.name = action.name;
      }
      if (nodeMap) {
        nodeMap[action.nodeKey] = newNode;
      }
      if (projectNodeMap) {
        projectNodeMap[action.nodeKey] = newNode;
      }

      // toEditKey 决定右侧预览是否自动变为编辑模式
      const toEditKey = newNode.type === 'doc' || newNode.type === 'flexNode' ? newNode._key : state.toEditKey;
      return {
        ...state,
        nodeMap,
        selectedNode: newNode,
        selectedMenuNode: state.selectedMenuNode ? newNode : state.selectedMenuNode,
        projectNodeMap,
        toEditKey,
        // toSelectKey: action.name ? action.nodeKey : state.toSelectKey,
        toSelectNode: newNode,
      };
    }
    case actionTypes.CHANGE_ICON_SUCCEEDED:
    case actionTypes.REVERT_ICON_SUCCEEDED: {
      let nodeMap: NodeMap | null = state.nodeMap ? JSON.parse(JSON.stringify(state.nodeMap)) : null;
      let menuNodeMap: NodeMap | null = state.menuNodeMap ? JSON.parse(JSON.stringify(state.menuNodeMap)) : null;
      let projectNodeMap = state.projectNodeMap ? { ...state.projectNodeMap } : null;

      const projectList = [...state.projectList];
      const index = projectList.findIndex((project) => project._key === action.nodeKey);
      if (index !== -1) {
        projectList[index].icon = action.icon;
      }

      if (nodeMap) {
        let node = nodeMap[action.nodeKey];
        if (node) {
          node.icon = action.icon;
        }
      }

      if (projectNodeMap) {
        let node = projectNodeMap[action.nodeKey];
        if (node) {
          node.icon = action.icon;
        }
      }

      if (menuNodeMap) {
        let node = menuNodeMap[action.nodeKey];
        if (node) {
          node.icon = action.icon;
        }
      }
      return { ...state, nodeMap, projectNodeMap, menuNodeMap, projectList };
    }
    case actionTypes.GET_LOG_LIST_SUCCEEDED: {
      return {
        ...state,
        logList: action.pagination ? [...state.logList, ...action.data.data] : action.data.data,
        totalLog: action.data.total,
      };
    }
    case actionTypes.CLEAR_LOGS: {
      return {
        ...state,
        logList: [],
        totalLog: 0,
      };
    }
    case actionTypes.CLEAR_TOEDIT: {
      return {
        ...state,
        toEditKey: null,
        toSelectKey: null,
        projectToSelectKey: null,
      };
    }
    case actionTypes.UPDATE_TASK_NODE_SUCCEEDED: {
      if (state.nodeMap) {
        let nodes: NodeMap = { ...state.nodeMap };
        const results = action.data.data;
        for (let index = 0; index < results.length; index++) {
          const element = results[index];
          const newNode = { ...nodes[element._key], ...element };
          nodes[element._key] = newNode;
        }

        return {
          ...state,
          nodeMap: nodes,
          selectedNode:
            state.selectedNode && results.length === 1 ? { ...state.selectedNode, ...results[0] } : state.selectedNode,
        };
      } else {
        return state;
      }
    }
    case actionTypes.GET_PROJECT_LIST_SUCCEEDED: {
      return {
        ...state,
        projectList: action.data.data,
      };
    }
    case actionTypes.ADD_PROJECT_SUCCEEDED: {
      localStorage.setItem('last-repo-root-id', action.data.data._key);
      window.location.href = `${window.location.protocol}//${window.location.host}/home/workspace/${action.data.data._key}`;
      return {
        ...state,
        projectList: [...state.projectList, action.data.data],
      };
    }
    case actionTypes.DELETE_PROJECT_SUCCEEDED: {
      let projectList = [...state.projectList];
      let nodeIndex = projectList.findIndex((node: any) => node._key === action.nodeKey);
      projectList.splice(nodeIndex, 1);
      window.location.href = `${window.location.protocol}//${window.location.host}/home/workspace/${projectList[0]._key}`;
      return {
        ...state,
        projectList,
      };
    }
    case actionTypes.EXPORT_TREE_SUCCEEDED: {
      fetch(action.data.data.url).then((res) =>
        res.blob().then((blob) => {
          let a = document.createElement('a');
          let url = window.URL.createObjectURL(blob);
          let filename = action.data.data.name || '导出';
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        }),
      );
      return {
        ...state,
      };
    }
    case actionTypes.GET_COMMENTS_SUCCEEDED: {
      return {
        ...state,
        commentList: action.page === 1 ? action.data.data : [...state.commentList, ...action.data.data],
        commentCount: action.data.total,
      };
    }
    case actionTypes.DELETE_COMMENT_SUCCEEDED: {
      const commentList = [...state.commentList];
      let index = commentList.findIndex((comment: any) => comment._key === action.commentId);
      commentList.splice(index, 1);
      return {
        ...state,
        commentList,
      };
    }
    case actionTypes.POST_COMMENT_SUCCEEDED: {
      return {
        ...state,
        commentList: [...state.commentList, action.data.data],
      };
    }
    case actionTypes.TOGGLE_BOOK_EDITABLE: {
      return {
        ...state,
        editable: action.editable,
      };
    }
    case articleActionTypes.PUBLIC_SHARE_SUCCEEDED: {
      if (action.isBook) {
        return { ...state, isPublic: !state.isPublic };
      } else {
        return state;
      }
    }
    case actionTypes.MUTI_SELECT: {
      return {
        ...state,
        selectedNodes: action.selectedNodes,
      };
    }
    case actionTypes.MOVE_TASK_TREE_SUCCEEDED: {
      const selectedId: string = action.nodeKey;
      let nodes: NodeMap | null = state.nodeMap ? JSON.parse(JSON.stringify(state.nodeMap)) : null;
      if (nodes) {
        let selectedNode = nodes[selectedId];
        if (selectedNode) {
          let fatherNode = nodes[selectedNode.father];
          if (fatherNode) {
            let brotherKeys = fatherNode.sortList;
            brotherKeys.splice(brotherKeys.indexOf(selectedId), 1);
            fatherNode.sortList = brotherKeys;
            deleteNodeById(selectedNode, nodes);
          }
        }
      }
      return {
        ...state,
        nodeMap: nodes,
        selectedNode: null,
        toSelectKey: null,
      };
    }
    case actionTypes.SWITCH_FONT_TYPE: {
      const fontType = action.fontType || (state.fontType === 'normal' ? 'bold' : 'normal');
      localStorage.setItem('font-type', fontType);
      return {
        ...state,
        fontType,
      };
    }
    case actionTypes.GET_ROOT_KEY_HISTORY_SUCCEEDED: {
      // const nodes = action.data.data;
      // let keys = [];
      // for (let index = 0; index < nodes.length; index++) {
      //   const node = nodes[index];
      //   keys.push(node._key);
      // }
      return { ...state, rootKeyHistory: action.data.data };
    }
    case actionTypes.UPDATE_NODE_ATTACH_SUCCEEDED: {
      if (state.nodeMap) {
        const nodes: NodeMap = { ...state.nodeMap };
        let selectedNode;

        if (nodes[action.nodeKey]) {
          nodes[action.nodeKey].attach = action.attach;
          selectedNode = nodes[action.nodeKey];
        }

        return {
          ...state,
          nodeMap: nodes,
          selectedNode,
        };
      } else {
        return state;
      }
    }
    case actionTypes.GET_CARD_LIST_SUCCEEDED: {
      return {
        ...state,
        cardList: action.data.data,
      };
    }
    case actionTypes.GET_CARD_DETAIL_SUCCEEDED: {
      return {
        ...state,
        cardDetail: action.data.data,
      };
    }
    case actionTypes.CLEAR_CARD_DETAIL: {
      return {
        ...state,
        cardDetail: [],
      };
    }
    case actionTypes.ADD_NOTE_SUCCEEDED: {
      const nodes = { ...state.nodeMap };
      const node = nodes[action.nodeKey];
      if (node) {
        node.note = '';
      }
      return {
        ...state,
        nodeMap: nodes,
        defaultNoteFocusedNodeId: action.nodeKey,
      };
    }
    case actionTypes.UPDATE_NOTE_SUCCEEDED: {
      const nodes = { ...state.nodeMap };
      const node = nodes[action.nodeKey];
      if (node) {
        if (action.note === null) {
          delete node.note;
        } else {
          node.note = action.note;
        }
      }
      return {
        ...state,
        nodeMap: nodes,
        defaultNoteFocusedNodeId: '',
        toSelectKey: action.note === null ? action.nodeKey : state.toSelectKey,
      };
    }
    case commonActionTypes.FAILED: {
      return {
        ...state,
        loading: false,
        transparentLoading: false,
      };
    }
    case taskActionTypes.UPDATE_TASK_SUCCEEDED: {
      if (state.nodeMap) {
        const nodes = { ...state.nodeMap };
        const node = nodes[action.nodeKey];
        const task: Task = action.data.data;
        if (node) {
          node.checked = task.checked;
          node.avatarUri = task.executor?.userAvatar;
          node.executor = task.executor?.userKey;
          node.limitDay = task.limitDay || undefined;
          node.hour = task.hour || undefined;
        }
        return {
          ...state,
          nodeMap: nodes,
        };
      } else {
        return state;
      }
    }
    case actionTypes.APPEND_CHILDREN_TO_NODE: {
      const getState = (nodeMap: NodeMap, targetNodeKey: string, addedNodeMap: NodeMap) => {
        if (!nodeMap[targetNodeKey] || !addedNodeMap) {
          return nodeMap;
        }

        let map: NodeMap = { ...nodeMap, ...addedNodeMap };
        let targetNode = map[targetNodeKey];
        const addedRootKey = Object.keys(addedNodeMap)[0];
        targetNode.sortList.push(addedRootKey);
        return map;
      };
      const nodeMap = state.nodeMap ? getState(state.nodeMap, action.targetNodeKey, action.addedNodeMap) : null;
      const projectNodeMap = state.projectNodeMap
        ? getState(state.projectNodeMap, action.targetNodeKey, action.addedNodeMap)
        : null;
      const menuNodeMap = state.menuNodeMap
        ? getState(state.menuNodeMap, action.targetNodeKey, action.addedNodeMap)
        : null;

      const addedRootKey = action.addedNodeMap ? Object.keys(action.addedNodeMap)[0] : null;
      return {
        ...state,
        nodeMap,
        menuNodeMap,
        projectNodeMap,
        projectToSelectKey: addedRootKey || state.projectToSelectKey,
      };
    }
    default:
      return state;
  }
};
