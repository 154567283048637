import { actionTypes } from '../actions/articleActions';
import { actionTypes as commonActionTypes } from '../actions/commonActions';
import { actionTypes as treeActionTypes } from '../actions/treeActions';
import { actionTypes as taskActionTypes } from '../actions/taskActions';
import { ArticleType } from '../../interfaces/Article';
import Notification from '../../interfaces/Notification';
import { Task } from '../../interfaces/ProjectTask';

export interface ArticleReducer {
  articles: ArticleType[];
  articlesTotal: number;
  article: ArticleType | null;
  saveStatus: number; // 1已保存,0 保存中 -1 未保存
  // 是否顯示搜索結果面板
  showSearchResult: boolean;
  notifications: Notification[];
  notificationsTotal: number;
  reloadNotification: boolean;
  unreadNum: number;
  showPublicShareModal: boolean;
  isPublicShare: boolean | null;
  saveTime: number | null | undefined;
  quickAccessList: ArticleType[];
  navArticles: ArticleType[];
}

const defaultState: ArticleReducer = {
  articles: [],
  notifications: [],
  articlesTotal: 0,
  notificationsTotal: 0,
  article: null,
  saveStatus: 1,
  showSearchResult: false,
  unreadNum: 0,
  showPublicShareModal: false,
  isPublicShare: null,
  reloadNotification: false,
  saveTime: null,
  quickAccessList: [],
  navArticles: [],
};

export const article = (state = defaultState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_DOC_LIST_SUCCEEDED:
    case actionTypes.GET_SHARE_LIST_SUCCEEDED:
    case actionTypes.GET_SHARE_OUT_LIST_SUCCEEDED:
    case actionTypes.GET_FAV_LIST_SUCCEEDED:
    case actionTypes.GET_CLIPS_SUCCEEDED:
    case actionTypes.GET_RECENT_UPLOAD_SUCCEEDED:
    case actionTypes.GET_CHILD_NODES_SUCCESS: {
      return {
        ...state,
        articles: action.page === 1 || action.pagination ? action.data.data : [...state.articles, ...action.data.data],
        articlesTotal: action.data.total,
      };
    }
    case actionTypes.GET_NAV_FAV_LIST_SUCCEEDED: {
      return {
        ...state,
        navArticles:
          action.page === 1 || action.pagination ? action.data.data : [...state.articles, ...action.data.data],
      };
    }
    case actionTypes.GET_RECYCLE__SUCCEEDED: {
      return {
        ...state,
        articles: action.data.data,
        articlesTotal: action.data.total,
      };
    }
    case actionTypes.GET_NTFY_LIST_SUCCEEDED: {
      return {
        ...state,
        notifications: action.unRead
          ? []
          : action.pagination
          ? [...state.notifications, ...action.data.data]
          : action.data.data,
        notificationsTotal: action.unRead ? 0 : action.data.total,
        unreadNum: action.data.unRead,
        reloadNotification: false,
      };
    }
    case actionTypes.OPEN_SEARCH_PANEL: {
      return {
        ...state,
        showSearchResult: action.visible,
      };
    }
    case actionTypes.TOGGLE_PUBLIC_SHARE_MODAL: {
      return {
        ...state,
        showPublicShareModal: action.visible,
      };
    }
    case actionTypes.SEARCH_NODE_SUCCEEDED: {
      return {
        ...state,
        articles: action.data.data.sort((a: ArticleType, b: ArticleType) => {
          if (a.viewTime && b.viewTime) {
            return b.viewTime - a.viewTime;
          } else if (a.viewTime && !b.viewTime) {
            return -1;
          } else if (!a.viewTime && b.viewTime) {
            return 1;
          } else {
            return b.updateTime - a.updateTime;
          }
        }),
        articlesTotal: action.data.total,
      };
    }
    case actionTypes.SEARCH_SUCCEEDED: {
      return {
        ...state,
        articles: action.data.data,
      };
    }
    case actionTypes.CLEAR_ARTICLES:
    case commonActionTypes.SET_CONTENT_TYPE: {
      return {
        ...state,
        articles: [],
        shares: [],
        favs: [],
        articlesTotal: 0,
        sharesTotal: 0,
        favsTotal: 0,
      };
    }
    case actionTypes.DELETE_ARTICLE_SUCCEEDED: {
      if (action.isTree) {
        return {
          ...state,
        };
      } else {
        let articles = JSON.parse(JSON.stringify(state.articles));
        let nodeIndex = articles.findIndex((node: any) => node._key === action.nodeKey);
        if (nodeIndex === -1) {
          nodeIndex = articles.findIndex((node: any) => node.linkId === action.nodeKey);
        }
        if (nodeIndex !== -1) {
          articles.splice(nodeIndex, 1);
        }
        return {
          ...state,
          articles: articles,
          articlesTotal: state.articlesTotal - 1,
        };
      }
    }
    case actionTypes.RESTORE_SUCCEEDED: {
      let articles = JSON.parse(JSON.stringify(state.articles));
      let nodeIndex = articles.findIndex((node: any) => node._key === action.nodeKey);
      articles.splice(nodeIndex, 1);
      return {
        ...state,
        articles: articles,
        articlesTotal: state.articlesTotal - 1,
      };
    }
    case actionTypes.DELETE_RECYCLE_SUCCEEDED: {
      return {
        ...state,
        articles: [],
        articlesTotal: 0,
      };
    }
    case actionTypes.EDIT_ARTICLE_SAVESTATUS: {
      return {
        ...state,
        saveStatus: action.saveStatus,
      };
    }

    case actionTypes.ADD_ARTICLE_SUCCEEDED: {
      const articles = [...state.articles];
      if (action.inArticles) {
        articles.unshift(action.data.data);
      }

      if (window.parent) {
        window.parent.postMessage(
          { eventName: 'refresh-finder', data: { nodeKey: action.data.data._key, name: action.data.data.name } },
          '*',
        );
      }
      if (window.opener) {
        window.opener.postMessage(
          { eventName: 'refresh-finder', data: { nodeKey: action.data.data._key, name: action.data.data.name } },
          '*',
        );
      }

      return {
        ...state,
        article: { ...action.data.data, ...{ detail: action.detail } },
        saveStatus: action.saveStatus,
        saveTime: new Date().getTime(),
        articles,
      };
    }
    case actionTypes.GET_ARTICLE_BY_ID_SUCCEEDED:
    case actionTypes.PREVIEW_ARTICLE_SUCCEEDED:
      return {
        ...state,
        article: action.data.data,
        saveTime: null,
      };

    case actionTypes.EDIT_ARTICLE_SUCCEEDED:
      let articles = [...state.articles];
      let nodeIndex = articles.findIndex((node: any) => node._key === action.nodeKey);
      const nameChanged = action.name !== state.article?.name;

      if (nodeIndex !== -1) {
        if (action.name) {
          articles[nodeIndex] = { ...articles[nodeIndex], ...action.data.data, ...{ name: action.name } };
        } else {
          articles[nodeIndex] = {
            ...articles[nodeIndex],
            ...action.data.data,
            ...{
              star: action.star !== undefined ? action.star : articles[nodeIndex].star,
              customTime: action.customTime || articles[nodeIndex].customTime,
            },
          };
        }
      }

      if (action.modifyState) {
        return {
          ...state,
          saveStatus: action.saveStatus,
          saveTime: new Date().getTime(),
          article: {
            ...state.article,
            ...{
              name: action.name,
              // detail: action.detail,
              star: action.star,
              customTime: action.customTime,
            },
          },
          articles,
        };
      } else if (nameChanged) {
        // 如果文章名字更改了，更新文章detail（因为文章标题会在detail中体现出来）
        return {
          ...state,
          saveStatus: action.saveStatus,
          saveTime: new Date().getTime(),
          article: {
            ...state.article,
            ...{
              name: action.name,
              detail: action.detail,
              star: action.star,
              customTime: action.customTime,
            },
          },
          articles,
        };
      } else {
        return {
          ...state,
          saveStatus: action.saveStatus,
          saveTime: new Date().getTime(),
          articles,
        };
      }

    case actionTypes.EDIT_DRAW_SUCCEEDED: {
      return {
        ...state,
        article: { ...state.article, ...action.data.data, ...{ detail: action.detail, name: action.name } },
        saveStatus: action.saveStatus,
        saveTime: new Date().getTime(),
      };
    }
    case actionTypes.CLEAR_ARTICLE:
      return {
        ...state,
        article: null,
        saveTime: null,
      };
    case treeActionTypes.TOGGLE_DOC_PANEL:
      return {
        ...state,
        docPanelVisible: action.visible,
        articles: action.visible ? state.articles : [],
      };
    case actionTypes.READ_NTFY_SUCCEEDED:
      if (action.messageKey) {
        const notifications = [...state.notifications];
        const notification = notifications.find((ntfy: Notification) => ntfy._key === action.messageKey);
        if (notification) {
          notification.status = 2;
        }
        return {
          ...state,
          unreadNum: state.unreadNum - 1,
          notifications: notifications,
        };
      } else {
        return {
          ...state,
          reloadNotification: true,
        };
      }
    case actionTypes.CLEAR_NTFY_SUCCEEDED:
      return {
        ...state,
        notificationsTotal: 0,
        notifications: [],
      };
    case actionTypes.PUBLIC_SHARE_SUCCEEDED: {
      if (state.article && !action.isBook) {
        const article = { ...state.article };
        article.isPublic = !article.isPublic;
        return { ...state, article };
      } else {
        return state;
      }
    }
    case actionTypes.CHECK_PUBLIC_SHARE_SUCCEEDED: {
      return {
        ...state,
        isPublicShare: action.data.data.public ? true : false,
      };
    }
    case actionTypes.CLEAR_PUBLIC_SHARE: {
      return {
        ...state,
        isPublicShare: null,
      };
    }
    case actionTypes.CHANGE_STYLE_TYPE:
      return {
        ...state,
        article: { ...state.article, styleType: action.data },
        saveStatus: -1,
      };
    case actionTypes.FAV_ARTICLE_SUCCEEDED: {
      let articles = JSON.parse(JSON.stringify(state.articles));
      let index = articles.findIndex((article: ArticleType) => {
        if (article.type === 'shortcuts') {
          return article.linkNode._key === action.nodeKey;
        } else {
          return article._key === action.nodeKey;
        }
      });
      if (index !== -1) {
        articles[index].hasCollect = !articles[index].hasCollect;
      }
      const article = state.article ? { ...state.article } : null;
      if (article) {
        article.hasCollect = !article.hasCollect;
      }
      return {
        ...state,
        articles: articles,
        article,
      };
    }
    case actionTypes.ARTICLE_CHANGED:
      return {
        ...state,
        saveTime: undefined,
      };
    case actionTypes.UPLOAD_FILE_SUCCEEDED: {
      const articles = [...state.articles];
      articles.unshift(action.data.data);

      return {
        ...state,
        articles: articles,
      };
    }
    case treeActionTypes.DRAG_SUCCEEDED: {
      let articles = [...state.articles];
      let nodeIndex = articles.findIndex((node: any) => node._key === action.nodeKey);
      if (action.placement === 'in' && action.targetNodeKey !== action.oldFather) {
        articles.splice(nodeIndex, 1);
      } else {
        if (articles[nodeIndex]) {
          articles[nodeIndex] = { ...articles[nodeIndex], ...action.data.data };
        }
      }

      return {
        ...state,
        articles: articles,
      };
    }
    case treeActionTypes.RENAME_NODE_SUCCEEDED: {
      let article;
      if (state.article) {
        article = { ...state.article, ...{ name: action.name } };
      }
      return {
        ...state,
        article: article || state.article,
      };
    }
    case treeActionTypes.FAV_SUCCEEDED: {
      const articles = [...state.articles];
      const index = articles.findIndex((item) => item._key === action.nodeKey);
      if (index !== -1) {
        articles[index].hasCollect = !articles[index].hasCollect;
      }

      const node = action.data.data;
      const navArticles = [...state.navArticles];
      if (node) {
        if (
          node.type === 'folder' ||
          node.type.includes('Folder') ||
          node.type === 'project' ||
          node.docType === 'task-tree'
        ) {
          navArticles.push(node);
        }
      } else {
        const navIndex = navArticles.findIndex((item) => (item.linkId || item._key) === action.nodeKey);
        navArticles.splice(navIndex, 1);
      }
      return {
        ...state,
        articles,
        navArticles,
      };
    }
    case actionTypes.GET_QUICK_ACCESS_SUCCEEDED: {
      return {
        ...state,
        quickAccessList: action.data.data,
      };
    }
    case actionTypes.ADD_QUICK_ACCESS_SUCCEEDED: {
      const quickAccessList = [...state.quickAccessList];
      quickAccessList.unshift(action.data.data);
      return {
        ...state,
        quickAccessList,
      };
    }
    case actionTypes.REMOVE_QUICK_ACCESS_SUCCEEDED: {
      const quickAccessList = [...state.quickAccessList];
      let nodeIndex = quickAccessList.findIndex((node: any) => node._key === action.nodeKey);
      quickAccessList.splice(nodeIndex, 1);
      return {
        ...state,
        quickAccessList,
      };
    }
    case taskActionTypes.UPDATE_TASK_SUCCEEDED: {
      const task: Task = action.data.data;
      let taskList = [...state.articles];

      let i = taskList.findIndex((task) => task._key === action.nodeKey);
      taskList[i] = { ...taskList[i], ...task, ...{ name: task.name } };

      let article = { ...state.article };
      if (article) {
        article = { ...state.article, ...task };
      }

      return {
        ...state,
        articles: taskList,
        article,
      };
    }
    case treeActionTypes.CHANGE_ICON_SUCCEEDED:
    case treeActionTypes.REVERT_ICON_SUCCEEDED: {
      let articles = [...state.articles];
      const article =
        state.article && state.article._key === action.nodeKey ? { ...state.article, icon: action.icon } : null;
      let nodeIndex = articles.findIndex((node: any) => node._key === action.nodeKey);
      if (nodeIndex !== -1) {
        articles[nodeIndex].icon = action.icon;
      }
      return {
        ...state,
        articles: articles,
        article: article ? article : state.article,
      };
    }
    case actionTypes.GET_BANGUMI_LIST_SUCCEEDE: {
      return {
        ...state,
        articles: action.data.data,
      };
    }
    case treeActionTypes.CONVERT_NODE_SUCCEEDED: {
      if (state.article && action.data && state.article._key === action.data.data?._key) {
        const newNode = { ...action.data.data, ...action.nodeInfoObj };
        return {
          ...state,
          article: { ...state.article, ...newNode },
        };
      } else {
        return state;
      }
    }
    case treeActionTypes.CHANGE_COVER_SUCCEEDED: {
      const article = { ...state.article, cover: action.cover };
      return {
        ...state,
        article,
      };
    }
    default:
      return state;
  }
};
