import MutilSelectedNodeKey from '../../interfaces/MutilSelectedNodeKey';
import Node from '../../interfaces/Node';
import NodeMap from '../../interfaces/NodeMap';

export const actionTypes = {
  // 切换树视图
  CHANGE_TREE_VIEW: 'CHANGE_TREE_VIEW',
  // 获取节点路径
  GET_NODE_PATH: 'GET_NODE_PATH',
  GET_NODE_PATH_SUCCEEDED: 'GET_NODE_PATH_SUCCEEDED',
  // 选中目录节点
  CLICK_MENU_NODE: 'CLICK_MENU_NODE',
  // 獲取樹
  GET_REPOSITORY_TREE: 'GET_REPOSITORY_TREE',
  GET_REPOSITORY_TREE_SUCCESS: 'GET_REPOSITORY_TREE_SUCCESS',
  GET_FOLDER_TREE: 'GET_FOLDER_TREE',
  GET_FOLDER_TREE_SUCCESS: 'GET_FOLDER_TREE_SUCCESS',
  CLEAR_TREE: 'CLEAR_TREE',
  CLEAR_BOOK: 'CLEAR_BOOK',
  // 獲取其他樹
  GET_OTHER_TREE: 'GET_OTHER_TREE',
  GET_OTHER_TREE_SUCCESS: 'GET_OTHER_TREE_SUCCESS',
  // 點擊選中節點
  CLICK_NODE: 'CLICK_NODE',
  CLICK_NODE_BY_ID: 'CLICK_NODE_BY_ID',
  // 重命名節點
  RENAME_NODE: 'RENAME_NODE',
  RENAME_NODE_SUCCEEDED: 'RENAME_NODE_SUCCEEDED',
  // 更改排序
  CHANGE_SORT: 'CHANGE_SORT',
  CHANGE_SORT_SUCCEEDED: 'CHANGE_SORT_SUCCEEDED',
  // 創建節點
  CREATE_NODE: 'CREATE_NODE',
  CREATE_NODE_SUCCEEDED: 'CREATE_NODE_SUCCEEDED',
  // 展开節點
  EXPAND_NODE: 'EXPAND_NODE',
  EXPAND_NODE_SUCCEEDED: 'EXPAND_NODE_SUCCEEDED',
  // 展开電子書節點
  EXPAND_BOOK: 'EXPAND_BOOK',
  EXPAND_BOOK_SUCCEEDED: 'EXPAND_BOOK_SUCCEEDED',
  // 收起電子書節點
  COLLAPSE_BOOK: 'COLLAPSE_BOOK',
  // 節點歸檔
  ARCHIVE: 'ARCHIVE',
  ARCHIVE_SUCCEEDED: 'ARCHIVE_SUCCEEDED',
  // 收藏節點
  FAV: 'FAV',
  FAV_SUCCEEDED: 'FAV_SUCCEEDED',
  // 切換菜單
  TOGGLE_MENU: 'TOGGLE_MENU',
  TOGGLE_PREVIEW_PANEL: 'TOGGLE_PREVIEW_PANEL',
  TOGGLE_SHARE_PANEL: 'TOGGLE_SHARE_PANEL',
  TOGGLE_DOC_PANEL: 'TOGGLE_DOC_PANEL',
  TOGGLE_NTFY_PANEL: 'TOGGLE_NTFY_PANEL',
  TOGGLE_HISTORY_PANEL: 'TOGGLE_HISTORY_PANEL',
  // 拖拽節點
  DRAG: 'DRAG',
  DRAG_SUCCEEDED: 'DRAG_SUCCEEDED',
  DRAG_BATCH: 'DRAG_BATCH',
  DRAG_BATCH_SUCCEEDED: 'DRAG_BATCH_SUCCEEDED',
  // 粘貼節點
  PASTE: 'PASTE',
  PASTE_SUCCEEDED: 'PASTE_SUCCEEDED',
  // 點擊快捷方式節點
  CLICK_SHOTCUT: 'CLICK_SHOTCUT',
  // 轉換節點
  CONVERT_NODE: 'CONVERT_NODE',
  CONVERT_NODE_SUCCEEDED: 'CONVERT_NODE_SUCCEEDED',
  // 上傳圖標
  UPLOAD_ICON: 'UPLOAD_ICON',
  // 更改圖標
  CHANGE_ICON: 'CHANGE_ICON',
  CHANGE_ICON_SUCCEEDED: 'CHANGE_ICON_SUCCEEDED',
  REVERT_ICON: 'REVERT_ICON',
  REVERT_ICON_SUCCEEDED: 'REVERT_ICON_SUCCEEDED',
  // 更改封面
  CHANGE_COVER: 'CHANGE_COVER',
  CHANGE_COVER_SUCCEEDED: 'CHANGE_COVER_SUCCEEDED',
  GET_LOG_LIST: 'GET_LOG_LIST',
  GET_LOG_LIST_SUCCEEDED: 'GET_LOG_LIST_SUCCEEDED',
  CLEAR_TOEDIT: 'CLEAR_TOEDIT',
  IMPORT: 'IMPORT',
  IMPORT_SUCCEEDED: 'IMPORT_SUCCEEDED',
  IMPORT_FILES: 'IMPORT_FILES',
  IMPORT_FILES_SUCCEEDED: 'IMPORT_FILES_SUCCEEDED',
  UPDATE_TASK_NODE: 'UPDATE_TASK_NODE',
  UPDATE_TASK_NODE_SUCCEEDED: 'UPDATE_TASK_NODE_SUCCEEDED',
  MOVE_TASK_TREE: 'MOVE_TASK_TREE',
  MOVE_TASK_TREE_SUCCEEDED: 'MOVE_TASK_TREE_SUCCEEDED',
  GET_PROJECT_LIST: 'GET_PROJECT_LIST',
  GET_PROJECT_LIST_SUCCEEDED: 'GET_PROJECT_LIST_SUCCEEDED',
  ADD_PROJECT: 'ADD_PROJECT',
  ADD_PROJECT_SUCCEEDED: 'ADD_PROJECT_SUCCEEDED',
  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_SUCCEEDED: 'DELETE_PROJECT_SUCCEEDED',
  EXPORT_TREE: 'EXPORT_TREE',
  EXPORT_TREE_SUCCEEDED: 'EXPORT_TREE_SUCCEEDED',
  TOGGLE_BOOK_EDITABLE: 'TOGGLE_BOOK_EDITABLE',
  // 评论
  POST_COMMENT: 'POST_COMMENT',
  POST_COMMENT_SUCCEEDED: 'POST_COMMENT_SUCCEEDED',
  DELETE_COMMENT: 'DELETE_COMMENT',
  DELETE_COMMENT_SUCCEEDED: 'DELETE_COMMENT_SUCCEEDED',
  GET_COMMENTS: 'GET_COMMENTS',
  GET_COMMENTS_SUCCEEDED: 'GET_COMMENTS_SUCCEEDED',
  CLEAR_COMMENTS: 'CLEAR_COMMENTS',
  CLEAR_LOGS: 'CLEAR_LOGS',
  MUTI_SELECT: 'MUTI_SELECT',
  SWITCH_FONT_TYPE: 'SWITCH_FONT_TYPE',
  CLICK_FOR_SORT: 'CLICK_FOR_SORT',
  GET_ROOT_KEY_HISTORY: 'GET_ROOT_KEY_HISTORY',
  GET_ROOT_KEY_HISTORY_SUCCEEDED: 'GET_ROOT_KEY_HISTORY_SUCCEEDED',
  UPDATE_NODE_ATTACH: 'UPDATE_NODE_ATTACH',
  UPDATE_NODE_ATTACH_SUCCEEDED: 'UPDATE_NODE_ATTACH_SUCCEEDED',
  GET_CARD_LIST: 'GET_CARD_LIST',
  GET_CARD_LIST_SUCCEEDED: 'GET_CARD_LIST_SUCCEEDED',
  GET_CARD_DETAIL: 'GET_CARD_DETAIL',
  GET_CARD_DETAIL_SUCCEEDED: 'GET_CARD_DETAIL_SUCCEEDED',
  UPDATE_CARD: 'UPDATE_CARD',
  UPDATE_CARD_SUCCEEDED: 'UPDATE_CARD_SUCCEEDED',
  CLEAR_CARD_DETAIL: 'CLEAR_CARD_DETAIL',
  ADD_NOTE: 'ADD_NOTE',
  ADD_NOTE_SUCCEEDED: 'ADD_NOTE_SUCCEEDED',
  UPDATE_NOTE: 'UPDATE_NOTE',
  UPDATE_NOTE_SUCCEEDED: 'UPDATE_NOTE_SUCCEEDED',
  CONVERT_TO_CHILD: 'CONVERT_TO_CHILD',
  CONVERT_TO_CHILD_SUCCEEDED: 'CONVERT_TO_CHILD_SUCCEEDED',
  APPEND_CHILDREN_TO_NODE: 'APPEND_CHILDREN_TO_NODE',
};

export function changeTreeView(nodeKey: string, viewType: string, noSave?: boolean) {
  return {
    type: actionTypes.CHANGE_TREE_VIEW,
    nodeKey,
    viewType,
    noSave,
  };
}

export function getNodePath(nodeKey: string, startNodeKey?: string, includeStartNodeKey?: boolean) {
  return { type: actionTypes.GET_NODE_PATH, nodeKey, startNodeKey, includeStartNodeKey };
}
export function getNodePathSuccess(data: any) {
  return {
    type: actionTypes.GET_NODE_PATH_SUCCEEDED,
    data,
  };
}

export function clickMenuNode(node: Node | null, toEdit?: boolean) {
  return {
    type: actionTypes.CLICK_MENU_NODE,
    node,
    toEdit,
  };
}

export function getRepositoryTree(
  nodeKey?: string,
  showEditor?: boolean,
  fatherKey?: string,
  rootKey?: string,
  showDetail?: boolean,
  isMenuTree?: boolean,
  pageMode?: boolean,
  partialLoading?: boolean,
) {
  return {
    type: actionTypes.GET_REPOSITORY_TREE,
    nodeKey,
    showEditor,
    fatherKey,
    rootKey,
    showDetail,
    isMenuTree,
    pageMode,
    partialLoading,
  };
}
export function getRepositoryTreeSuccess(data: any, isMenuTree?: boolean, partialLoading?: boolean) {
  return {
    type: actionTypes.GET_REPOSITORY_TREE_SUCCESS,
    data,
    isMenuTree,
    partialLoading,
  };
}

export function getFolderTree(
  nodeKey?: string,
  returnAll?: number,
  returnTaskTree?: number,
  treeType?: 'menu' | 'quick-access',
  getFolderTree?: boolean,
) {
  return {
    type: actionTypes.GET_FOLDER_TREE,
    nodeKey,
    returnAll,
    returnTaskTree,
    treeType,
    getFolderTree,
  };
}
export function getFolderTreeSuccess(data: any, treeType?: 'menu' | 'quick-access') {
  return {
    type: actionTypes.GET_FOLDER_TREE_SUCCESS,
    data,
    treeType,
  };
}

export function getOtherTree(nodeKey: string) {
  return {
    type: actionTypes.GET_OTHER_TREE,
    nodeKey,
  };
}
export function getOtherTreeSuccess(data: any) {
  return {
    type: actionTypes.GET_OTHER_TREE_SUCCESS,
    data,
  };
}

export function expandBook(nodeKey: string, fatherKey: string) {
  return {
    type: actionTypes.EXPAND_BOOK,
    nodeKey,
    fatherKey,
  };
}
export function expandBookSuccess(nodeKey: string, data: any) {
  return {
    type: actionTypes.EXPAND_BOOK_SUCCEEDED,
    nodeKey,
    data,
  };
}

export function collpaseBook(nodeKey: string) {
  return {
    type: actionTypes.COLLAPSE_BOOK,
    nodeKey,
  };
}

export function clearBook() {
  return {
    type: actionTypes.CLEAR_BOOK,
  };
}

export function clickNode(node: Node | null) {
  return {
    type: actionTypes.CLICK_NODE,
    node,
  };
}

export function clickNodeById(nodeKey: string) {
  return {
    type: actionTypes.CLICK_NODE_BY_ID,
    nodeKey,
  };
}

/**
 * 节点改名
 * @param nodeKey
 * @param name
 * @param rootKey 项目下传，不返回文件夹节点图标
 * @param noMessage 不发送消息
 * @returns
 */
export function renameNode(nodeKey: string, name: string, rootKey?: string, noMessage?: boolean) {
  return {
    type: actionTypes.RENAME_NODE,
    nodeKey,
    name: name || '新标题',
    rootKey,
    noMessage,
  };
}

export function renameNodeSuccess(nodeKey: string, name: string, noMessage?: boolean) {
  return {
    type: actionTypes.RENAME_NODE_SUCCEEDED,
    nodeKey: nodeKey,
    name: name,
    noMessage,
  };
}

export function changeSort(nodeKey: string, sortList: string[], toSelectKey?: string) {
  return {
    type: actionTypes.CHANGE_SORT,
    nodeKey,
    sortList,
    toSelectKey,
  };
}

export function changeSortSuccess(nodeKey: string, sortList: string[], toSelectKey?: string) {
  return {
    type: actionTypes.CHANGE_SORT_SUCCEEDED,
    nodeKey,
    sortList,
    toSelectKey,
  };
}

export function createNode(
  nodeKey: string,
  fatherKey: string,
  addType: 'peer' | 'elder-peer' | 'child',
  type: string,
  appKey?: string,
  rootKey?: string | null,
  docType?: 'e-book' | 'task-tree' | 'knowledgebase',
  name?: string,
  cardKey?: string,
  nodeInfoObj?: object,
  url?: string,
  fileSize?: number,
  fileType?: string,
  linkType?: string,
  inTiptap?: boolean,
  isProjectTree?: boolean,
  targetNodeKey?: string,
) {
  return {
    type: actionTypes.CREATE_NODE,
    nodeKey,
    fatherKey,
    addType,
    nodeType: type,
    appKey,
    rootKey,
    docType,
    name,
    cardKey,
    nodeInfoObj,
    url,
    fileSize,
    fileType,
    linkType,
    inTiptap,
    isProjectTree,
    targetNodeKey,
  };
}

export function createNodeSuccess(
  nodeKey: string,
  addType: 'peer' | 'child',
  data: any,
  nodeInfoObj?: object,
  inTiptap?: boolean,
  isProjectTree?: boolean,
) {
  return {
    type: actionTypes.CREATE_NODE_SUCCEEDED,
    nodeKey,
    addType,
    data,
    nodeInfoObj,
    inTiptap,
    isProjectTree,
  };
}

export function expandNode(nodeKey: string, fatherId: string, rootKey?: string) {
  return {
    type: actionTypes.EXPAND_NODE,
    nodeKey,
    fatherId,
    rootKey,
  };
}

export function expandNodeSuccess(nodeKey: string, data: any) {
  return {
    type: actionTypes.EXPAND_NODE_SUCCEEDED,
    nodeKey,
    data,
  };
}

export function archive(nodeKey: string, isTaskTree?: boolean) {
  return {
    type: actionTypes.ARCHIVE,
    nodeKey,
    isTaskTree,
  };
}

export function archiveSuccess(nodeKey: string, data: any) {
  return {
    type: actionTypes.ARCHIVE_SUCCEEDED,
    nodeKey,
    data,
  };
}

export function fav(nodeKey: string, viewType?: string) {
  return {
    type: actionTypes.FAV,
    nodeKey,
    viewType,
  };
}

export function favSuccess(nodeKey: string, data: any) {
  return {
    type: actionTypes.FAV_SUCCEEDED,
    nodeKey,
    data,
  };
}

export function drag(nodeKey: string, targetNodeKey: string, placement: 'up' | 'down' | 'in', oldFather: string) {
  return {
    type: actionTypes.DRAG,
    nodeKey,
    targetNodeKey,
    placement,
    oldFather,
  };
}

export function dragSuccess(
  nodeKey: string,
  targetNodeKey: string,
  placement: 'up' | 'down' | 'in',
  data: any,
  oldFather: string,
) {
  return {
    type: actionTypes.DRAG_SUCCEEDED,
    nodeKey,
    targetNodeKey,
    placement,
    data,
    oldFather,
  };
}

export function dragBatch(dragNodeArr: MutilSelectedNodeKey[], targetNodeKey: string, placement: 'up' | 'down' | 'in') {
  return {
    type: actionTypes.DRAG_BATCH,
    dragNodeArr,
    targetNodeKey,
    placement,
  };
}

export function dragBatchSuccess(
  dragNodeArr: MutilSelectedNodeKey[],
  targetNodeKey: string,
  placement: 'up' | 'down' | 'in',
) {
  return {
    type: actionTypes.DRAG_BATCH_SUCCEEDED,
    dragNodeArr,
    targetNodeKey,
    placement,
  };
}

export function paste(
  pasteNodeKey: string,
  pasteType: 'copy' | 'cut' | null,
  selectedId: string,
  oldFather?: string,
  rootKey?: string,
) {
  return {
    type: actionTypes.PASTE,
    pasteNodeKey,
    pasteType,
    selectedId,
    oldFather,
    rootKey,
  };
}

export function pasteSuccess(pasteNodeKey: string, pasteType: 'copy' | 'cut' | null, selectedId: string, data: any) {
  return {
    type: actionTypes.PASTE_SUCCEEDED,
    pasteNodeKey,
    pasteType,
    selectedId,
    data,
  };
}

export function toggleMenu(collapsed?: boolean) {
  return {
    type: actionTypes.TOGGLE_MENU,
    collapsed,
  };
}

export function toggleSharePanel(visible?: boolean) {
  return {
    type: actionTypes.TOGGLE_SHARE_PANEL,
    visible,
  };
}

export function togglePreviewPanel(visible?: boolean) {
  return {
    type: actionTypes.TOGGLE_PREVIEW_PANEL,
    visible,
  };
}

export function toggleDocPanel(visible: boolean, docPanelType?: number) {
  return {
    type: actionTypes.TOGGLE_DOC_PANEL,
    visible,
    docPanelType,
  };
}

export function toggleNtfyPanel(visible?: boolean) {
  return {
    type: actionTypes.TOGGLE_NTFY_PANEL,
    visible,
  };
}

export function toggleHistoryPanel(visible?: boolean) {
  return {
    type: actionTypes.TOGGLE_HISTORY_PANEL,
    visible,
  };
}

export function clickShotcut(nodeKey: string) {
  return {
    type: actionTypes.CLICK_SHOTCUT,
    nodeKey,
  };
}

/**
 * 转换节点
 * @param nodeKey
 * @param type
 * @param appKey
 * @param rootKey 如果是在项目下，传rootkey，不返回文件夹图标
 * @returns
 */
export function convertNode(
  nodeKey: string,
  type: string,
  appKey?: string,
  rootKey?: string,
  cardKey?: string,
  nodeInfoObj?: object,
  name?: string,
  url?: string,
  fileSize?: number,
  fileType?: string,
  linkType?: string,
  // 内联节点的目标节点key
  targetNodeKey?: string,
) {
  return {
    type: actionTypes.CONVERT_NODE,
    nodeKey,
    convertType: type,
    appKey,
    rootKey,
    cardKey,
    nodeInfoObj,
    name,
    url,
    fileSize,
    fileType,
    linkType,
    targetNodeKey,
  };
}

export function convertNodeSuccess(nodeKey: string, data: any, nodeInfoObj?: object, name?: string) {
  return {
    type: actionTypes.CONVERT_NODE_SUCCEEDED,
    nodeKey,
    data,
    nodeInfoObj,
    name,
  };
}

export function uploadIcon(nodeKey: string, file: any) {
  return {
    type: actionTypes.UPLOAD_ICON,
    nodeKey,
    file,
  };
}

export function changeIcon(nodeKey: string, icon: string) {
  return {
    type: actionTypes.CHANGE_ICON,
    nodeKey,
    icon,
  };
}

export function changeIconSuccess(nodeKey: string, icon: string) {
  return {
    type: actionTypes.CHANGE_ICON_SUCCEEDED,
    nodeKey,
    icon,
  };
}

export function revertIcon(nodeKey: string, isTaskTree?: boolean) {
  return {
    type: actionTypes.REVERT_ICON,
    nodeKey,
    isTaskTree,
  };
}
export function revertIconSuccess(nodeKey: string, icon: string) {
  return {
    type: actionTypes.REVERT_ICON_SUCCEEDED,
    nodeKey,
    icon,
  };
}

export function changeCover(nodeKey: string, cover?: string) {
  return {
    type: actionTypes.CHANGE_COVER,
    nodeKey,
    cover,
  };
}

export function changeCoverSuccess(nodeKey: string, cover: string) {
  return {
    type: actionTypes.CHANGE_COVER_SUCCEEDED,
    nodeKey,
    cover,
  };
}

export function getLogList(nodeKey: string, page: number, limit: number) {
  return {
    type: actionTypes.GET_LOG_LIST,
    nodeKey,
    page,
    limit,
  };
}
export function getLogListSuccess(pagination: boolean, data: any) {
  return {
    type: actionTypes.GET_LOG_LIST_SUCCEEDED,
    pagination,
    data,
  };
}

export function clearLog() {
  return {
    type: actionTypes.CLEAR_LOGS,
  };
}

export function clearToedit() {
  return {
    type: actionTypes.CLEAR_TOEDIT,
  };
}

export function clearTree(treeType?: 'tree' | 'menu-tree' | 'quick-access' | 'project-tree') {
  return {
    type: actionTypes.CLEAR_TREE,
    treeType,
  };
}

export function importNode(nodeKey: string, text: string, fatherId: string, rootKey?: string) {
  return {
    type: actionTypes.IMPORT,
    nodeKey,
    text,
    fatherId,
    rootKey,
  };
}

export function importNodeSuccess(nodeKey: string, data: any) {
  return {
    type: actionTypes.IMPORT_SUCCEEDED,
    nodeKey,
    data,
  };
}

export function importFiles(nodeKey: string, files: FileList, fatherId: string, rootKey?: string) {
  return {
    type: actionTypes.IMPORT_FILES,
    nodeKey,
    files,
    fatherId,
    rootKey,
  };
}

export function importFilesSuccess(nodeKey: string, data: any) {
  return {
    type: actionTypes.IMPORT_FILES_SUCCEEDED,
    nodeKey,
    data,
  };
}

export function updateTaskNode(
  nodeKeyArr: string[],
  executor?: string | null,
  hour?: number | null,
  limitDay?: number | null,
  check?: boolean,
  filed?: boolean,
  star?: number,
) {
  return {
    type: actionTypes.UPDATE_TASK_NODE,
    nodeKeyArr,
    executor,
    hour,
    limitDay,
    check,
    filed,
    star,
  };
}
export function updateTaskNodeSuccess(data: any) {
  return {
    type: actionTypes.UPDATE_TASK_NODE_SUCCEEDED,
    data,
  };
}

export function getProjectList(filter?: 'create' | 'share') {
  return {
    type: actionTypes.GET_PROJECT_LIST,
    filter,
  };
}

export function getProjectListSuccess(data: any) {
  return {
    type: actionTypes.GET_PROJECT_LIST_SUCCEEDED,
    data,
  };
}

export function addProject(name: string) {
  return {
    type: actionTypes.ADD_PROJECT,
    name,
  };
}

export function addProjectSuccess(data: any) {
  return {
    type: actionTypes.ADD_PROJECT_SUCCEEDED,
    data,
  };
}

export function deleteProject(nodeKey: string, exitShare?: boolean) {
  return {
    type: actionTypes.DELETE_PROJECT,
    nodeKey,
    exitShare,
  };
}

export function deleteProjectSuccess(nodeKey: string, exitShare?: boolean) {
  return {
    type: actionTypes.DELETE_PROJECT_SUCCEEDED,
    nodeKey,
    exitShare,
  };
}

export function expotTree(type: 'excel' | 'txt' | 'opml', nodeKey?: string) {
  return {
    type: actionTypes.EXPORT_TREE,
    exportType: type,
    nodeKey,
  };
}

export function expotTreeSuccess(data: any) {
  return {
    type: actionTypes.EXPORT_TREE_SUCCEEDED,
    data,
  };
}

export function getComments(nodeKey: string, page: number, limit: number) {
  return {
    type: actionTypes.GET_COMMENTS,
    nodeKey,
    page,
    limit,
  };
}
export function getCommentsSuccess(page: number, data: any) {
  return {
    type: actionTypes.GET_COMMENTS_SUCCEEDED,
    page,
    data,
  };
}

export function deleteComment(commentId: string) {
  return {
    type: actionTypes.DELETE_COMMENT,
    commentId,
  };
}
export function deleteCommentsSuccess(commentId: string) {
  return {
    type: actionTypes.DELETE_COMMENT_SUCCEEDED,
    commentId,
  };
}
export function postComment(nodeKey: string, commentDetail: string, commentId?: string) {
  return {
    type: actionTypes.POST_COMMENT,
    nodeKey,
    commentDetail,
    commentId,
  };
}
export function postCommentSuccess(data: any) {
  return {
    type: actionTypes.POST_COMMENT_SUCCEEDED,
    data,
  };
}

export function clearComments() {
  return {
    type: actionTypes.CLEAR_COMMENTS,
  };
}

export function toggleBookEditable(editable: boolean) {
  return {
    type: actionTypes.TOGGLE_BOOK_EDITABLE,
    editable,
  };
}
export function mutiSelect(selectedNodes: Node[]) {
  return {
    type: actionTypes.MUTI_SELECT,
    selectedNodes,
  };
}

export function moveTaskTree(nodeKey: string, targetNodeKey: string, oldFather: string) {
  return {
    type: actionTypes.MOVE_TASK_TREE,
    nodeKey,
    targetNodeKey,
    oldFather,
  };
}
export function moveTaskTreeSuccess(nodeKey: string, targetNodeKey: string) {
  return {
    type: actionTypes.MOVE_TASK_TREE_SUCCEEDED,
    nodeKey,
    targetNodeKey,
  };
}

export function switchFontType(fontType?: 'normal' | 'bold') {
  return {
    type: actionTypes.SWITCH_FONT_TYPE,
    fontType,
  };
}

export function clickForSort(nodeKey: string) {
  return {
    type: actionTypes.CLICK_FOR_SORT,
    nodeKey,
  };
}

export function getRootKeyHistory() {
  return {
    type: actionTypes.GET_ROOT_KEY_HISTORY,
  };
}

export function getRootKeyHistorySuccess(data: any) {
  return {
    type: actionTypes.GET_ROOT_KEY_HISTORY_SUCCEEDED,
    data,
  };
}

export function updateNodeAttach(nodeKey: string, attach: Object[]) {
  return {
    type: actionTypes.UPDATE_NODE_ATTACH,
    nodeKey,
    attach,
  };
}

export function updateNodeAttachSuccess(nodeKey: string, attach: Object[]) {
  return {
    type: actionTypes.UPDATE_NODE_ATTACH_SUCCEEDED,
    nodeKey,
    attach,
  };
}

export function getCardList(name?: string) {
  return {
    type: actionTypes.GET_CARD_LIST,
    name,
  };
}

export function getCardListSuccess(data: any) {
  return {
    type: actionTypes.GET_CARD_LIST_SUCCEEDED,
    data,
  };
}

export function getCardDetail(cardKey: string) {
  return {
    type: actionTypes.GET_CARD_DETAIL,
    cardKey,
  };
}

export function getCardDetailSuccess(data: any) {
  return {
    type: actionTypes.GET_CARD_DETAIL_SUCCEEDED,
    data,
  };
}

export function clearCardDetail() {
  return {
    type: actionTypes.CLEAR_CARD_DETAIL,
  };
}

export function updateCard(nodeKey: string, nodeInfoObj: object) {
  return {
    type: actionTypes.UPDATE_CARD,
    nodeKey,
    nodeInfoObj,
  };
}

export function updateCardSuccess(data: any) {
  return {
    type: actionTypes.UPDATE_CARD_SUCCEEDED,
    data,
  };
}

export function addNote(nodeKey: string) {
  return {
    type: actionTypes.ADD_NOTE,
    nodeKey,
  };
}

export function addNoteSuccess(nodeKey: string, data: any) {
  return {
    type: actionTypes.ADD_NOTE_SUCCEEDED,
    nodeKey,
    data,
  };
}

export function updateNote(nodeKey: string, note: string | null) {
  return {
    type: actionTypes.UPDATE_NOTE,
    nodeKey,
    note,
  };
}

export function updateNoteSuccess(nodeKey: string, note: string | null) {
  return {
    type: actionTypes.UPDATE_NOTE_SUCCEEDED,
    nodeKey,
    note,
  };
}

export function convertToChild(nodeKey: string, targetNodeKey: string, targetPlaceIndex?: number) {
  return {
    type: actionTypes.CONVERT_TO_CHILD,
    nodeKey,
    targetNodeKey,
    targetPlaceIndex,
  };
}

export function convertToChildSuccess(nodeKey: string, targetNodeKey: string, targetPlaceIndex?: number) {
  return {
    type: actionTypes.CONVERT_TO_CHILD_SUCCEEDED,
    nodeKey,
    targetNodeKey,
    placement: 'in',
    targetPlaceIndex,
  };
}

export function appendChildrenToNode(targetNodeKey: string, addedNodeMap: NodeMap) {
  return {
    type: actionTypes.APPEND_CHILDREN_TO_NODE,
    targetNodeKey,
    addedNodeMap,
  };
}
