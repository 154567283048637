import { actionTypes as commonActionTypes } from '../actions/commonActions';
import { actionTypes as articleActionTypes } from '../actions/articleActions';
import { actionTypes as treeActionTypes } from '../actions/treeActions';
import { actionTypes as taskActionTypes } from '../actions/taskActions';
import User from '../../interfaces/User';
import AppType from '../../interfaces/AppType';
import Node from '../../interfaces/Node';
import EventEmitter from '../../util/event';
import CategoryType from '../../interfaces/CategoryType';
import { IndexDBOperate } from '../../util/IndexDBOperate';
interface Message {
  visible: boolean;
  text: string;
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
}

interface SoundEffect {
  play: boolean;
  name:
    | ''
    | 'create'
    | 'compelete'
    | 'cancel'
    | 'buttonClick'
    | 'systemInit'
    | 'loginSuccess'
    | 'receiveTask'
    | 'notification'
    | 'archive'
    | 'pack'
    | 'createTask';
}

interface Wallpaper {
  _key: string;
  url: string;
}

export interface Common {
  loading: boolean;
  articleLoading: boolean;
  // 局部加载
  partialLoading: boolean;
  showMessage: boolean;
  message: Message;
  sharedUsers: User[];
  searchedUsers: User[];
  recentCollabList: User[];
  cardUsers: User[];
  // 右侧内容类型
  contentType: 'tree' | 'apps' | 'share' | 'fav' | 'recent';
  allApps: AppType[];
  wallpapers: Wallpaper[];
  totalWallpaperNum: number;
  unsplashImages: any[];
  helps: Node[];
  projectCollapsed: boolean;
  darkMode: boolean;
  categories: CategoryType[];
  apps: AppType[];
  chatPanelVisible: boolean;
  showSearchPanel: boolean;
  privateChatId: string | null;
  soundEffect: SoundEffect;
  indexDBOperate: IndexDBOperate | null;
}

const defaultState: Common = {
  loading: false,
  articleLoading: false,
  partialLoading: false,
  showMessage: false,
  message: {
    visible: false,
    text: '',
    severity: undefined,
  },
  sharedUsers: [],
  searchedUsers: [],
  recentCollabList: [],
  cardUsers: [],
  contentType: 'tree',
  allApps: [],
  wallpapers: [],
  totalWallpaperNum: 0,
  unsplashImages: [],
  helps: [],
  projectCollapsed: false,
  darkMode: localStorage.getItem('dark-mode') ? (localStorage.getItem('dark-mode') === 'dark' ? true : false) : false,
  categories: [],
  apps: [],
  chatPanelVisible: false,
  showSearchPanel: false,
  privateChatId: null,
  soundEffect: {
    play: false,
    name: '',
  },
  indexDBOperate: null,
};

export const common = (state = defaultState, action: any) => {
  switch (action.type) {
    case commonActionTypes.FAILED:
      return {
        ...state,
        loading: false,
        message: {
          visible: true,
          text: action.error.msg,
          severity: 'error',
        },
      };
    case commonActionTypes.SET_MESSAGE:
      return {
        ...state,
        message: {
          visible: action.visible,
          text: action.text,
          severity: action.severity ? action.severity : state.message.severity,
        },
      };
    case commonActionTypes.PLAY_SOUND_EFFECT:
      return {
        ...state,
        soundEffect: {
          play: action.play,
          name: action.name,
        },
      };
    case articleActionTypes.ADD_ARTICLE_SUCCEEDED:
      if (action.notShowMessage) {
        return {
          ...state,
          articleLoading: false,
          partialLoading: false,
        };
      } else {
        return {
          ...state,
          articleLoading: false,
          partialLoading: false,
          message: {
            visible: true,
            text: '创建成功！',
            severity: 'success',
          },
        };
      }
    case articleActionTypes.ADD_ARTICLE:
    case articleActionTypes.EDIT_ARTICLE: {
      if (action.partialLoading) {
        return {
          ...state,
          partialLoading: true,
        };
      } else {
        return {
          ...state,
          articleLoading: true,
        };
      }
    }
    case articleActionTypes.EDIT_ARTICLE_SUCCEEDED:
      if (action.notShowMessage) {
        return {
          ...state,
          articleLoading: false,
        };
      } else {
        return {
          ...state,
          articleLoading: false,
          message: {
            visible: true,
            text: '保存成功！',
            severity: 'success',
          },
        };
      }

    case articleActionTypes.DELETE_ARTICLE_SUCCEEDED:
    case treeActionTypes.DELETE_PROJECT_SUCCEEDED: {
      if (action.noMessage) {
        return state;
      } else {
        return {
          ...state,
          message: {
            visible: true,
            text: action.exitShare ? '退出成功！' : '删除成功！',
            severity: 'success',
          },
        };
      }
    }

    case treeActionTypes.MOVE_TASK_TREE_SUCCEEDED:
      return {
        ...state,
        message: {
          visible: true,
          text: '移动成功！',
          severity: 'success',
        },
      };
    case articleActionTypes.GET_DOC_LIST:
    case articleActionTypes.GET_ARTICLE_BY_ID:
    case articleActionTypes.GET_SHARE_LIST:
    case articleActionTypes.GET_SHARE_OUT_LIST:
    case articleActionTypes.GET_FAV_LIST:
    case articleActionTypes.GET_RECYCLE:
    case articleActionTypes.RESTORE:
    case articleActionTypes.SEARCH_NODE:
    case treeActionTypes.UPLOAD_ICON:
    case treeActionTypes.IMPORT:
    case commonActionTypes.GET_WALLPAPERS:
    case commonActionTypes.GET_UNSPLASH:
    case commonActionTypes.GET_ICONS:
    case commonActionTypes.SEARCH_UNSPLASH:
    case commonActionTypes.GET_FRIEND_LIST:
    case commonActionTypes.GET_RECENT_COLLAB:
    case treeActionTypes.EXPORT_TREE:
    case treeActionTypes.UPDATE_CARD:
    case taskActionTypes.GET_FILTER_CONFIG:
    case taskActionTypes.GET_PROJECT_TASKS:
    case taskActionTypes.GET_RECENT_TASKS:
    case articleActionTypes.GET_CHILD_NODES:
    case treeActionTypes.GET_LOG_LIST:
    case taskActionTypes.GET_DATE_LIST:
    case taskActionTypes.GET_USER_TASKS:
    case taskActionTypes.GET_TASK_LIST:
    case articleActionTypes.DELETE_RECYCLE:
      if (action.partialLoading) {
        return {
          ...state,
          partialLoading: true,
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    case articleActionTypes.GET_DOC_LIST_SUCCEEDED:
    case articleActionTypes.GET_ARTICLE_BY_ID_SUCCEEDED:
    case articleActionTypes.GET_SHARE_LIST_SUCCEEDED:
    case articleActionTypes.GET_SHARE_OUT_LIST_SUCCEEDED:
    case articleActionTypes.GET_RECYCLE__SUCCEEDED:
    case articleActionTypes.GET_FAV_LIST_SUCCEEDED:
    case articleActionTypes.SEARCH_NODE_SUCCEEDED:
    case treeActionTypes.CHANGE_ICON_SUCCEEDED:
    case treeActionTypes.EXPORT_TREE_SUCCEEDED:
    case treeActionTypes.UPDATE_CARD_SUCCEEDED:
    case taskActionTypes.GET_FILTER_CONFIG_SUCCEEDED:
    case taskActionTypes.GET_PROJECT_TASKS_SUCCEEDED:
    case taskActionTypes.GET_RECENT_TASKS_SUCCEEDED:
    case articleActionTypes.GET_CHILD_NODES_SUCCESS:
    case treeActionTypes.GET_LOG_LIST_SUCCEEDED:
    case taskActionTypes.GET_DATE_LIST_SUCCEEDED:
    case taskActionTypes.GET_USER_TASKS_SUCCEEDED:
    case taskActionTypes.GET_TASK_LIST_SUCCEEDED:
    case articleActionTypes.DELETE_RECYCLE_SUCCEEDED:
      if (action.partialLoading) {
        return {
          ...state,
          partialLoading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }
    case articleActionTypes.GET_CLIPS:
    case articleActionTypes.GET_NTFY_LIST:
    case taskActionTypes.GET_PROJECT_TASK_TREE:
      return {
        ...state,
        partialLoading: true,
      };
    case articleActionTypes.GET_CLIPS_SUCCEEDED:
    case articleActionTypes.GET_NTFY_LIST_SUCCEEDED:
    case taskActionTypes.GET_PROJECT_TASK_TREE_SUCCEEDED:
      return {
        ...state,
        partialLoading: false,
      };
    case treeActionTypes.IMPORT_SUCCEEDED:
      return {
        ...state,
        loading: false,
        message: {
          visible: true,
          text: '保存成功！',
          severity: 'success',
        },
      };
    case articleActionTypes.RESTORE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        message: {
          visible: true,
          text: '恢复成功！',
          severity: 'success',
        },
      };

    case commonActionTypes.GET_SHARED_PEOPLE_SUCCEEDED: {
      return {
        ...state,
        sharedUsers: action.data.data,
      };
    }
    case commonActionTypes.SEARCH_USER_SUCCEEDED:
    case commonActionTypes.SEARCH_USER_LOCAL_SUCCEEDED: {
      return {
        ...state,
        searchedUsers: action.data.data,
      };
    }
    case commonActionTypes.GET_RECENT_COLLAB_SUCCEEDED:
    case commonActionTypes.GET_FRIEND_LIST_SUCCEEDED: {
      return {
        ...state,
        recentCollabList: action.data.data,
        loading: false,
      };
    }
    case commonActionTypes.SHARE_SUCCEEDED: {
      let sharedUsers = [...state.sharedUsers];
      sharedUsers.push(action.data.data);
      return {
        ...state,
        sharedUsers: sharedUsers,
      };
    }
    case commonActionTypes.DELETE_SHARE_SUCCEEDED: {
      let sharedUsers = [...state.sharedUsers];
      let index = sharedUsers.findIndex((user: User) => user._key === action.toUser);
      sharedUsers.splice(index, 1);
      return {
        ...state,
        sharedUsers: sharedUsers,
      };
    }
    case commonActionTypes.EDIT_SHARE_SUCCEEDED: {
      let sharedUsers = [...state.sharedUsers];
      let index = sharedUsers.findIndex((user: User) => user._key === action.toUser);
      let user = sharedUsers[index];
      user.role = action.role;
      return {
        ...state,
        sharedUsers: sharedUsers,
      };
    }
    case commonActionTypes.DESTORY_SHARE_PANEL: {
      return {
        ...state,
        sharedUsers: [],
        searchedUsers: [],
        recentCollabList: [],
      };
    }
    case commonActionTypes.ADD_FRIEND_SUCCEEDED: {
      let recentCollabList = [...state.recentCollabList];
      recentCollabList.push(action.data.data);

      let searchedUsers = [...state.searchedUsers];
      const index = searchedUsers.findIndex((user) => user._key === action.userKey);
      if (index !== -1) {
        searchedUsers[index].beFriend = true;
      }

      return {
        ...state,
        recentCollabList,
        searchedUsers,
      };
    }
    case commonActionTypes.CLEAR_SEARCH_USER: {
      return {
        ...state,
        searchedUsers: [],
      };
    }
    case commonActionTypes.SET_CONTENT_TYPE: {
      return {
        ...state,
        contentType: action.contentType,
      };
    }
    case treeActionTypes.CLICK_MENU_NODE: {
      return {
        ...state,
        contentType: 'tree',
      };
    }
    case commonActionTypes.INIT_APPS_SUCCEEDED: {
      return {
        ...state,
        allApps: action.data.data,
      };
    }
    case commonActionTypes.GET_WALLPAPERS_SUCCEEDED:
    case commonActionTypes.GET_ICONS_SUCCEEDED:
      return {
        ...state,
        wallpapers: [...state.wallpapers, ...action.data.data],
        totalWallpaperNum: action.data.total,
        partialLoading: false,
      };
    case commonActionTypes.CLEAR_WALLPAPERS:
      return {
        ...state,
        wallpapers: [],
      };
    case commonActionTypes.GET_UNSPLASH_SUCCEEDED:
      return {
        ...state,
        unsplashImages: action.data,
        partialLoading: false,
      };
    case commonActionTypes.SEARCH_UNSPLASH_SUCCEEDED:
      return {
        ...state,
        unsplashImages: action.data.results,
        partialLoading: false,
      };
    case commonActionTypes.GET_HELP_SUCCEEDED:
      return {
        ...state,
        helps: action.data.data,
      };
    case commonActionTypes.COLLAPSE_PROJECT:
      EventEmitter.emit('reload');
      return {
        ...state,
        projectCollapsed: !state.projectCollapsed,
      };
    case commonActionTypes.CHANGE_COLOR_THEME:
      localStorage.setItem('dark-mode', !state.darkMode ? 'dark' : 'light');
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case commonActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case commonActionTypes.GET_CATEGORY_SUCCEEDED:
      return {
        ...state,
        categories: action.data.data,
      };
    case commonActionTypes.GET_STORE_APP_LIST_SUCCEEDED:
      return {
        ...state,
        apps: action.data.data,
      };
    case commonActionTypes.ADD_APP_SUCCEEDED: {
      let apps = [...state.apps];
      let index = apps.findIndex((app: any) => app._key === action.appKey);
      apps[index].hasAdd = 1;
      return {
        ...state,
        apps: apps,
      };
    }
    case commonActionTypes.DELETE_APP_SUCCEEDED: {
      let apps = [...state.apps];
      let index = apps.findIndex((app: any) => app._key === action.appKey);
      apps[index].hasAdd = 0;
      return {
        ...state,
        apps: apps,
      };
    }
    case commonActionTypes.GET_ADDED_APP_LIST_SUCCEEDED:
      return {
        ...state,
        apps: action.data.data,
      };
    case commonActionTypes.CLEAR_APPS:
      return {
        ...state,
        apps: [],
      };
    case commonActionTypes.FIX_APP_SUCCEEDED: {
      const apps = [...state.apps];
      const index = apps.findIndex((app) => app._key === action.nodeKey);
      if (index !== -1) {
        apps[index].top = !apps[index].top;
      }
      return {
        ...state,
        apps: apps,
      };
    }
    case commonActionTypes.DRAG_APP_SUCCEEDED: {
      const apps = [...state.apps];
      switch (action.placement) {
        case 'down':
          const dragIndex = apps.findIndex((item) => item._key === action.nodeKey);
          const dragMenu = apps[dragIndex];
          apps.splice(dragIndex, 1);
          const dropIndex = apps.findIndex((item) => item._key === action.targetNodeKey);
          apps.splice(dropIndex + 1, 0, dragMenu);
          break;
        default:
          break;
      }
      return {
        ...state,
        apps: apps,
      };
    }
    case commonActionTypes.SWITCH_CHAT_PANEL:
      return {
        ...state,
        chatPanelVisible: action.visible,
        privateChatId: null,
      };
    case commonActionTypes.SHOW_SEARCH_PANEL:
      return {
        ...state,
        showSearchPanel: action.visible,
      };
    case commonActionTypes.GET_PRIVATE_CHAT_ID_SUCCEEDED:
      return {
        ...state,
        privateChatId: action.privateChatId,
      };
    case commonActionTypes.CLEAR_PRIVATE_CHAT_ID:
      return {
        ...state,
        privateChatId: null,
      };
    case commonActionTypes.INIT_INDEXDB:
      return {
        ...state,
        indexDBOperate: new IndexDBOperate('working', 1, 'article'),
      };
    case commonActionTypes.GET_CARD_USERS_SUCCEEDED:
      return {
        ...state,
        cardUsers: action.data.data,
      };
    case commonActionTypes.ADD_CARD_USER: {
      let cardUsers = [...state.cardUsers];
      cardUsers.push(action.user);
      return {
        ...state,
        cardUsers,
      };
    }
    case commonActionTypes.DEL_CARD_USER: {
      let cardUsers = [...state.cardUsers];
      const index = cardUsers.findIndex((item) => item._key === action.user._key);
      cardUsers.splice(index, 1);
      return {
        ...state,
        cardUsers,
      };
    }
    case commonActionTypes.CLEAR_CARD_USER: {
      return {
        ...state,
        cardUsers: [],
      };
    }
    default:
      return state;
  }
};
