import axios from 'axios';
import MutilSelectedNodeKey from '../interfaces/MutilSelectedNodeKey';
import { FilterConfig } from '../interfaces/ProjectTask';
import Resource from '../interfaces/Resource';
import UserConfig from '../interfaces/UserConfig';
// 环境变量：https://www.html.cn/create-react-app/docs/adding-custom-environment-variables/
const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const API_URL = process.env.REACT_APP_API_URL;
let token: string | null = localStorage.getItem('auth_token');

const request = {
  get(path: string, params?: object) {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await axios({
          method: 'get',
          url: path,
          params: params,
          headers: {
            token: token,
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  post(path: string, params: object) {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await axios({
          method: 'post',
          url: path,
          data: params,
          headers: {
            token: token,
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  patch(path: string, params: object) {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await axios({
          method: 'patch',
          url: path,
          data: params,
          headers: {
            token: token,
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  delete(path: string, params: object) {
    return new Promise(async function (resolve, reject) {
      try {
        const response = await axios({
          method: 'delete',
          url: path,
          data: params,
          headers: {
            token: token,
          },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

const auth = {
  loginByToken(token: string) {
    return request.get(AUTH_URL + '/account/userinfo', { token: token });
  },
  syncUser(
    userKey: string,
    userName: string,
    mobile: string,
    app: number,
    appHigh: number,
    userAvatar?: string,
    email?: string,
    qr_id?: string,
  ) {
    return request.patch(API_URL + '/user', {
      userKey: userKey,
      userName: userName,
      userAvatar: userAvatar,
      email: email,
      mobile: mobile,
      app,
      appHigh,
      qr_id,
    });
  },
  loginByQrid(qr_id: string) {
    return request.get(API_URL + '/user/tokenByQrId', { qr_id });
  },
  // 获取七牛云uptoken
  getUptoken() {
    return request.get(AUTH_URL + '/upTokenQiniu/getQiNiuUpToken', {
      token: token,
      type: 2,
      bucketType: 4,
    });
  },
  getUptokenOverWrite(key: string) {
    return request.get(AUTH_URL + '/upTokenQiniu/getQiNiuUpTokenKey', {
      token: token,
      type: 2,
      key,
      bucketType: 4,
    });
  },
  searchUser(keyWord: string) {
    return request.get(API_URL + '/user/search', { keyWord });
  },
  // TODO 分页
  searchUserLocal(keyWord: string) {
    return request.get(API_URL + '/user/searchFriend', { keyWord, page: 1, limit: 1000 });
  },
  addFriend(friendKey: string) {
    return request.post(API_URL + '/user/friend', { friendKey });
  },
  getFriendList() {
    return request.get(API_URL + '/user/friend', { page: 1, limit: 2000 });
  },
  getRecentCollab() {
    return request.get(API_URL + '/user/partner');
  },
  switchToken(appHigh: number, app?: number) {
    return request.get(AUTH_URL + '/account/switchToken', {
      token: token,
      appHigh: appHigh,
      app: app,
    });
  },
  getWallPapers() {
    return request.get(API_URL + '/wallPaper');
  },
  getIcons(page: number, limit: number, tagKey?: string) {
    return request.get('https://icondata.qingtime.cn/icon', {
      page,
      limit,
      tagKey,
    });
  },
  getUnsplashImages() {
    return request.get('https://api.unsplash.com/photos/random', {
      client_id: 'PWr2enHljv71dG8XrmNwVorgtYkPfe89j5OXywvx7sc',
      count: 30,
    });
  },
  searchUnsplashImages(query: string, page: number, per_page: number) {
    return request.get('https://api.unsplash.com/search/photos', {
      client_id: 'PWr2enHljv71dG8XrmNwVorgtYkPfe89j5OXywvx7sc',
      query,
      page,
      per_page,
    });
  },
  getRandomUnsplashImage() {
    return request.get('https://api.unsplash.com/photos/random', {
      client_id: 'PWr2enHljv71dG8XrmNwVorgtYkPfe89j5OXywvx7sc',
      orientation: 'landscape',
      content_filter: 'high',
    });
  },
  getPrivateChatId(friendKey: string) {
    return request.get(API_URL + '/user/friend/privateChatId', {
      friendKey,
    });
  },
  remainingStorage(fileSize?: number) {
    return request.get(AUTH_URL + '/qiniuResource/remainSize', { fileSize });
  },
  updateStorage(resourceArr: Resource[]) {
    return request.post(AUTH_URL + '/qiniuResource', { resourceArr });
  },
  deleteQiniu(urls: string[]) {
    return request.delete(AUTH_URL + '/qiniuResource', { urlArr: urls });
  },
};

const options = {
  init() {
    return request.get(API_URL + '/user');
  },
  getWallPapers(page: number, limit: number) {
    return request.get(API_URL + '/wallPaper', {
      page,
      limit,
    });
  },
  setWallpaper(wallKey: string) {
    return request.patch(API_URL + '/user/subscribe', {
      wallKey: wallKey,
    });
  },
  updateUserConfig(config: UserConfig) {
    return request.patch(API_URL + '/user/config', {
      config,
    });
  },
  getApps(keyword?: string, tagKey?: string, added?: boolean) {
    return request.get(API_URL + '/appType', {
      name: keyword,
      tagKey,
      added,
    });
  },
  addApp(appKey: string) {
    return request.post(API_URL + '/user/app', {
      appKey: appKey,
    });
  },
  deleteApp(appKey: string) {
    return request.delete(API_URL + '/user/app', {
      appKey: appKey,
    });
  },
  getCategories() {
    return request.get(API_URL + '/tag');
  },
  fixApp(nodeKey: string) {
    return request.patch(API_URL + '/appType/top', {
      nodeKey,
    });
  },
};

const node = {
  addTreeNode(
    nodeKey: string,
    fatherKey: string,
    addType: 'peer' | 'elder-peer' | 'child',
    type: string,
    appKey: string,
    rootKey?: string,
    docType?: 'e-book' | 'task-tree',
    name?: string,
    cardKey?: string,
    nodeInfoObj?: object,
    url?: string,
    fileSize?: number,
    fileType?: string,
    linkType?: string,
    targetNodeKey?: string,
  ) {
    return request.post(API_URL + '/node/onTree', {
      nodeKey,
      fatherKey,
      addType,
      type,
      appKey,
      rootKey,
      name,
      docType,
      cardKey,
      nodeInfoObj,
      url,
      fileSize,
      fileType,
      linkType,
      linkNode: targetNodeKey,
    });
  },
  createNode(
    name: string,
    type: string,
    detail: any,
    appKey?: string,
    fatherId?: string,
    url?: string,
    fileSize?: number,
    thumbnail?: string,
    docType?: 'e-book' | 'task-tree' | 'knowledgebase',
    styleType?: any,
    fileType?: string,
    customTime?: number,
  ) {
    return request.post(API_URL + '/node', {
      name: name,
      type: type,
      fatherId: fatherId,
      appKey: appKey,
      url: url,
      detail: detail,
      fileSize: appKey ? 0 : url ? fileSize : undefined,
      thumbnail,
      docType,
      styleType,
      fileType,
      createTime: new Date().getTime(),
      customTime,
    });
  },
  /**
   * 更新节点
   * @param nodeKey
   * @param name
   * @param type
   * @param status 0：删除 1:正常
   */
  updateNode(
    nodeKey: string,
    name?: string,
    type?: string,
    detail?: any,
    status?: number,
    thumbnail?: string,
    url?: string,
    sortList?: string[],
    icon?: string,
    styleType?: any,
    openNewTab?: boolean,
    rootKey?: string,
    star?: number,
    customTime?: number,
    nodeInfoObj?: object,
    note?: string,
    fileSize?: number,
    fileType?: string,
    cover?: string,
  ) {
    return request.patch(API_URL + '/node', {
      nodeKey,
      name: name !== undefined ? (name ? name : '新标题') : undefined,
      type,
      status,
      detail,
      thumbnail,
      url,
      sortList,
      icon,
      styleType,
      openNewTab,
      rootKey,
      star,
      customTime,
      nodeInfoObj,
      note,
      fileSize,
      fileType,
      cover,
    });
  },
  /**
   * 修改节点任务(树上)
   * @param nodeKey
   * @param executor
   * @param hour
   * @param limitDay 截止时间
   * @param check
   * @param needObject
   */
  updateTask(
    nodeKeyArr: string[],
    executor?: string,
    hour?: number,
    limitDay?: number,
    check?: boolean,
    filed?: boolean,
    star?: number,
  ) {
    return request.patch(API_URL + '/node/task/batch', {
      nodeKeyArr,
      executor,
      hour,
      limitDay,
      checked: check,
      filed,
      star,
    });
  },
  /**
   * 修改节点任务
   * @param nodeKey
   * @param executor
   * @param hour
   * @param limitDay 截止时间
   * @param check
   * @param name
   */
  updateProjectTask(
    nodeKey: string,
    executor?: string,
    hour?: number,
    limitDay?: number,
    check?: boolean,
    name?: string,
    filed?: boolean,
  ) {
    return request.patch(API_URL + '/node/task/inProject', {
      nodeKey,
      executor,
      hour,
      limitDay,
      checked: check,
      name,
      filed,
    });
  },
  /**
   * 退出分享
   * @param nodeKey
   */
  exitShare(nodeKey: string) {
    return request.delete(API_URL + '/node/share/active', {
      nodeKey: nodeKey,
    });
  },
  getNodes(nodeKey: string) {
    return request.get(API_URL + '/node/list', {
      nodeKey: nodeKey,
    });
  },
  revertNodeIcon(nodeKey: string, isTaskTree?: boolean) {
    return request.patch(API_URL + '/node/icon/default', {
      nodeKey: nodeKey,
      isTaskTree,
    });
  },
  getNodePath(nodeKey: string, startNodeKey?: string, includeStartNodeKey?: boolean) {
    return request.get(API_URL + '/node/way', {
      nodeKey,
      startNodeKey,
      includeStartNodeKey,
    });
  },
  getNodeDeail(nodeKey: string, bookId?: string) {
    return request.get(API_URL + '/node/detail', {
      nodeKey: nodeKey,
      bookId,
    });
  },
  getNodeByKey(nodeKey: string) {
    return request.get(API_URL + '/node/info', {
      nodeKey: nodeKey,
    });
  },
  getNodeDeailNoToken(nodeKey: string) {
    return request.get(API_URL + '/node/detailNoToken', {
      nodeKey: nodeKey,
    });
  },
  /**
   * 回收箱
   */
  getRecycle(currentPage: number, perPage: number) {
    return request.get(API_URL + '/node/recycle', {
      page: currentPage,
      limit: perPage,
    });
  },
  completelyDelete(nodeKeyArr?: string[]) {
    return request.delete(API_URL + '/node/recycle', {
      nodeKeyArr: nodeKeyArr,
    });
  },
  /**
   * 搜索节点
   * @param keyWord 关键字
   * @param nodeKey 父亲节点，有则搜索该节点下的节点，无则搜索该用户的所有节点
   * @param appKey 有则搜索该文档类型的节点，没有则搜索所有节点
   * @param isFile 有则搜索文档节点
   */
  search(keyWord: string, nodeKey?: string, appKey?: string, isFile?: boolean, type?: string, cardKey?: string) {
    return request.get(API_URL + '/node/search', {
      nodeKey,
      keyWord,
      appKey,
      isFile,
      type,
      cardKey,
    });
  },
  copy(nodeKey: string, fatherId: string, rootKey?: string) {
    return request.post(API_URL + '/node/copyOnTree', {
      nodeKey,
      fatherId,
      rootKey,
    });
  },
  cut(nodeKeyArr: string[], fatherId: string, oldFather: string) {
    return request.post(API_URL + '/node/cut', {
      nodeKeyArr: nodeKeyArr,
      fatherId: fatherId,
      oldFather,
    });
  },
  drag(nodeKey: string, targetNodeKey: string, placement: 'up' | 'down' | 'in', oldFather: string) {
    return request.patch(API_URL + '/node/drag', {
      nodeKey,
      targetNodeKey,
      placement,
      oldFather,
    });
  },
  dragBatch(dragNodeArr: MutilSelectedNodeKey[], targetNodeKey: string, placement: 'up' | 'down' | 'in') {
    return request.patch(API_URL + '/node/drag/batch', {
      dragNodeArr,
      targetNodeKey,
      placement,
    });
  },
  /**
   * 批量删除
   * @param nodeKeyArr
   * @returns
   */
  deleteBatch(nodeKeyArr: string[]) {
    return request.delete(API_URL + '/node/batch', {
      nodeKeyArr,
    });
  },
  // 展开/收起
  expand(nodeKey: string, fatherId: string, rootKey?: string) {
    return request.patch(API_URL + '/node/contract', {
      nodeKey,
      fatherId,
      rootKey,
    });
  },
  // 打包/取消打包
  archive(nodeKey: string, isTaskTree?: boolean) {
    return request.patch(API_URL + '/node/pack', {
      nodeKey,
      isTaskTree,
    });
  },
  // 收藏/取消收藏
  fav(nodeKey: string, viewType?: string) {
    return request.post(API_URL + '/node/collect', {
      nodeKey,
      viewType,
    });
  },
  importNode(nodeKey: string, text: string, fatherId: string, rootKey?: string) {
    return request.post(API_URL + '/node/batch', {
      nodeKey,
      nodeText: text,
      fatherId,
      rootKey,
    });
  },
  /**
   * 获取最新上传的文件
   * @param currentPage
   * @param perPage
   * @param appKey
   * @returns
   */
  getRecentUpload(currentPage: number, perPage: number, projectKey?: string) {
    return request.get(API_URL + '/user/uploadFile', {
      page: currentPage,
      limit: perPage,
      projectKey,
    });
  },
  viewUploadFileLog(nodeKey: string) {
    return request.post(API_URL + '/node/uploadFile/log', {
      nodeKey,
    });
  },
  getNodeFromWeb(page: number, limit: number, type?: string) {
    return request.get(API_URL + '/node/fromWeb', {
      page,
      limit,
      type,
    });
  },
  // 獲取文檔列表
  getDocList(
    currentPage: number,
    perPage: number,
    appKey?: string,
    projectKey?: string,
    type?: string,
    star?: number,
    cardKey?: string,
  ) {
    return request.get(API_URL + '/node/file', {
      appKey: appKey,
      page: currentPage,
      limit: perPage,
      projectKey,
      type,
      star,
      cardKey,
    });
  },
  /**
   * 我的收藏
   * @param page 頁碼
   * @param limit 每頁條數
   * @param days 最近幾天的，沒有則是全部
   * @param showOriginal 是否顯示快捷方式的原始節點
   */
  getFavList(
    page: number,
    limit: number,
    days?: number,
    showOriginal?: boolean,
    appKey?: string,
    projectKey?: string,
    type?: string,
    star?: number,
    cardKey?: string,
  ) {
    return request.get(API_URL + '/user/fav', {
      page,
      limit,
      days,
      showOriginal,
      appKey,
      projectKey,
      type,
      star,
      cardKey,
    });
  },
  /**
   * 分享給我的
   * @param page 頁碼
   * @param limit 每頁條數
   * @param days 最近幾天的，沒有則是全部
   */
  getShareList(
    page: number,
    limit: number,
    days?: number,
    appKey?: string,
    projectKey?: string,
    type?: string,
    cardKey?: string,
  ) {
    return request.get(API_URL + '/user/shareToMe', {
      page,
      limit,
      days,
      appKey,
      projectKey,
      type,
      cardKey,
    });
  },
  /**
   * 分享出去的
   * @param page 頁碼
   * @param limit 每頁條數
   * @param days 最近幾天的，沒有則是全部
   */
  getShareOutList(
    page: number,
    limit: number,
    days?: number,
    appKey?: string,
    projectKey?: string,
    type?: string,
    cardKey?: string,
  ) {
    return request.get(API_URL + '/user/share', {
      page,
      limit,
      days,
      appKey,
      projectKey,
      type,
      cardKey,
    });
  },
  /**
   * 公開分享
   * @param nodeKey
   */
  publicShare(nodeKey: string) {
    return request.patch(API_URL + '/node/public', {
      nodeKey,
    });
  },
  publicShareTree(nodeKey: string, isPublic: boolean, allowEdit: boolean) {
    return request.patch(API_URL + '/node/public/new', {
      nodeKey,
      isPublic,
      allowEdit,
    });
  },
  /**
   * 判斷是否是公開分享
   * @param nodeKey
   */
  isPublicShare(nodeKey: string) {
    return request.get(API_URL + '/node/public', {
      nodeKey,
    });
  },
  /**
   * 獲取消息列表
   * @param page 頁碼
   * @param limit 每頁條數
   * @param unRead 是否显示未读总数
   * @param status 1:未读；2:已读
   */
  getNotificationList(page: number, limit: number, unRead?: boolean, status?: number) {
    return request.get(API_URL + '/user/message', {
      page,
      limit,
      unRead,
      status,
    });
  },
  /**
   * 消息已读
   * @param messageKey
   */
  readNotification(messageKey: string) {
    return request.patch(API_URL + '/message', {
      messageKey,
    });
  },
  clearReadMessage() {
    return request.delete(API_URL + '/message/read', {});
  },
  initApp(suffix?: string, noToken?: boolean) {
    return request.get(API_URL + '/appType/bySuffix', {
      suffix,
      noToken,
    });
  },
  /**
   * 獲取知識樹
   */
  getRepositoryTree(
    nodeKey?: string,
    showEditor?: boolean,
    fatherKey?: string,
    rootKey?: string,
    showDetail?: boolean,
    // 为真，文件夹返回page图标
    pageMode?: boolean,
  ) {
    return request.get(API_URL + '/node/tree', { nodeKey, showEditor, fatherKey, rootKey, showDetail, pageMode });
  },
  getFolderTree(nodeKey?: string, returnAll?: number, returnTaskTree?: number, showFolderIcon?: boolean) {
    return request.get(API_URL + '/node/tree/simple', { nodeKey, returnAll, returnTaskTree, showFolderIcon });
  },
  getChildNodes(nodeKey: string, page: number, limit: number, returnFolder?: boolean) {
    return request.get(API_URL + '/node/child/files', { nodeKey, page, limit, returnFolder });
  },
  // 轉換節點
  convertNode(
    nodeKey: string,
    type: string,
    appKey?: string,
    rootKey?: string,
    cardKey?: string,
    name?: string,
    url?: string,
    fileSize?: number,
    fileType?: string,
    linkType?: string,
    targetNodeKey?: string,
  ) {
    return request.patch(API_URL + '/node/type', {
      nodeKey,
      type,
      appKey,
      rootKey,
      cardKey,
      name,
      url,
      fileSize,
      fileType,
      linkType,
      linkNode: targetNodeKey,
    });
  },
  /**
   * 獲取節點日誌
   * @param page 頁碼
   * @param limit 每頁條數
   */
  getNodeLog(nodeKey: string, page: number, limit: number) {
    return request.get(API_URL + '/node/log', {
      nodeKey,
      page,
      limit,
    });
  },
  /**
   * 獲取用戶項目列表
   */
  getProjectList(filter?: 'create' | 'share') {
    return request.get(API_URL + '/user/project', { filter });
  },
  /**
   * 展開當前節點前的所有節點
   * @param nodeKey
   */
  checkContract(nodeKey: string) {
    return request.post(API_URL + '/node/checkContract', {
      nodeKey,
    });
  },
  exportTree(type: 'excel' | 'txt' | 'opml', nodeKey?: string) {
    return request.get(API_URL + '/node/export', { type, nodeKey });
  },
  getProjectTask(
    filterConfig: FilterConfig,
    tabType: 'execute' | 'assign',
    projectKey?: string,
    friendKey?: string,
    treeKey?: string,
    page?: number,
    limit?: number,
  ) {
    return request.get(API_URL + '/user/projectTask', {
      ...filterConfig,
      ...{ tabType },
      projectKey,
      friendKey,
      treeKey,
      page,
      limit,
    });
  },
  getProjectTaskTree(projectKey?: string) {
    return request.get(API_URL + '/user/projectTaskTree', {
      projectKey,
    });
  },
  getTaskList(
    filterConfig: FilterConfig,
    tabType: 'execute' | 'assign',
    friendKey?: string,
    page?: number,
    limit?: number,
  ) {
    return request.get(API_URL + '/task/list', {
      ...filterConfig,
      ...{ tabType },
      friendKey,
      page,
      limit,
      // asc/desc
      sortType: 'desc',
    });
  },
  getRecentTasks(page: number, limit: number, filterConfig?: FilterConfig) {
    return request.get(API_URL + '/user/task/create', {
      page,
      limit,
      ...filterConfig,
    });
  },
  getTaskFilterConfig() {
    return request.get(API_URL + '/user/fiterConfig');
  },
  getTaskDateList(userKey: string) {
    return request.get(API_URL + '/task/friend/summary', {
      friendKey: userKey,
    });
  },
  getUserTasksByDay(userKey: string, date: string) {
    return request.get(API_URL + '/task/friend/byDay', {
      friendKey: userKey,
      dateString: date,
    });
  },
  moveTaskTree(nodeKey: string, targetNodeKey: string, oldFather: string) {
    return request.patch(API_URL + '/node/moveTaskTree', {
      nodeKey,
      targetNodeKey,
      oldFather,
    });
  },
  // 獲取文檔列表
  getNodesInTime(startTime: number, endTime: number, star?: number, nodeKey?: string, appKey?: string, type?: string) {
    return request.get(API_URL + '/node/timeline', {
      begTime: startTime,
      endTime,
      star,
      nodeKey,
      appKey,
      type,
    });
  },
  getQuickAccess(page: number, limit: number) {
    return request.get(API_URL + '/quickAccess/list', {
      page,
      limit,
    });
  },
  addQuickAccess(nodeKey: string) {
    return request.post(API_URL + '/quickAccess', {
      nodeKey,
    });
  },
  removeQuickAccess(nodeKey: string) {
    return request.delete(API_URL + '/quickAccess', {
      nodeKey,
    });
  },
  clickNodeForSortTree(nodeKey: string) {
    return request.post(API_URL + '/node/click', {
      nodeKey,
    });
  },
  getRootKeyHistory() {
    return request.get(API_URL + '/user/clickList');
  },
  addTask(name: string, fathersObj: Object[]) {
    return request.post(API_URL + '/task/batch', {
      name,
      fathersObj,
    });
  },
  updateNodeAttach(nodeKey: string, attach: Object[]) {
    return request.patch(API_URL + '/node/attach', {
      nodeKey,
      attach,
    });
  },
  fileCompeletedTasks(treeKey: string) {
    return request.patch(API_URL + '/node/filed/batch', {
      treeKey,
    });
  },
  convertToChild(nodeKey: string, targetNodeKey: string, index?: number) {
    return request.patch(API_URL + '/node/father', {
      nodeKey,
      targetNodeKey,
      index,
    });
  },
  importFile(nodeKey: string, fatherId: string, fileArr: object[], showFolderIcon?: boolean) {
    return request.post(API_URL + '/node/upload/batch', {
      nodeKey,
      fatherId,
      fileArr,
      showFolderIcon,
    });
  },
  getBangumiList(userKey: string) {
    return request.get(API_URL + '/node/bangumi', {
      userKey,
    });
  },
  changeViewType(nodeKey: string, viewType: string) {
    return request.patch(API_URL + '/node/viewType', {
      nodeKey,
      viewType,
    });
  },
};

const share = {
  /**
   * 节点协作者列表
   * @param nodeKey
   */
  getShares(nodeKey: string) {
    return request.get(API_URL + '/node/share/people', { nodeKey });
  },
  share(nodeKey: string, toUser: string, role: 'admin' | 'edit' | 'author' | 'read') {
    return request.post(API_URL + '/node/share', {
      nodeKey,
      toUser,
      role,
    });
  },
  transferNode(nodeKey: string, toUser: string) {
    return request.patch(API_URL + '/node/transfer', {
      nodeKey,
      toUser,
    });
  },
  editShare(nodeKey: string, toUser: string, role: 'admin' | 'edit' | 'author' | 'read') {
    return request.patch(API_URL + '/node/share', {
      nodeKey,
      toUser,
      role,
    });
  },
  cancelShare(nodeKey: string, toUser: string) {
    return request.delete(API_URL + '/node/share/passive', {
      nodeKey,
      toUser,
    });
  },
  exitShare(nodeKey: string) {
    return request.delete(API_URL + '/node/share/active', {
      nodeKey,
    });
  },
};

const comment = {
  postComment(nodeKey: string, commentDetail: string, commentId?: string) {
    return request.post(API_URL + '/comment', {
      nodeKey,
      commentDetail,
      commentId,
    });
  },
  getComments(nodeKey: string, page: number, limit: number) {
    return request.get(API_URL + '/comment', {
      nodeKey,
      page,
      limit,
    });
  },
  deleteComment(commentId: string) {
    return request.delete(API_URL + '/comment', {
      commentId,
    });
  },
};

const card = {
  getCardList(name?: string) {
    return request.get(API_URL + '/card', { name });
  },
  getCardDetail(cardKey: string) {
    return request.get(API_URL + '/card/detail', { cardKey });
  },
  getCardUsers(userKeyArr: string[]) {
    return request.post(API_URL + '/user/byKeys', { userKeyArr });
  },
};

export default {
  auth,
  options,
  node,
  share,
  comment,
  card,
  setToken: (_token: string) => {
    localStorage.setItem('auth_token', _token);
    token = _token;
  },
};
