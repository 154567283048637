import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import { useTypedSelector } from '../../redux/reducer/RootState';
import { togglePublicShareModal, publicShare, getArticleById } from '../../redux/actions/articleActions';
import { setMessage } from '../../redux/actions/commonActions';
import { getNodeUrl, getSearchParamValue } from '../../util/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'unset',
      width: '500px',
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        width: '90vw',
      },
    },
    head: {
      display: 'flex',
    },
    headTitleWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    space: {
      flex: 1,
    },
    urlWrapper: {
      display: 'flex',
      marginTop: '14px',
    },
    qrcodeWrapper: {
      display: 'flex',
      marginTop: '32px',
      padding: '32px 0',
      borderTop: '1px solid #e9e9f5',
    },
    qrcodeWrapperTitle: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '32px',
    },
    track: {
      backgroundColor: '#b2b2b2',
    },
  }),
);

export default function PublicShareModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showPublicShareModal = useTypedSelector((state) => state.article.showPublicShareModal);
  const article = useTypedSelector((state) => state.article.article);
  const key = getSearchParamValue(window.location.search, 'key');
  const bookid = getSearchParamValue(window.location.search, 'bookid');
  const baseid = getSearchParamValue(window.location.search, 'baseid');
  const rootKey = useTypedSelector((state) => state.tree.rootKey);
  const bookisPublic = useTypedSelector((state) => state.tree.isPublic);
  const [publicUrl, setPublicUrl] = useState('');
  const [isPublic, setIsPublic] = useState<boolean | undefined>(false);

  const suffix = `&tag 《${article?.name || ''}》，分享自working.cn`;

  useEffect(() => {
    if (!bookid && !article && key && showPublicShareModal) {
      console.log('---publicShareModal：获取节点详情---');
      dispatch(getArticleById(key));
    }
  }, [dispatch, bookid, key, showPublicShareModal, article]);

  useEffect(() => {
    if (bookid && !baseid) {
      setIsPublic(bookisPublic);
      setPublicUrl(`${window.location.origin}/home/book/book?bookid=${rootKey}`);
    } else if (article) {
      const url = getNodeUrl(article);
      if (!url) return;
      if (article.suffix === 'book') {
        setIsPublic(bookisPublic);
      } else {
        setIsPublic(article.isPublic);
      }
      setPublicUrl(url);
    }
  }, [rootKey, article, bookisPublic, bookid, baseid]);

  function copyUrl() {
    copy(`${publicUrl}${suffix}`);
    dispatch(setMessage(true, '已经粘贴到剪切板！', 'success'));
    if (!isPublic) {
      handleChange();
    }
  }

  function handleChange() {
    if (bookid) {
      dispatch(publicShare(bookid, true));
    } else if (article) {
      dispatch(publicShare(article._key, false));
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={bookid ? showPublicShareModal : showPublicShareModal && article ? true : false}
      onClose={() => dispatch(togglePublicShareModal(false))}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showPublicShareModal}>
        <div className={classes.paper}>
          <div className={classes.head}>
            <IconButton color="inherit">{isPublic ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}</IconButton>
            <div className={classes.headTitleWrapper}>
              <span>{`公开分享（${isPublic ? '已分享' : '未分享'}）`}</span>
              <span>开启后获得链接的人都可以访问</span>
            </div>
            <div className={classes.space}></div>
            <Switch
              classes={{
                track: classes.track,
              }}
              checked={isPublic ? true : false}
              onChange={handleChange}
              color="primary"
            />
          </div>
          <div className={classes.urlWrapper}>
            <TextField
              label="分享链接"
              variant="outlined"
              disabled
              style={{ flex: 1, marginRight: '8px' }}
              value={publicUrl}
            />
            <Button variant="outlined" color="primary" onClick={copyUrl}>
              复制链接
            </Button>
          </div>
          <div className={classes.qrcodeWrapper}>
            <QRCode value={publicUrl} />
            <div className={classes.qrcodeWrapperTitle}>微信扫码分享</div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
