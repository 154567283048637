import { FilterConfig } from '../../interfaces/ProjectTask';

export const actionTypes = {
  TOGGLE_COLLAB_PANEL: 'TOGGLE_COLLAB_PANEL',
  GET_PROJECT_TASKS: 'GET_PROJECT_TASKS',
  GET_PROJECT_TASKS_SUCCEEDED: 'GET_PROJECT_TASKS_SUCCEEDED',
  UPDATE_TASK: 'UPDATE_TASK',
  UPDATE_TASK_SUCCEEDED: 'UPDATE_TASK_SUCCEEDED',
  GET_PROJECT_TASK_TREE: 'GET_PROJECT_TASK_TREE',
  GET_PROJECT_TASK_TREE_SUCCEEDED: 'GET_PROJECT_TASK_TREE_SUCCEEDED',
  CREATE_TASK: 'CREATE_TASK',
  CREATE_TASK_SUCCEEDED: 'CREATE_TASK_SUCCEEDED',
  GET_RECENT_TASKS: 'GET_RECENT_TASKS',
  GET_RECENT_TASKS_SUCCEEDED: 'GET_RECENT_TASKS_SUCCEEDED',
  CHANGE_FILTER: 'CHANGE_FILTER',
  GET_FILTER_CONFIG: 'GET_FILTER_CONFIG',
  GET_FILTER_CONFIG_SUCCEEDED: 'GET_FILTER_CONFIG_SUCCEEDED',
  CLEAR_TASKS: 'CLEAR_TASKS',
  GET_DATE_LIST: 'GET_DATE_LIST',
  GET_DATE_LIST_SUCCEEDED: 'GET_DATE_LIST_SUCCEEDED',
  GET_USER_TASKS: 'GET_USER_TASKS',
  GET_USER_TASKS_SUCCEEDED: 'GET_USER_TASKS_SUCCEEDED',
  GET_TASK_LIST: 'GET_TASK_LIST',
  GET_TASK_LIST_SUCCEEDED: 'GET_TASK_LIST_SUCCEEDED',
  FILE_COMPELETED_TASKS: 'FILE_COMPELETED_TASKS',
  FILE_COMPELETED_TASKS_SUCCEEDED: 'FILE_COMPELETED_TASKS_SUCCEEDED',
  CHANGE_GANTT_ZOOM: 'CHANGE_GANTT_ZOOM',
};

export function toggleCollabPanel(visible?: boolean) {
  return { type: actionTypes.TOGGLE_COLLAB_PANEL, visible };
}

export function getProjectTasks(
  filterConfig: FilterConfig,
  tabType?: 'execute' | 'assign',
  projectKey?: string,
  friendKey?: string,
) {
  return {
    type: actionTypes.GET_PROJECT_TASKS,
    filterConfig,
    tabType,
    projectKey,
    friendKey,
  };
}

export function getProjectTasksSuccess(data: any) {
  return {
    type: actionTypes.GET_PROJECT_TASKS_SUCCEEDED,
    data,
  };
}

export function getProjectTaskTreeList(projectKey?: string) {
  return {
    type: actionTypes.GET_PROJECT_TASK_TREE,
    projectKey,
  };
}

export function getProjectTaskTreeListSuccess(data: any) {
  return {
    type: actionTypes.GET_PROJECT_TASK_TREE_SUCCEEDED,
    data,
  };
}

export function updateTask(
  treeKey: string,
  nodeKey: string,
  executor?: string | null,
  hour?: number,
  limitDay?: number,
  check?: boolean,
  name?: string,
  filed?: boolean,
) {
  return {
    type: actionTypes.UPDATE_TASK,
    treeKey,
    nodeKey,
    executor,
    hour,
    limitDay,
    check,
    name,
    filed,
  };
}
export function updateTaskSuccess(treeKey: string, nodeKey: string, data: any, check?: boolean) {
  return {
    type: actionTypes.UPDATE_TASK_SUCCEEDED,
    treeKey,
    nodeKey,
    data,
    check,
  };
}

export function addTask(name: string, fathersObj: Object[]) {
  return {
    type: actionTypes.CREATE_TASK,
    name,
    fathersObj,
  };
}
export function addTaskSuccess(treeKey: string, data: any) {
  return {
    type: actionTypes.CREATE_TASK_SUCCEEDED,
    treeKey,
    data,
  };
}

export function getRecentTasks(currentPage: number, perPage: number, filterConfig?: FilterConfig) {
  return {
    type: actionTypes.GET_RECENT_TASKS,
    currentPage,
    perPage,
    filterConfig,
  };
}

export function getRecentTasksSuccess(currentPage: number, data: any) {
  return {
    type: actionTypes.GET_RECENT_TASKS_SUCCEEDED,
    currentPage,
    data,
  };
}

export function changeFilter(filterConfig: FilterConfig) {
  return {
    type: actionTypes.CHANGE_FILTER,
    filterConfig,
  };
}

export function getFilterConfig(userKey?: string) {
  return {
    type: actionTypes.GET_FILTER_CONFIG,
    userKey,
  };
}

export function getFilterConfigSuccess(data: any, userKey?: string) {
  return {
    type: actionTypes.GET_FILTER_CONFIG_SUCCEEDED,
    data,
    userKey,
  };
}

export function clearTasks() {
  return {
    type: actionTypes.CLEAR_TASKS,
  };
}

export function getDateList(userKey: string) {
  return {
    type: actionTypes.GET_DATE_LIST,
    userKey,
  };
}

export function getDateListSuccess(data: any) {
  return {
    type: actionTypes.GET_DATE_LIST_SUCCEEDED,
    data,
  };
}

export function getUserTasks(userKey: string, date: string) {
  return {
    type: actionTypes.GET_USER_TASKS,
    userKey,
    date,
  };
}

export function getUserTasksSuccess(data: any) {
  return {
    type: actionTypes.GET_USER_TASKS_SUCCEEDED,
    data,
  };
}

export function fileCompeletedTasks(treeKey: string) {
  return {
    type: actionTypes.FILE_COMPELETED_TASKS,
    treeKey,
  };
}

export function fileCompeletedTasksSuccess(data: any) {
  return {
    type: actionTypes.FILE_COMPELETED_TASKS_SUCCEEDED,
    data,
  };
}

export function getTaskList(
  currentPage: number,
  perPage: number,
  filterConfig: FilterConfig,
  tabType?: 'execute' | 'assign',
  friendKey?: string,
) {
  return {
    type: actionTypes.GET_TASK_LIST,
    currentPage,
    perPage,
    filterConfig,
    tabType,
    friendKey,
  };
}

export function getTaskListSuccess(data: any) {
  return {
    type: actionTypes.GET_TASK_LIST_SUCCEEDED,
    data,
  };
}

export function changeGanttZoom(zoom: 'Hours' | 'Days' | 'Months') {
  return {
    type: actionTypes.CHANGE_GANTT_ZOOM,
    zoom,
  };
}
