import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useTypedSelector } from '../../redux/reducer/RootState';

export default function SoundEffect() {
  const soundEffect = useTypedSelector((state) => state.common.soundEffect);
  const compeleteAudio: any = useRef<any>(null);
  const cancelAudio: any = useRef<any>(null);
  const createAudio: any = useRef<any>(null);
  const buttonClick: any = useRef<any>(null);
  const systemInit: any = useRef<any>(null);
  const loginSuccess: any = useRef<any>(null);
  const receiveTask: any = useRef<any>(null);
  const notification: any = useRef<any>(null);
  const archive: any = useRef<any>(null);
  const pack: any = useRef<any>(null);
  const createTask: any = useRef<any>(null);

  useEffect(() => {
    if (soundEffect.play && soundEffect.name) {
      switch (soundEffect.name) {
        case 'compelete':
          compeleteAudio.current.play();
          break;
        case 'create':
          createAudio.current.play();
          break;
        case 'cancel':
          cancelAudio.current.play();
          break;
        case 'buttonClick':
          buttonClick.current.play();
          break;
        case 'systemInit':
          systemInit.current.play();
          break;
        case 'loginSuccess':
          loginSuccess.current.play();
          break;
        case 'receiveTask':
          receiveTask.current.play();
          break;
        case 'notification':
          notification.current.play();
          break;
        case 'archive':
          archive.current.play();
          break;
        case 'pack':
          pack.current.play();
          break;
        case 'createTask':
          createTask.current.play();
          break;
        default:
          break;
      }
    }
  }, [soundEffect]);

  return (
    <div>
      <audio src="/soundEffect/coin.mp3" controls hidden ref={compeleteAudio} />
      <audio src="https://cdn-icare.qingtime.cn/1606524071707_workingVip" controls hidden ref={cancelAudio} />
      <audio src="https://cdn-icare.qingtime.cn/1607480783765_workingVip" controls hidden ref={createAudio} />
      <audio src="/soundEffect/button-click.mp3" controls hidden ref={buttonClick} />
      <audio src="/soundEffect/system-init.mp3" controls hidden ref={systemInit} />
      <audio src="/soundEffect/create-task.mp3" controls hidden ref={loginSuccess} />
      <audio src="/soundEffect/receiveTask.mp3" controls hidden ref={receiveTask} />
      <audio src="/soundEffect/notification.mp3" controls hidden ref={notification} />
      <audio src="/soundEffect/archive.mp3" controls hidden ref={archive} />
      <audio src="/soundEffect/pack.mp3" controls hidden ref={pack} />
      <audio src="/soundEffect/create-task.mp3" controls hidden ref={createTask} />
    </div>
  );
}
