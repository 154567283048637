import { call, put, takeLatest } from 'redux-saga/effects';
import {
  actionTypes,
  getNodePathSuccess,
  getRepositoryTreeSuccess,
  getOtherTreeSuccess,
  renameNodeSuccess,
  changeSortSuccess,
  createNodeSuccess,
  expandNodeSuccess,
  archiveSuccess,
  favSuccess,
  dragSuccess,
  dragBatchSuccess,
  pasteSuccess,
  convertNodeSuccess,
  changeIconSuccess,
  clickMenuNode,
  changeIcon,
  getLogListSuccess,
  revertIconSuccess,
  expandBookSuccess,
  importNodeSuccess,
  updateTaskNodeSuccess,
  getProjectListSuccess,
  addProjectSuccess,
  deleteProjectSuccess,
  expotTreeSuccess,
  getFolderTreeSuccess,
  deleteCommentsSuccess,
  postCommentSuccess,
  getCommentsSuccess,
  moveTaskTreeSuccess,
  getRootKeyHistorySuccess,
  updateNodeAttachSuccess,
  getCardListSuccess,
  getCardDetailSuccess,
  updateCardSuccess,
  clearCardDetail,
  addNoteSuccess,
  updateNoteSuccess,
  convertToChildSuccess,
  importFilesSuccess,
  changeCoverSuccess,
} from '../actions/treeActions';
import { Failed, setMessage } from '../actions/commonActions';
import api from '../../util/api';
import { uploadImg, uploadImgs } from '../../util/uploadImage';
import ResponseGenerator from '../../interfaces/ResponseGenerator';
import ResponseGenerator2 from '../../interfaces/ResponseGenerator2';

function* getNodePath(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getNodePath,
      action.nodeKey,
      action.startNodeKey,
      action.includeStartNodeKey,
    );
    if (res.status === 200) {
      yield put(getNodePathSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getRepositoryTree(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getRepositoryTree,
      action.nodeKey,
      action.showEditor,
      action.fatherKey,
      action.rootKey,
      action.showDetail,
      action.pageMode,
    );
    if (res.status === 200) {
      yield put(getRepositoryTreeSuccess(res, action.isMenuTree, action.partialLoading));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getFolderTree(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getFolderTree,
      action.nodeKey,
      action.returnAll,
      action.returnTaskTree,
      action.getFolderTree,
    );
    if (res.status === 200) {
      yield put(getFolderTreeSuccess(res, action.treeType));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getOtherTree(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getRepositoryTree, action.nodeKey);
    if (res.status === 200) {
      yield put(getOtherTreeSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* expandBook(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getRepositoryTree, action.nodeKey, undefined, action.fatherKey);
    if (res.status === 200) {
      yield put(expandBookSuccess(action.nodeKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* renameNode(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      action.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      action.rootKey,
    );
    if (res.status === 200) {
      yield put(renameNodeSuccess(action.nodeKey, action.name, action.noMessage));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* changeSort(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      action.sortList,
    );
    if (res.status === 200) {
      yield put(changeSortSuccess(action.nodeKey, action.sortList, action.toSelectKey));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* createNode(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.addTreeNode,
      action.nodeKey,
      action.fatherKey,
      action.addType,
      action.nodeType,
      action.appKey,
      action.rootKey,
      action.docType,
      action.name,
      action.cardKey,
      action.nodeInfoObj,
      action.url,
      action.fileSize,
      action.fileType,
      action.linkType,
      action.targetNodeKey,
    );
    if (res.status === 200) {
      yield put(
        createNodeSuccess(
          action.nodeKey,
          action.addType,
          res,
          action.nodeInfoObj,
          action.inTiptap,
          action.isProjectTree,
        ),
      );
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* expand(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.expand, action.nodeKey, action.fatherId, action.rootKey);
    if (res.status === 200) {
      yield put(expandNodeSuccess(action.nodeKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* archive(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.archive, action.nodeKey, action.isTaskTree);
    if (res.status === 200) {
      yield put(archiveSuccess(action.nodeKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* fav(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.fav, action.nodeKey, action.viewType);
    if (res.status === 200) {
      yield put(favSuccess(action.nodeKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* drag(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.drag,
      action.nodeKey,
      action.targetNodeKey,
      action.placement,
      action.oldFather,
    );
    if (res.status === 200) {
      yield put(dragSuccess(action.nodeKey, action.targetNodeKey, action.placement, res, action.oldFather));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* dragBatch(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.dragBatch,
      action.dragNodeArr,
      action.targetNodeKey,
      action.placement,
    );
    if (res.status === 200) {
      yield put(dragBatchSuccess(action.dragNodeArr, action.targetNodeKey, action.placement));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getShotcutDetail(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getNodeByKey, action.nodeKey);
    if (res.status === 200) {
      yield put(clickMenuNode(res.data));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* paste(action: any) {
  try {
    if (action.pasteType === 'copy') {
      const res: ResponseGenerator = yield call(api.node.copy, action.pasteNodeKey, action.selectedId, action.rootKey);
      if (res && res.status && res.status === 200) {
        yield put(pasteSuccess(action.pasteNodeKey, action.pasteType, action.selectedId, res));
      } else {
        yield put(Failed(res));
      }
    } else {
      const res: ResponseGenerator = yield call(
        api.node.cut,
        [action.pasteNodeKey],
        action.selectedId,
        action.oldFather,
      );
      if (res && res.status && res.status === 200) {
        yield put(pasteSuccess(action.pasteNodeKey, action.pasteType, action.selectedId, res));
      } else {
        yield put(Failed(res));
      }
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* convert(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.convertNode,
      action.nodeKey,
      action.convertType,
      action.appKey,
      action.rootKey,
      action.cardKey,
      action.name,
      action.url,
      action.fileSize,
      action.fileType,
      action.linkType,
      action.targetNodeKey,
    );
    if (res.status === 200) {
      yield put(convertNodeSuccess(action.nodeKey, res, action.nodeInfoObj, action.name));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* uploadIcon(action: any) {
  try {
    // 獲取七牛token
    let qiniuToken;
    const qiniuRes: ResponseGenerator2 = yield call(api.auth.getUptoken);
    if (qiniuRes.statusCode === '200') {
      qiniuToken = qiniuRes.result;
      // 上傳
      const url: string = yield uploadImg(qiniuToken, action.file, false);
      if (url) {
        yield put(changeIcon(action.nodeKey, url));
      } else {
        yield put(Failed('上传失败！'));
      }
    } else {
      yield put(Failed(qiniuRes));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* changeicon(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      action.icon,
    );
    if (res.status === 200) {
      yield put(changeIconSuccess(action.nodeKey, action.icon));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* changeCover(action: any) {
  try {
    let cover = action.cover;
    if (!cover) {
      let unsplashRes: { urls: any } = yield call(api.auth.getRandomUnsplashImage);
      if (unsplashRes && unsplashRes.urls) {
        cover = unsplashRes.urls.regular;
      } else {
        return put(Failed(unsplashRes));
      }
    }
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      cover,
    );
    if (res.status === 200) {
      yield put(changeCoverSuccess(action.nodeKey, cover));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getLogList(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getNodeLog, action.nodeKey, action.page, action.limit);
    if (res.status === 200) {
      yield put(getLogListSuccess(action.page === 1 ? false : true, res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* revertNode(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.revertNodeIcon, action.nodeKey, action.isTaskTree);
    if (res.status === 200) {
      yield put(revertIconSuccess(action.nodeKey, res.data.icon));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* importNode(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.importNode,
      action.nodeKey,
      action.text,
      action.fatherId,
      action.rootKey,
    );
    if (res.status === 200) {
      yield put(importNodeSuccess(action.nodeKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* updateTask(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateTask,
      action.nodeKeyArr,
      action.executor,
      action.hour,
      action.limitDay,
      action.check,
      action.filed,
      action.star,
    );
    if (res.status === 200) {
      yield put(updateTaskNodeSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getProjectList(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getProjectList, action.filter);
    if (res.status === 200) {
      yield put(getProjectListSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* addProject(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.createNode, action.name, 'project', undefined);
    if (res.status === 200) {
      yield put(addProjectSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* deleteProject(action: any) {
  try {
    let res: ResponseGenerator;
    if (action.exitShare) {
      res = yield call(api.node.exitShare, action.nodeKey);
    } else {
      res = yield call(api.node.updateNode, action.nodeKey, undefined, undefined, undefined, 0);
    }

    if (res.status === 200) {
      yield put(deleteProjectSuccess(action.nodeKey, action.exitShare));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* exportTree(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.exportTree, action.exportType, action.nodeKey);
    if (res.status === 200) {
      yield put(expotTreeSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getComments(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.comment.getComments, action.nodeKey, action.page, action.limit);
    if (res.status === 200) {
      yield put(getCommentsSuccess(action.page, res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}
function* postComment(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.comment.postComment,
      action.nodeKey,
      action.commentDetail,
      action.commentId,
    );
    if (res.status === 200) {
      yield put(postCommentSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* moveTaskTree(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.moveTaskTree,
      action.nodeKey,
      action.targetNodeKey,
      action.oldFather,
    );
    if (res.status === 200) {
      yield put(moveTaskTreeSuccess(action.nodeKey, action.targetNodeKey));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* deleteComment(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.comment.deleteComment, action.commentId);
    if (res.status === 200) {
      yield put(deleteCommentsSuccess(action.commentId));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* clickForSort(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.clickNodeForSortTree, action.nodeKey);
    if (res.status === 200) {
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getRootKeyHistory() {
  try {
    const res: ResponseGenerator = yield call(api.node.getRootKeyHistory);
    if (res.status === 200) {
      yield put(getRootKeyHistorySuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* updateNodeAttach(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.updateNodeAttach, action.nodeKey, action.attach);
    if (res.status === 200) {
      yield put(updateNodeAttachSuccess(action.nodeKey, action.attach));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getCardList(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.card.getCardList, action.name);
    if (res.status === 200) {
      yield put(getCardListSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getCardDetail(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.card.getCardDetail, action.cardKey);
    if (res.status === 200) {
      yield put(getCardDetailSuccess(res));
    } else {
      yield put(clearCardDetail());
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* updateCard(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      action.nodeInfoObj,
    );
    if (res.status === 200) {
      yield put(updateCardSuccess(res));
      yield put(setMessage(true, '保存成功！', 'success'));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* addNote(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      '',
    );
    if (res.status === 200) {
      yield put(addNoteSuccess(action.nodeKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* updateNote(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      action.note,
    );
    if (res.status === 200) {
      yield put(updateNoteSuccess(action.nodeKey, action.note));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* convertToChild(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.convertToChild,
      action.nodeKey,
      action.targetNodeKey,
      action.targetPlaceIndex,
    );
    if (res.status === 200) {
      yield put(convertToChildSuccess(action.nodeKey, action.targetNodeKey, action.targetPlaceIndex));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* importFiles(action: any) {
  try {
    // 獲取七牛token
    let qiniuToken;
    const qiniuRes: ResponseGenerator2 = yield call(api.auth.getUptoken);
    if (qiniuRes.statusCode === '200') {
      qiniuToken = qiniuRes.result;
      const files: FileList = action.files;
      const uploadRes: { url: string; fileSize: number; fileType: string; name: string }[] = yield call(
        uploadImgs,
        qiniuToken,
        files,
        false,
        true,
      );
      if (uploadRes && uploadRes.length) {
        const res: ResponseGenerator = yield call(api.node.importFile, action.nodeKey, action.fatherId, uploadRes);
        if (res.status === 200) {
          yield put(importFilesSuccess(action.nodeKey, res));
        } else {
          yield put(Failed(res));
        }
      }
    } else {
      yield put(Failed(qiniuRes));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* changeTreeView(action: any) {
  try {
    let res: ResponseGenerator;
    if (!action.noSave) {
      res = yield call(api.node.changeViewType, action.nodeKey, action.viewType);
      if (res.status === 200) {
      } else {
        yield put(Failed(res));
      }
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

const treeSagas = [
  takeLatest(actionTypes.GET_NODE_PATH, getNodePath),
  takeLatest(actionTypes.GET_REPOSITORY_TREE, getRepositoryTree),
  takeLatest(actionTypes.GET_FOLDER_TREE, getFolderTree),
  takeLatest(actionTypes.GET_OTHER_TREE, getOtherTree),
  takeLatest(actionTypes.RENAME_NODE, renameNode),
  takeLatest(actionTypes.CHANGE_SORT, changeSort),
  takeLatest(actionTypes.CREATE_NODE, createNode),
  takeLatest(actionTypes.EXPAND_NODE, expand),
  takeLatest(actionTypes.ARCHIVE, archive),
  takeLatest(actionTypes.FAV, fav),
  takeLatest(actionTypes.DRAG, drag),
  takeLatest(actionTypes.DRAG_BATCH, dragBatch),
  takeLatest(actionTypes.PASTE, paste),
  takeLatest(actionTypes.CLICK_SHOTCUT, getShotcutDetail),
  takeLatest(actionTypes.CONVERT_NODE, convert),
  takeLatest(actionTypes.UPLOAD_ICON, uploadIcon),
  takeLatest(actionTypes.CHANGE_ICON, changeicon),
  takeLatest(actionTypes.GET_LOG_LIST, getLogList),
  takeLatest(actionTypes.REVERT_ICON, revertNode),
  takeLatest(actionTypes.EXPAND_BOOK, expandBook),
  takeLatest(actionTypes.IMPORT, importNode),
  takeLatest(actionTypes.UPDATE_TASK_NODE, updateTask),
  takeLatest(actionTypes.GET_PROJECT_LIST, getProjectList),
  takeLatest(actionTypes.ADD_PROJECT, addProject),
  takeLatest(actionTypes.DELETE_PROJECT, deleteProject),
  takeLatest(actionTypes.EXPORT_TREE, exportTree),
  takeLatest(actionTypes.GET_COMMENTS, getComments),
  takeLatest(actionTypes.DELETE_COMMENT, deleteComment),
  takeLatest(actionTypes.POST_COMMENT, postComment),
  takeLatest(actionTypes.MOVE_TASK_TREE, moveTaskTree),
  takeLatest(actionTypes.CLICK_FOR_SORT, clickForSort),
  takeLatest(actionTypes.GET_ROOT_KEY_HISTORY, getRootKeyHistory),
  takeLatest(actionTypes.UPDATE_NODE_ATTACH, updateNodeAttach),
  takeLatest(actionTypes.GET_CARD_LIST, getCardList),
  takeLatest(actionTypes.GET_CARD_DETAIL, getCardDetail),
  takeLatest(actionTypes.UPDATE_CARD, updateCard),
  takeLatest(actionTypes.ADD_NOTE, addNote),
  takeLatest(actionTypes.UPDATE_NOTE, updateNote),
  takeLatest(actionTypes.CONVERT_TO_CHILD, convertToChild),
  takeLatest(actionTypes.IMPORT_FILES, importFiles),
  takeLatest(actionTypes.CHANGE_TREE_VIEW, changeTreeView),
  takeLatest(actionTypes.CHANGE_COVER, changeCover),
];
export default treeSagas;
