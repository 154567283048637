import React from 'react';
import Loadable from 'react-loadable';
import Loading from './components/common/Loading';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

export default function Pages() {
  const Welcome = Loadable({
    loader: () => import('./pages/welcome'),
    loading: () => <Loading />,
  });

  const Login = Loadable({
    loader: () => import('./pages/Login'),
    loading: () => <Loading />,
  });

  const App = Loadable({
    loader: () => import('./pages/Home'),
    loading: () => <Loading />,
  });

  const NotFound = Loadable({
    loader: () => import('./pages/NotFound'),
    loading: () => <Loading />,
  });

  const TimeBoxLogin = Loadable({
    loader: () => import('./pages/TimeBoxLogin'),
    loading: () => <Loading />,
  });

  const TimeBoxScanSuccess = Loadable({
    loader: () => import('./pages/TimeBoxScanSuccess'),
    loading: () => <Loading />,
  });

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/welcome" push />} />
        <Route path="/home/:doctype" component={App} />
        <Route path="/login" component={Login} />
        <Route path="/welcome" component={Welcome} />
        <Route path="/404" component={NotFound} />
        <Route path="/box-login" component={TimeBoxLogin} />
        <Route path="/scan-success" component={TimeBoxScanSuccess} />
      </Switch>
    </Router>
  );
}
