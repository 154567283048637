import { actionTypes } from '../actions/taskActions';
import { actionTypes as articleActionTypes } from '../actions/articleActions';
import { actionTypes as treeActionTypes } from '../actions/treeActions';
import { TaskTree, Task, ProjectTreeListItem, FilterConfig, GanttTask } from '../../interfaces/ProjectTask';
import TeammateSummaryByDate from '../../interfaces/TeammateSummaryByDate';
import moment from 'moment';

export interface TaskType {
  collabVisible: boolean;
  taskTreeList: TaskTree[];
  taskList: Task[];
  ganttTaskList: GanttTask[];
  projectTaskTreeList: ProjectTreeListItem[];
  latelyTreeId: string | null;
  latelyFatherId: string | null;
  recentTaskList: Task[];
  filterConfig: FilterConfig | null;
  dateList: TeammateSummaryByDate[];
  total: number;
  ganttZoom: 'Hours' | 'Days' | 'Months';
}

const defaultState: TaskType = {
  collabVisible: false,
  taskTreeList: [],
  taskList: [],
  ganttTaskList: [],
  projectTaskTreeList: [],
  latelyTreeId: null,
  latelyFatherId: null,
  recentTaskList: [],
  filterConfig: null,
  dateList: [],
  total: 0,
  ganttZoom: 'Days',
};

export const task = (state = defaultState, action: any) => {
  switch (action.type) {
    case actionTypes.TOGGLE_COLLAB_PANEL:
      return {
        ...state,
        collabVisible: action.visible === undefined ? !state.collabVisible : action.visible,
      };
    case actionTypes.GET_PROJECT_TASKS_SUCCEEDED: {
      const taskTreeList: TaskTree[] = action.data.data.taskTree;
      taskTreeList.sort((a, b) => b.taskNum - a.taskNum);
      return {
        ...state,
        taskTreeList,
        taskList: action.data.data.task,
        // total: action.data.total,
      };
    }
    case actionTypes.UPDATE_TASK_SUCCEEDED: {
      let taskList = [...state.taskList];
      let recentTaskList = [...state.recentTaskList];
      let taskTreeList = [...state.taskTreeList];
      // let ganttTaskList = [...state.ganttTaskList];
      const task = action.data.data;

      let index = taskList.findIndex((task) => task._key === action.nodeKey);
      taskList[index] = { ...taskList[index], ...task, ...{ name: task.name } };

      let recentIndex = recentTaskList.findIndex((task) => task._key === action.nodeKey);
      recentTaskList[recentIndex] = { ...recentTaskList[recentIndex], ...task };

      // let ganttIndex = ganttTaskList.findIndex((task) => task._key === action.nodeKey);
      // ganttTaskList[ganttIndex] = { ...ganttTaskList[index], ...task, ...{ name: task.name } };

      if (action.check !== undefined) {
        let treeIndex = taskTreeList.findIndex((taskTree) => taskTree.treeKey === action.treeKey);
        const tree = taskTreeList[treeIndex];
        if (tree) {
          if (action.check) {
            tree.checkedNum += 1;
          } else {
            tree.checkedNum -= 1;
          }
        }
      }

      return {
        ...state,
        taskList,
        recentTaskList,
        // ganttTaskList,
        taskTreeList,
      };
    }
    case actionTypes.CREATE_TASK_SUCCEEDED: {
      let taskList = [...action.data.data, ...state.taskList];
      let recentTaskList = [...action.data.data, ...state.recentTaskList];

      return {
        ...state,
        taskList,
        recentTaskList,
      };
    }
    case actionTypes.GET_PROJECT_TASK_TREE_SUCCEEDED: {
      return {
        ...state,
        projectTaskTreeList: action.data.data.treeList,
        latelyTreeId: action.data.data.latelyTreeId,
        latelyFatherId: action.data.data.latelyFatherId,
      };
    }
    case actionTypes.GET_RECENT_TASKS_SUCCEEDED: {
      return {
        ...state,
        recentTaskList: action.currentPage === 1 ? action.data.data : [...state.recentTaskList, ...action.data.data],
        total: action.data.total,
      };
    }
    case actionTypes.CHANGE_FILTER: {
      return {
        ...state,
        filterConfig: action.filterConfig,
      };
    }
    case actionTypes.GET_FILTER_CONFIG_SUCCEEDED: {
      return {
        ...state,
        filterConfig: action.userKey ? { ...action.data.data, filterExecutor: action.userKey } : action.data.data,
      };
    }
    case actionTypes.CLEAR_TASKS: {
      return {
        ...state,
        collabVisible: false,
        taskTreeList: [],
        taskList: [],
        ganttTaskList: [],
        projectTaskTreeList: [],
        latelyTreeId: null,
        latelyFatherId: null,
        recentTaskList: [],
        filterConfig: null,
        total: 0,
      };
    }
    case actionTypes.GET_DATE_LIST_SUCCEEDED: {
      return {
        ...state,
        dateList: action.data.data,
      };
    }
    case actionTypes.GET_USER_TASKS_SUCCEEDED: {
      return {
        ...state,
        recentTaskList: action.data.data.todayCreate,
        taskList: action.data.data.todayPlan,
      };
    }
    case articleActionTypes.EDIT_ARTICLE_SUCCEEDED: {
      let taskList = [...state.taskList];
      let recentTaskList = [...state.recentTaskList];
      const index = taskList.findIndex((item) => item._key === action.nodeKey);
      const recentIndex = recentTaskList.findIndex((item) => item._key === action.nodeKey);
      if (index === -1 && recentIndex === -1) {
        return state;
      }
      if (index !== -1) {
        taskList[index] = {
          ...taskList[index],
          ...{
            star: action.star || taskList[index].star,
            customTime: action.customTime || taskList[index].customTime,
          },
        };
      }
      if (recentIndex !== -1) {
        recentTaskList[recentIndex] = {
          ...recentTaskList[recentIndex],
          ...{
            star: action.star || recentTaskList[recentIndex].star,
            customTime: action.customTime || recentTaskList[recentIndex].customTime,
          },
        };
      }
      return {
        ...state,
        taskList,
        recentTaskList,
      };
    }
    case treeActionTypes.FAV_SUCCEEDED: {
      let taskList = [...state.taskList];
      let recentTaskList = [...state.recentTaskList];
      const index = taskList.findIndex((item) => item._key === action.nodeKey);
      const recentIndex = recentTaskList.findIndex((item) => item._key === action.nodeKey);
      if (index === -1 && recentIndex === -1) {
        return state;
      }
      if (index !== -1) {
        taskList[index].hasCollect = !taskList[index].hasCollect;
      }
      if (recentIndex !== -1) {
        recentTaskList[recentIndex].hasCollect = !recentTaskList[recentIndex].hasCollect;
      }
      return {
        ...state,
        taskList,
        recentTaskList,
      };
    }
    case actionTypes.FILE_COMPELETED_TASKS_SUCCEEDED: {
      let taskList = [...state.taskList];
      const keys: string[] = action.data.data;
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        let i = taskList.findIndex((task) => task._key === key);
        if (i !== -1) {
          taskList[i].filed = true;
        }
      }

      return {
        ...state,
        taskList,
      };
    }
    case actionTypes.GET_TASK_LIST_SUCCEEDED: {
      const taskList: Task[] = action.data.data;
      const ganttTaskList: GanttTask[] = [];
      for (let index = 0; index < taskList.length; index++) {
        const task = taskList[index];
        if (task) {
          ganttTaskList.push({
            ...task,
            ...{
              text: '',
              start_date: moment(task.limitDay).subtract(task.hour, 'hours').format('YYYY-MM-DD HH:MM'),
              // 如果是日视图或者是月视图，duration为一天或者一个月
              duration:
                state.ganttZoom === 'Days'
                  ? 24
                  : state.ganttZoom === 'Months'
                  ? 24 * 30
                  : task.hour && task.hour >= 1
                  ? task.hour
                  : 1,
              progress: 0,
              color: '#a5a9dd',
            },
          });
        }
      }
      return {
        ...state,
        taskList,
        ganttTaskList,
        total: action.data.totalNum,
      };
    }
    case actionTypes.CHANGE_GANTT_ZOOM: {
      const taskList = [...state.taskList];
      const ganttTaskList: GanttTask[] = [];
      for (let index = 0; index < taskList.length; index++) {
        const task = taskList[index];
        if (task) {
          ganttTaskList.push({
            ...task,
            ...{
              text: '',
              start_date: moment(task.limitDay).subtract(task.hour, 'hours').format('YYYY-MM-DD HH:MM'),
              // 如果是日视图或者是月视图，duration为一天或者一个月
              duration:
                action.zoom === 'Days'
                  ? 24
                  : action.zoom === 'Months'
                  ? 24 * 30
                  : task.hour && task.hour >= 1
                  ? task.hour
                  : 1,
              progress: 0,
            },
          });
        }
      }

      return {
        ...state,
        ganttZoom: action.zoom,
        ganttTaskList,
      };
    }
    case treeActionTypes.CREATE_NODE_SUCCEEDED: {
      const addedNode = { ...action.data.data, ...action.nodeInfoObj };
      if (addedNode.suffix === 'task') {
        const projectTaskTreeList = [...state.projectTaskTreeList];
        projectTaskTreeList.push(addedNode);
        return {
          ...state,
          projectTaskTreeList,
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
