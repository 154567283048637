import AppType from '../../interfaces/AppType';
import User from '../../interfaces/User';

export const actionTypes = {
  FAILED: 'FAILED',
  SWITCH_APPS: 'SWITCH_APPS',
  SWITCH_NOTIFICATION: 'SWITCH_NOTIFICATION',
  SWITCH_SEARCH: 'SWITCH_SEARCH',
  SET_MESSAGE: 'SET_MESSAGE',
  PLAY_SOUND_EFFECT: 'PLAY_SOUND_EFFECT',
  GET_SHARED_PEOPLE: 'GET_SHARED_PEOPLE',
  GET_SHARED_PEOPLE_SUCCEEDED: 'GET_SHARED_PEOPLE_SUCCEEDED',
  SHARE: 'SHARE',
  SHARE_SUCCEEDED: 'SHARE_SUCCEEDED',
  SEARCH_USER: 'SEARCH_USER',
  SEARCH_USER_SUCCEEDED: 'SEARCH_USER_SUCCEEDED',
  SEARCH_USER_LOCAL: 'SEARCH_USER_LOCAL',
  SEARCH_USER_LOCAL_SUCCEEDED: 'SEARCH_USER_LOCAL_SUCCEEDED',
  GET_RECENT_COLLAB: 'GET_RECENT_COLLAB',
  GET_RECENT_COLLAB_SUCCEEDED: 'GET_RECENT_COLLAB_SUCCEEDED',
  DELETE_SHARE: 'DELETE_SHARE',
  DELETE_SHARE_SUCCEEDED: 'DELETE_SHARE_SUCCEEDED',
  EDIT_SHARE: 'EDIT_SHARE',
  EDIT_SHARE_SUCCEEDED: 'EDIT_SHARE_SUCCEEDED',
  DESTORY_SHARE_PANEL: 'DESTORY_SHARE_PANEL',
  SET_CONTENT_TYPE: 'SET_CONTENT_TYPE',
  INIT_APPS: 'INIT_APPS',
  INIT_APPS_SUCCEEDED: 'INIT_APPS_SUCCEEDED',
  GET_WALLPAPERS: 'GET_WALLPAPERS',
  GET_WALLPAPERS_SUCCEEDED: 'GET_WALLPAPERS_SUCCEEDED',
  GET_ICONS: 'GET_ICONS',
  GET_ICONS_SUCCEEDED: 'GET_ICONS_SUCCEEDED',
  CLEAR_WALLPAPERS: 'CLEAR_WALLPAPERS',
  GET_UNSPLASH: 'GET_UNSPLASH',
  GET_UNSPLASH_SUCCEEDED: 'GET_UNSPLASH_SUCCEEDED',
  SEARCH_UNSPLASH: 'SEARCH_UNSPLASH',
  SEARCH_UNSPLASH_SUCCEEDED: 'SEARCH_UNSPLASH_SUCCEEDED',
  GET_HELP: 'GET_HELP',
  GET_HELP_SUCCEEDED: 'GET_HELP_SUCCEEDED',
  COLLAPSE_PROJECT: 'COLLAPSE_PROJECT',
  CHANGE_COLOR_THEME: 'CHANGE_COLOR_THEME',
  SET_LOADING: 'SET_LOADING',
  CLEAR_SEARCH_USER: 'CLEAR_SEARCH_USER',
  ADD_FRIEND: 'ADD_FRIEND',
  ADD_FRIEND_SUCCEEDED: 'ADD_FRIEND_SUCCEEDED',
  GET_FRIEND_LIST: 'GET_FRIEND_LIST',
  GET_FRIEND_LIST_SUCCEEDED: 'GET_FRIEND_LIST_SUCCEEDED',
  GET_STORE_APP_LIST: 'GET_STORE_APP_LIST',
  GET_STORE_APP_LIST_SUCCEEDED: 'GET_STORE_APP_LIST_SUCCEEDED',
  ADD_APP: 'ADD_APP',
  ADD_APP_SUCCEEDED: 'ADD_APP_SUCCEEDED',
  DELETE_APP: 'DELETE_APP',
  DELETE_APP_SUCCEEDED: 'DELETE_APP_SUCCEEDED',
  GET_CATEGORY: 'GET_CATEGORY',
  GET_CATEGORY_SUCCEEDED: 'GET_CATEGORY_SUCCEEDED',
  GET_ADDED_APP_LIST: 'GET_ADDED_APP_LIST',
  GET_ADDED_APP_LIST_SUCCEEDED: 'GET_ADDED_APP_LIST_SUCCEEDED',
  CLEAR_APPS: 'CLEAR_APPS',
  FIX_APP: 'FIX_APP',
  FIX_APP_SUCCEEDED: 'FIX_APP_SUCCEEDED',
  DRAG_APP: 'DRAG_APP',
  DRAG_APP_SUCCEEDED: 'DRAG_APP_SUCCEEDED',
  SWITCH_CHAT_PANEL: 'SWITCH_CHAT_PANEL',
  SHOW_SEARCH_PANEL: 'SHOW_SEARCH_PANEL',
  GET_PRIVATE_CHAT_ID: 'GET_PRIVATE_CHAT_ID',
  GET_PRIVATE_CHAT_ID_SUCCEEDED: 'GET_PRIVATE_CHAT_ID_SUCCEEDED',
  CLEAR_PRIVATE_CHAT_ID: 'CLEAR_PRIVATE_CHAT_ID',
  INIT_INDEXDB: 'INIT_INDEXDB',
  GET_CARD_USERS: 'GET_CARD_USERS',
  GET_CARD_USERS_SUCCEEDED: 'GET_CARD_USERS_SUCCEEDED',
  ADD_CARD_USER: 'ADD_CARD_USER',
  DEL_CARD_USER: 'DEL_CARD_USER',
  CLEAR_CARD_USER: 'CLEAR_CARD_USER',
  TRANSFER_NODE: 'TRANSFER_NODE',
  DELETE_QINIU: 'DELETE_QINIU',
};

export function Failed(error: any) {
  console.log('---error---', error);
  return {
    type: actionTypes.FAILED,
    error,
  };
}

export function switchApps(visible?: boolean) {
  return {
    type: actionTypes.SWITCH_APPS,
    visible: visible,
  };
}

export function switchNotification(visible?: boolean) {
  return {
    type: actionTypes.SWITCH_NOTIFICATION,
    visible: visible,
  };
}

export function switchSearch(visible?: boolean) {
  return {
    type: actionTypes.SWITCH_SEARCH,
    visible: visible,
  };
}

export function setMessage(
  visible: boolean,
  text: string,
  severity: 'success' | 'info' | 'warning' | 'error' | undefined,
) {
  return { type: actionTypes.SET_MESSAGE, visible, text, severity };
}

export function playSoundEffect(
  play: boolean,
  name:
    | ''
    | 'create'
    | 'compelete'
    | 'cancel'
    | 'buttonClick'
    | 'systemInit'
    | 'loginSuccess'
    | 'receiveTask'
    | 'notification'
    | 'archive'
    | 'pack'
    | 'createTask',
) {
  return { type: actionTypes.PLAY_SOUND_EFFECT, play, name };
}

export function getNodeSharedUser(nodeKey: string) {
  return {
    type: actionTypes.GET_SHARED_PEOPLE,
    nodeKey,
  };
}

export function getNodeSharedUserSuccess(data: any) {
  return {
    type: actionTypes.GET_SHARED_PEOPLE_SUCCEEDED,
    data,
  };
}

export function share(nodeKey: string, toUser: string, role: 'admin' | 'edit' | 'author' | 'read') {
  return {
    type: actionTypes.SHARE,
    nodeKey,
    toUser,
    role,
  };
}

export function shareSuccess(data: any) {
  return {
    type: actionTypes.SHARE_SUCCEEDED,
    data,
  };
}

export function searchUser(keyWord: string) {
  return {
    type: actionTypes.SEARCH_USER,
    keyWord,
  };
}

export function searchUserSuccess(data: any) {
  return {
    type: actionTypes.SEARCH_USER_SUCCEEDED,
    data,
  };
}

export function getRecentCollab() {
  return {
    type: actionTypes.GET_RECENT_COLLAB,
  };
}

export function searchUserLocal(keyWord: string) {
  return {
    type: actionTypes.SEARCH_USER_LOCAL,
    keyWord,
  };
}

export function searchUserLocalSuccess(data: any) {
  return {
    type: actionTypes.SEARCH_USER_LOCAL_SUCCEEDED,
    data,
  };
}

export function getRecentCollabSuccess(data: any) {
  return {
    type: actionTypes.GET_RECENT_COLLAB_SUCCEEDED,
    data,
  };
}

export function deleteShare(nodeKey: string, toUser: string) {
  return {
    type: actionTypes.DELETE_SHARE,
    nodeKey,
    toUser,
  };
}

export function deleteShareSuccess(toUser: string) {
  return {
    type: actionTypes.DELETE_SHARE_SUCCEEDED,
    toUser,
  };
}

export function transferNode(nodeKey: string, toUser: string) {
  return {
    type: actionTypes.TRANSFER_NODE,
    nodeKey,
    toUser,
  };
}

export function editShare(nodeKey: string, toUser: string, role: 'admin' | 'edit' | 'author' | 'read') {
  return {
    type: actionTypes.EDIT_SHARE,
    nodeKey,
    toUser,
    role,
  };
}

export function editShareSuccess(toUser: string, role: 'admin' | 'edit' | 'author' | 'read') {
  return {
    type: actionTypes.EDIT_SHARE_SUCCEEDED,
    toUser,
    role,
  };
}

export function destorySharePanel() {
  return {
    type: actionTypes.DESTORY_SHARE_PANEL,
  };
}

export function setContentType(contentType: 'tree' | 'apps' | 'share' | 'fav' | 'recent') {
  return {
    type: actionTypes.SET_CONTENT_TYPE,
    contentType,
  };
}

export function initApps(nodeKey: string) {
  return {
    type: actionTypes.INIT_APPS,
    nodeKey,
  };
}

export function initAppsSuccess(data: any) {
  return {
    type: actionTypes.INIT_APPS_SUCCEEDED,
    data,
  };
}

export function getWallPapers(currentPage: number, perPage: number) {
  return {
    type: actionTypes.GET_WALLPAPERS,
    currentPage,
    perPage,
    partialLoading: true,
  };
}

export function getWallPapersSuccess(data: any) {
  return {
    type: actionTypes.GET_WALLPAPERS_SUCCEEDED,
    data,
  };
}

export function getIcons(currentPage: number, perPage: number, tagKey?: string) {
  return {
    type: actionTypes.GET_ICONS,
    currentPage,
    perPage,
    tagKey,
    partialLoading: true,
  };
}

export function getIconsSuccess(data: any) {
  return {
    type: actionTypes.GET_ICONS_SUCCEEDED,
    data,
  };
}

export function getUnsplash() {
  return {
    type: actionTypes.GET_UNSPLASH,
    partialLoading: true,
  };
}

export function getUnsplashSuccess(data: any) {
  return {
    type: actionTypes.GET_UNSPLASH_SUCCEEDED,
    data,
  };
}

export function searchUnsplash(keyword: string) {
  return {
    type: actionTypes.SEARCH_UNSPLASH,
    keyword,
    partialLoading: true,
  };
}

export function searchUnsplashSuccess(data: any) {
  return {
    type: actionTypes.SEARCH_UNSPLASH_SUCCEEDED,
    data,
  };
}

export function getHelp(nodeKey: string) {
  return {
    type: actionTypes.GET_HELP,
    nodeKey,
  };
}

export function getHelpSuccess(data: any) {
  return {
    type: actionTypes.GET_HELP_SUCCEEDED,
    data,
  };
}

export function collapseProject() {
  return {
    type: actionTypes.COLLAPSE_PROJECT,
  };
}

export function changeColorTheme() {
  return {
    type: actionTypes.CHANGE_COLOR_THEME,
  };
}

export function setLoading(loading: boolean) {
  return {
    type: actionTypes.SET_LOADING,
    loading,
  };
}

export function clearSearchUsers() {
  return {
    type: actionTypes.CLEAR_SEARCH_USER,
  };
}

export function addFriend(userKey: string) {
  return {
    type: actionTypes.ADD_FRIEND,
    userKey,
  };
}

export function addFriendSuccess(userKey: string, data: any) {
  return {
    type: actionTypes.ADD_FRIEND_SUCCEEDED,
    userKey,
    data,
  };
}

export function getFriendList() {
  return {
    type: actionTypes.GET_FRIEND_LIST,
  };
}

export function getFriendListSuccess(data: any) {
  return {
    type: actionTypes.GET_FRIEND_LIST_SUCCEEDED,
    data,
  };
}

export function getCategories() {
  return {
    type: actionTypes.GET_CATEGORY,
  };
}

export function getCategoriesSuccess(data: any) {
  return {
    type: actionTypes.GET_CATEGORY_SUCCEEDED,
    data,
  };
}

export function getAppList(keyword?: string, tagKey?: string, added?: boolean) {
  return {
    type: actionTypes.GET_STORE_APP_LIST,
    keyword,
    tagKey,
    added,
  };
}

export function getAppListSuccess(data: any) {
  return {
    type: actionTypes.GET_STORE_APP_LIST_SUCCEEDED,
    data,
  };
}

export function addApp(appKey: string) {
  return {
    type: actionTypes.ADD_APP,
    appKey,
  };
}

export function addAppSuccess(appKey: string, data: any) {
  return {
    type: actionTypes.ADD_APP_SUCCEEDED,
    appKey,
    data,
  };
}

export function deleteApp(appKey: string) {
  return {
    type: actionTypes.DELETE_APP,
    appKey,
  };
}

export function deleteAppSuccess(appKey: string, data: any) {
  return {
    type: actionTypes.DELETE_APP_SUCCEEDED,
    appKey,
    data,
  };
}

export function getAddedApps(nodeKey: string) {
  return {
    type: actionTypes.GET_ADDED_APP_LIST,
    nodeKey,
  };
}

export function getAddedAppsSuccess(data: any) {
  return {
    type: actionTypes.GET_ADDED_APP_LIST_SUCCEEDED,
    data,
  };
}

export function clearApps() {
  return {
    type: actionTypes.CLEAR_APPS,
  };
}

export function fixApp(nodeKey: string, app: AppType) {
  return {
    type: actionTypes.FIX_APP,
    nodeKey,
    app,
  };
}

export function fixAppSuccess(nodeKey: string, app: AppType) {
  return {
    type: actionTypes.FIX_APP_SUCCEEDED,
    nodeKey,
    app,
  };
}

export function dragApp(nodeKey: string, targetNodeKey: string, placement: 'up' | 'down' | 'in', oldFather: string) {
  return {
    type: actionTypes.DRAG_APP,
    nodeKey,
    targetNodeKey,
    placement,
    oldFather,
  };
}

export function dragAppSuccess(
  nodeKey: string,
  targetNodeKey: string,
  placement: 'up' | 'down' | 'in',
  data: any,
  oldFather: string,
) {
  return {
    type: actionTypes.DRAG_APP_SUCCEEDED,
    nodeKey,
    targetNodeKey,
    placement,
    data,
    oldFather,
  };
}

export function switchChatPanel(visible: boolean) {
  return {
    type: actionTypes.SWITCH_CHAT_PANEL,
    visible,
  };
}

export function switchSearchPanel(visible: boolean) {
  return {
    type: actionTypes.SHOW_SEARCH_PANEL,
    visible,
  };
}

export function getPrivateChatId(friendKey: string) {
  return {
    type: actionTypes.GET_PRIVATE_CHAT_ID,
    friendKey,
  };
}

export function getPrivateChatIdSuccess(privateChatId: string) {
  return {
    type: actionTypes.GET_PRIVATE_CHAT_ID_SUCCEEDED,
    privateChatId,
  };
}

export function clearPrivateChatId() {
  return {
    type: actionTypes.CLEAR_PRIVATE_CHAT_ID,
  };
}

export function clearWallpapers() {
  return {
    type: actionTypes.CLEAR_WALLPAPERS,
  };
}

export function initIndexDB() {
  return {
    type: actionTypes.INIT_INDEXDB,
  };
}

export function getCardUsers(userKeyArr: string[]) {
  return {
    type: actionTypes.GET_CARD_USERS,
    userKeyArr,
  };
}

export function getCardUsersSuccess(data: any) {
  return {
    type: actionTypes.GET_CARD_USERS_SUCCEEDED,
    data,
  };
}

export function addCardUser(user: User) {
  return {
    type: actionTypes.ADD_CARD_USER,
    user,
  };
}

export function deleteCardUser(user: User) {
  return {
    type: actionTypes.DEL_CARD_USER,
    user,
  };
}

export function clearCardUsers() {
  return {
    type: actionTypes.CLEAR_CARD_USER,
  };
}

export function deleteQiniu(urls: string[]) {
  return {
    type: actionTypes.DELETE_QINIU,
    urls,
  };
}
