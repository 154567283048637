import { all } from 'redux-saga/effects';
import authSagas from './authSaga';
import articleSagas from './articleSaga';
import treeSagas from './treeSaga';
import commonSaga from './commonSaga';
import taskSaga from './taskSaga';

function* rootSaga() {
  yield all([
    ...authSagas,
    ...articleSagas,
    ...treeSagas,
    ...commonSaga,
    ...taskSaga
  ]);
}

export default rootSaga;
