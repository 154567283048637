import { call, put, takeLatest } from 'redux-saga/effects';
import {
  actionTypes,
  addArticleSuccess,
  deleteArticleSuccess,
  editArticleSuccess,
  // getArticlesSuccess,
  getArticleByIdSuccess,
  previewArticleSuccess,
  getDocListSuccess,
  getShareListSuccess,
  getShareOutListSuccess,
  getFavListSuccess,
  getClipsSuccess,
  searchSuccess,
  editArticleSaveStatus,
  editDrawSuccess,
  searchNodeSuccess,
  getNtfyListSuccess,
  readNtfySuccess,
  publicShareSuccess,
  checkPublicShareSuccess,
  getRecycleSuccess,
  restoreSuccess,
  favSuccess,
  getChildNodesSuccess,
  getRecentUploadSuccess,
  uploadFileSuccess,
  deleteBatchArticleSuccess,
  deleteRecycleSuccess,
  clearNtfySuccess,
  getQuickAccessSuccess,
  addQuickAccessSuccess,
  removeQuickAccessSuccess,
  getNavFavListSuccess,
  publicShareTreeSuccess,
  getBangumiListSuccess,
} from '../actions/articleActions';
import { Failed, setMessage } from '../actions/commonActions';
import api from '../../util/api';
import ResponseGenerator from '../../interfaces/ResponseGenerator';
import { getRepositoryTree } from '../actions/treeActions';

// function* getArticles(action: any) {
//   try {
//     const res: ResponseGenerator = yield call(api.article.get, action.current, action.pageSize);
//     if (res.status === 200) {
//       yield put(getArticlesSuccess(res));
//     } else {
//       yield put(Failed(res));
//     }
//   } catch (e) {
//     yield put(Failed(e));
//   }
// }

function* getArticleById(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getNodeDeail, action._key, action.bookId);
    if (res.status === 200) {
      yield put(getArticleByIdSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* previewArticle(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getNodeDeailNoToken, action._key);
    if (res.status === 200) {
      yield put(previewArticleSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* addArticle(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.createNode,
      action.name,
      action.nodeType,
      action.detail,
      action.appKey,
      action.fatherId,
      action.url,
      undefined,
      action.thumbnail,
      undefined,
      undefined,
      undefined,
      action.customTime,
    );
    if (res.status === 200) {
      yield put(
        addArticleSuccess(
          res,
          action.detail,
          action.notShowMessage,
          action.inArticles,
          action.partialLoading,
          action.customTime,
        ),
      );
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* editArticle(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      action.name,
      action.nodeType,
      action.detail,
      action.status,
      action.thumbnail,
      action.url,
      undefined,
      undefined,
      undefined,
      action.openNewTab,
      undefined,
      action.star,
      action.customTime,
      undefined,
      undefined,
      action.fileSize,
      action.fileType,
    );
    if (res.status === 200) {
      yield put(
        editArticleSuccess(
          action.nodeKey,
          action.name,
          res,
          action.detail,
          action.url,
          action.notShowMessage,
          action.modifyState,
          action.star,
          action.customTime,
          action.noRefresh,
          action.fileType,
        ),
      );
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getDocList(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getDocList,
      action.currentPage,
      action.perPage,
      action.appKey,
      action.projectKey,
      action.nodeType,
      action.star,
      action.cardKey,
    );
    if (res.status === 200) {
      yield put(getDocListSuccess(res, action.currentPage, action.pagination, action.partialLoading));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getRecentUpload(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getRecentUpload,
      action.currentPage,
      action.perPage,
      action.projectKey,
    );
    if (res.status === 200) {
      yield put(getRecentUploadSuccess(res, action.currentPage));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* setViewUploadLog(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.viewUploadFileLog, action.nodeKey);
    if (res.status === 200) {
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getShareList(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getShareList,
      action.page,
      action.limit,
      undefined,
      action.appKey,
      action.projectKey,
      action.nodeType,
      action.cardKey,
    );
    if (res.status === 200) {
      yield put(getShareListSuccess(res, action.page, action.pagination));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getShareOutList(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getShareOutList,
      action.page,
      action.limit,
      undefined,
      action.appKey,
      action.projectKey,
      action.nodeType,
      action.cardKey,
    );
    if (res.status === 200) {
      yield put(getShareOutListSuccess(res, action.page, action.pagination));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getFavList(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getFavList,
      action.page,
      action.limit,
      undefined,
      action.showOriginal,
      action.appKey,
      action.projectKey,
      action.nodeType,
      action.star,
      action.cardKey,
    );
    if (res.status === 200) {
      yield put(getFavListSuccess(res, action.page, action.pagination));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}
function* getNavFavList(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getFavList,
      action.page,
      action.limit,
      undefined,
      action.showOriginal,
      action.appKey,
      action.projectKey,
      action.nodeType,
    );
    if (res.status === 200) {
      yield put(getNavFavListSuccess(res, action.page, action.pagination));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getNtfyList(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getNotificationList,
      action.page,
      action.limit,
      action.unRead,
      action.status,
    );
    if (res.status === 200) {
      yield put(getNtfyListSuccess(action.page === 1 ? false : true, res, action.unRead));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getClips(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getNodeFromWeb, action.page, action.limit, action.nodeType);
    if (res.status === 200) {
      yield put(getClipsSuccess(res, action.page));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* deleteArticle(action: any) {
  try {
    let res: ResponseGenerator;
    if (action.exitShare) {
      // 退出分享
      res = yield call(api.node.exitShare, action.nodeKey);
    } else {
      // 刪除節點
      res = yield call(api.node.updateNode, action.nodeKey, undefined, undefined, undefined, 0);
    }

    if (res.status === 200) {
      if (action.nextTreeKey) {
        yield put(getRepositoryTree(action.nextTreeKey));
      } else {
        yield put(
          deleteArticleSuccess(action.nodeKey, action.isTree, action.exitShare, action.noMessage, action.isProjectTree),
        );
      }
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* deleteBatchArticle(action: any) {
  try {
    let res: ResponseGenerator = yield call(api.node.deleteBatch, action.nodeKeyArr);
    if (res.status === 200) {
      if (action.nextTreeKey) {
        yield put(getRepositoryTree(action.nextTreeKey));
      } else {
        yield put(deleteBatchArticleSuccess(action.nodeKeyArr));
      }
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* search(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.search, action.keyWord, action.nodeKey, action.appKey, true);
    if (res.status === 200) {
      yield put(searchSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

// 编辑draw
function* addDraw(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.createNode,
      action.name,
      action.nodeType,
      action.detail,
      action.appKey,
      action.fatherId,
      undefined,
      undefined,
      action.thumbnail,
      undefined,
      action.styleType,
    );
    if (res.status === 200) {
      yield put(editDrawSuccess(undefined, action.detail, action.styleType, res, action.name));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}
function* editDraw(action: any) {
  try {
    yield put(editArticleSaveStatus(0));
    const res: ResponseGenerator = yield call(
      api.node.updateNode,
      action.nodeKey,
      action.name,
      action.nodeType,
      action.detail,
      action.status,
      action.thumbnail,
      undefined,
      undefined,
      undefined,
      action.styleType,
    );
    if (res.status === 200) {
      yield put(editDrawSuccess(action.nodeKey, action.detail, action.styleType, res, action.name));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* searchNode(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.search,
      action.keyword,
      action.nodeKey,
      action.appKey,
      undefined,
      action.nodeType,
      action.cardKey,
    );
    if (res.status === 200) {
      yield put(searchNodeSuccess(res, action.partialLoading));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* readNtfy(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.readNotification, action.messageKey);
    if (res.status === 200) {
      yield put(readNtfySuccess(action.messageKey));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* clearNtfy() {
  try {
    const res: ResponseGenerator = yield call(api.node.clearReadMessage);
    if (res.status === 200) {
      yield put(clearNtfySuccess());
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* publicShare(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.publicShare, action.nodeKey);
    if (res.status === 200) {
      yield put(publicShareSuccess(action.nodeKey, action.isBook));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* publicShareTree(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.publicShareTree,
      action.nodeKey,
      action.isPublic,
      action.allowEdit,
    );
    if (res.status === 200) {
      yield put(publicShareTreeSuccess(action.nodeKey));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* checkPublicShare(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.isPublicShare, action.nodeKey);
    if (res.status === 200) {
      yield put(checkPublicShareSuccess(res));
    } else if (res.status === 404) {
      yield put(Failed(res));
      window.location.href = `${window.location.protocol}//${window.location.host}/home/project/repository`;
    } else {
      yield put(setMessage(true, res.msg || '', 'error'));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getRecycle(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getRecycle, action.currentPage, action.perPage);
    if (res.status === 200) {
      yield put(getRecycleSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* restore(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.updateNode, action.nodeKey, undefined, undefined, undefined, 1);
    if (res.status === 200) {
      yield put(restoreSuccess(action.nodeKey));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* deleteRecycle(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.completelyDelete, action.nodeKeyArr);
    if (res.status === 200) {
      yield put(deleteRecycleSuccess(action.nodeKeyArr));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* clickPath(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.checkContract, action.nodeKey);
    if (res.status === 200) {
      action.callback();
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* fav(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.fav, action.nodeKey);
    if (res.status === 200) {
      yield put(favSuccess(action.nodeKey, res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

function* getChildNodes(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.getChildNodes,
      action.nodeKey,
      action.page,
      action.limit,
      action.returnFolder,
    );
    if (res.status === 200) {
      yield put(getChildNodesSuccess(action.page, res, action.partialLoading));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* uploadFile(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      api.node.createNode,
      action.name,
      action.nodeType,
      undefined,
      undefined,
      action.fatherId,
      action.url,
      action.fileSize,
      action.thumbnail,
      undefined,
      undefined,
      action.fileType,
    );
    if (res.status === 200) {
      yield put(uploadFileSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getQuickAccess(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getQuickAccess, action.currentPage, action.perPage);
    if (res.status === 200) {
      yield put(getQuickAccessSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* addQuickAccess(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.addQuickAccess, action.nodeKey);
    if (res.status === 200) {
      yield put(addQuickAccessSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* removeQuickAccess(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.removeQuickAccess, action.nodeKey);
    if (res.status === 200) {
      yield put(removeQuickAccessSuccess(action.nodeKey));
    } else {
      yield put(Failed(res));
    }
  } catch (e) {
    yield put(Failed(e));
  }
}

function* getBangumiList(action: any) {
  try {
    const res: ResponseGenerator = yield call(api.node.getBangumiList, action.userKey);
    if (res.status === 200) {
      yield put(getBangumiListSuccess(res));
    } else {
      yield put(Failed(res));
    }
  } catch (error) {
    yield put(Failed(error));
  }
}

const articleSagas = [
  takeLatest(actionTypes.GET_ARTICLE_BY_ID, getArticleById),
  takeLatest(actionTypes.PREVIEW_ARTICLE, previewArticle),
  takeLatest(actionTypes.EDIT_ARTICLE, editArticle),
  takeLatest(actionTypes.EDIT_DRAW, editDraw),
  takeLatest(actionTypes.DELETE_ARTICLE, deleteArticle),
  takeLatest(actionTypes.GET_DOC_LIST, getDocList),
  takeLatest(actionTypes.GET_SHARE_LIST, getShareList),
  takeLatest(actionTypes.GET_SHARE_OUT_LIST, getShareOutList),
  takeLatest(actionTypes.GET_FAV_LIST, getFavList),
  takeLatest(actionTypes.GET_NAV_FAV_LIST, getNavFavList),
  takeLatest(actionTypes.GET_CLIPS, getClips),
  takeLatest(actionTypes.GET_NTFY_LIST, getNtfyList),
  takeLatest(actionTypes.ADD_ARTICLE, addArticle),
  takeLatest(actionTypes.ADD_DRAW, addDraw),
  takeLatest(actionTypes.SEARCH, search),
  takeLatest(actionTypes.SEARCH_NODE, searchNode),
  takeLatest(actionTypes.READ_NTFY, readNtfy),
  takeLatest(actionTypes.CLEAR_NTFY, clearNtfy),
  takeLatest(actionTypes.PUBLIC_SHARE, publicShare),
  takeLatest(actionTypes.PUBLIC_SHARE_TREE, publicShareTree),
  takeLatest(actionTypes.CHECK_PUBLIC_SHARE, checkPublicShare),
  takeLatest(actionTypes.GET_RECYCLE, getRecycle),
  takeLatest(actionTypes.RESTORE, restore),
  takeLatest(actionTypes.DELETE_RECYCLE, deleteRecycle),
  takeLatest(actionTypes.CLICK_PATH, clickPath),
  takeLatest(actionTypes.FAV_ARTICLE, fav),
  takeLatest(actionTypes.GET_CHILD_NODES, getChildNodes),
  takeLatest(actionTypes.GET_RECENT_UPLOAD, getRecentUpload),
  takeLatest(actionTypes.SET_VIEW_UPLOAD_LOG, setViewUploadLog),
  takeLatest(actionTypes.UPLOAD_FILE, uploadFile),
  takeLatest(actionTypes.DELETE_BATCH_ARTICLE, deleteBatchArticle),
  takeLatest(actionTypes.GET_QUICK_ACCESS, getQuickAccess),
  takeLatest(actionTypes.ADD_QUICK_ACCESS, addQuickAccess),
  takeLatest(actionTypes.REMOVE_QUICK_ACCESS, removeQuickAccess),
  takeLatest(actionTypes.GET_BANGUMI_LIST, getBangumiList),
];
export default articleSagas;
