import AppType from '../../interfaces/AppType';
import UserConfig from '../../interfaces/UserConfig';
import UserStorage from '../../interfaces/UserStorage';
import { actionTypes } from '../actions/authActions';
import { actionTypes as commonActionTypes } from '../actions/commonActions';
import { actionTypes as treeActionTypes } from '../actions/treeActions';

export interface AuthType {
  user: any;
  expired: boolean;
  qiniuToken: null | string;
  app: any | null;
  appMap: any | null;
  root: any;
  userConfig: UserConfig | null;
  userStorage: UserStorage | null;
}

const defaultState: AuthType = {
  user: null,
  expired: false,
  qiniuToken: null,
  app: null,
  appMap: null,
  root: null,
  userConfig: null,
  userStorage: null,
};

const defaultConfig: UserConfig = {
  leftBarMenus: [
    {
      id: 'doc',
      name: '文档',
      pathname: '/project/doc',
      visible: true,
    },
    {
      id: 'mind',
      name: '大脑',
      pathname: '/project/repository',
      visible: true,
    },
    // {
    //   id: 'project',
    //   name: '任务',
    //   pathname: '/project/teammate',
    //   visible: true,
    // },
    {
      id: 'collaborator',
      name: '工作',
      pathname: '/project/teammate',
      visible: true,
    },
    {
      id: 'os',
      name: '桌面',
      pathname: '/project/os',
      visible: false,
    },
    {
      id: 'clip',
      name: '网摘',
      pathname: '/project/clip',
      visible: true,
    },
    {
      id: 'chat',
      name: '聊天',
      visible: true,
    },
    {
      id: 'apps',
      name: '应用',
      pathname: '/project/apps',
      visible: true,
    },
  ],
};

export const auth = (state = defaultState, action: any) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCEEDED:
    case actionTypes.LOGIN_BY_TOKEN_SUCCEEDED:
      return {
        ...state,
        user: action.data.result,
        expired: false,
      };
    case actionTypes.LOGIN_BY_QRID_SUCCEEDED:
      return {
        ...state,
        user: action.data.data,
        expired: false,
      };
    case actionTypes.LOGIN_FAILED:
      localStorage.clear();
      return {
        ...state,
        user: null,
        expired: true,
      };
    case commonActionTypes.FAILED: {
      if (action.error.status === 701) {
        localStorage.clear();
        return {
          ...state,
          user: null,
          expired: true,
        };
      } else {
        return state;
      }
    }
    case actionTypes.REGISTER_SUCCEEDED:
      return {
        ...state,
        user: action.data.result,
        expired: false,
      };
    case actionTypes.LOGOUT:
      localStorage.clear();
      return {
        ...state,
        user: null,
        expired: true,
      };
    case actionTypes.GET_QINIU_TOKEN_SUCCEEDED:
      return {
        ...state,
        qiniuToken: action.data.result,
      };
    case actionTypes.INIT_APP_SUCCEEDED: {
      return {
        ...state,
        app: action.data.data,
      };
    }
    case actionTypes.GET_APPS_SUCCEEDED: {
      const res = action.data.data;
      let appMap: any = {};
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        appMap[element._key] = element;
      }
      return {
        ...state,
        appMap,
      };
    }
    case actionTypes.INIT_SUCCEEDED: {
      const root = action.data.data;
      return {
        ...state,
        root,
        userConfig: root.config || defaultConfig,
      };
    }
    case actionTypes.UPDATE_CONFIG_SUCCEEDED:
      return {
        ...state,
        userConfig: action.config,
      };
    case actionTypes.SWITCH_SPACE: {
      const root = { ...state.root };
      root.project = {
        _key: action.spaceKey,
        name: action.spaceName,
      };
      return {
        ...state,
        root,
      };
    }
    case treeActionTypes.ADD_PROJECT_SUCCEEDED: {
      const root = { ...state.root };
      root.project = {
        _key: action.data.data._key,
        name: action.data.data.name,
      };
      return {
        ...state,
        root,
      };
    }
    case commonActionTypes.FIX_APP_SUCCEEDED: {
      const app = action.app;
      const root = { ...state.root };
      const topApps: AppType[] = root && root.topApp ? root.topApp : [];
      if (app.top) {
        const index = topApps.findIndex((item) => item._key === app._key);
        topApps.splice(index, 1);
      } else {
        topApps.push(app);
      }
      return {
        ...state,
        root,
      };
    }
    case actionTypes.REMAIN_STORAGE_SUCCEEDED: {
      return {
        ...state,
        userStorage: action.data.data,
      };
    }
    default:
      return state;
  }
};
